const $ = window.jQuery;

export default function counter() {
	var $counter = $('.counter');
	if ($counter.length > 0) {
	  $counter.each(function () {
	    var $elem = $(this);
	    $elem.appear(function () {
	      $elem.find('.timer').countTo();
	    });
	  });
	}
}

import { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getApp, updateApp } from '../utils';
import { isEmpty, map, each, uniqBy } from 'lodash';

function getValidationErrors(data) {
  let arr = [];
  each(data, (value, key) => {
    if (['firstName', 'lastName'].includes(key) && !value) {
      arr.push({ section: 'Personal Background', message: 'Missing Name', link: '/application/personal-background' });
    }
    if (key === 'birthDate' && !value) {
      arr.push({ section: 'Personal Background', message: 'Missing Birthdate', link: '/application/personal-background' });
    }
    if (['country', 'streetAddress', 'city', 'postalCode'].includes(key) && !value) {
      arr.push({ section: 'Personal Background', message: 'Missing Permanent Address', link: '/application/personal-background' });
    }
    if (['mailingCountry', 'mailingStreetAddress', 'mailingCity', 'mailingPostalCode'].includes(key) && !value) {
      arr.push({ section: 'Personal Background', message: 'Missing Mailing Address', link: '/application/personal-background' });
    }
    if (key === 'nativeLanguage' && !value) {
      arr.push({ section: 'Personal Background', message: 'You must select a native language', link: '/application/personal-background' });
    }
    if (key === 'fullTime' && value === null) {
      arr.push({ section: 'Program Selection', message: '"Do you plan to attend the Sakya Buddha University as a full-time or part-time student?" is required', link: '/application/program' });
    }
    if (key === 'previouslyApplied' && value === null) {
      arr.push({ section: 'Program Selection', message: '"Have you previously applied to the Sakya Buddha University?" is required', link: '/application/program' });
    }
    if (key === 'program' && !value) {
      arr.push({ section: 'Program Selection', message: 'College and Program are required', link: '/application/program' });
    }
    if (key === 'term' && !value) {
      arr.push({ section: 'Program Selection', message: '"Please select your intended start term" is required', link: '/application/program' });
    }
    if (key === 'academicRecords' && isEmpty(value)) {
      arr.push({ section: 'Academic Record', message: 'Applicants must hold an earned baccalaureate degree from a regionally accredited college or university. Please report the University where you have or will be receiving your baccalaureate degree.', link: '/application/academic' });
    }
    if (key === 'felonyFlag' && value === null) {
      arr.push({ section: 'Required Supplemental Information', message: '"Have you ever been convicted of or pleaded guilty to a criminal offense, excluding minor traffic violations, or do you currently have pending criminal charges against you?" is required', link: '/application/supplemental' });
    }
    if (key === 'disciplinaryFlag' && value === null) {
      arr.push({ section: 'Required Supplemental Information', message: '"Have you previously experienced or are you presently subject to probation, suspension, or dismissal from any educational institution you attended due to conduct or disciplinary reasons?" is required', link: '/application/supplemental' });
    }
    if (key === 'signature' && !value) {
      arr.push({ section: 'Signature', message: 'Please complete prior to submission', link: '/application/signature' });
    }
  });
  return uniqBy(arr, 'message');
}

function Review() {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const [errors, setErrors] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();
  const [data, setData] = useState(selectedApp || {});
  const [validationErrors, setValidationErrors] = useState(getValidationErrors(data) || []);

  useEffect(() => {
    window.jQuery('body,html').animate({
      scrollTop: 460
    }, 1000);
  }, []);

  function onSuccess(data) {
    setSelectedApp(data);
    return navigate('/application/status');
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrors(null);
    updateApp(
      loggedInUser,
      selectedApp,
      {
        ...data,
        applicationStatus: 'Submitted',
        submittedAt: new Date(),
      },
      onSuccess,
      setErrors,
    );
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <h1>Review</h1>
            <p>
              Click <span className="text-danger fw-bold">Save for Later</span> to continue working on your application at another time.
            </p>
            <p>
              Click <span className="text-danger fw-bold">Submit Application</span> after you complete all required sections. Applications must be submitted on or before the program’s final submission deadline.  Supporting application materials such as personal statements, essays, transcripts, test scores, etc. can be uploaded after you submit your application. After your application is submitted, you will be prompted to pay your application fee.
            </p>
            {validationErrors && (
              <div className="row mb-2">
                <div className="col-lg-12">
                  <strong>
                    We have detected the following errors with your application.
                    These errors must be corrected before submission.
                  </strong>
                </div>
              </div>
            )}
            {validationErrors && (
              <table className="table">
                <thead>
                  <tr className="bg-gray">
                    <th>Section</th>
                    <th>Required Field or Error</th>
                  </tr>
                </thead>
                <tbody>
                  {map(validationErrors, (e) => (
                    <tr key={`ve-${e.message}`}>
                      <td>
                        <Link to={e.link}>{e.section}</Link>
                      </td>
                      <td>
                        <Link to={e.link} className="text-primary">{e.message}</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <div className="form-group">
              <div className="row justify-content-center gap-3">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate('/application')}
                >
                  Save for Later
                </button>
                {isEmpty(validationErrors) && (
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit Application
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;

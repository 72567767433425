import { useEffect } from 'react';
import { carousel } from './animation';
import img1 from '../images/client/01.svg';
import img2 from '../images/client/02.svg';
import img3 from '../images/client/03.svg';
import img4 from '../images/client/04.svg';
import img5 from '../images/client/05.svg';
import img6 from '../images/client/06.svg';
import img7 from '../images/client/07.svg';

function Clients() {
  useEffect(() => {
    carousel();
  }, []);
  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="owl-carousel our-clients bg-white p-0" data-nav-arrow="false" data-items="5" data-md-items="4" data-sm-items="3" data-xs-items="2" data-xx-items="1" data-space="0" data-autoheight="true">

                <div className="item">
                  <img className="img-fluid center-block mx-auto grayscale" src={img1} alt="" />
                </div>



                <div className="item">
                  <img className="img-fluid center-block mx-auto grayscale" src={img2} alt="" />
                </div>



                <div className="item">
                  <img className="img-fluid center-block mx-auto grayscale" src={img3} alt="" />
                </div>



                <div className="item">
                  <img className="img-fluid center-block mx-auto grayscale" src={img4} alt="" />
                </div>



                <div className="item">
                  <img className="img-fluid center-block mx-auto grayscale" src={img5} alt="" />
                </div>



                <div className="item">
                  <img className="img-fluid center-block mx-auto grayscale" src={img6} alt=""/>
                </div>



                <div className="item">
                  <img className="img-fluid center-block mx-auto grayscale" src={img7} alt="" />
                </div>

              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;

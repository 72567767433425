import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';

function ForgotPassword() {
  const navigate = useNavigate();

  const { loggedInUser, login } = useAuth();

  const [emailParam, setEmailParam] = useState(null);
  const [birthdateParam, setBirthdateParam] = useState(null);
  const [errors, setErrors] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(null);

  function handleForgotPassword() {
    setErrors(null);
    setMessage(null);
    setLoading(false);

    if (loggedInUser) {
      navigate('/application');
      return true;
    }

    if (!emailParam || !birthdateParam) {
      setErrors('Please complete the required fields.');
      return false;
    }

    setLoading(true);

    axios.post(
      `${process.env.REACT_APP_BACKEND_API}/password`,
      { user: { email: emailParam, birthdate: birthdateParam } },
    )
      .then(response => {
        if (response.data && response.data?.status === 200) {
          setMessage("Thank you. If the account exists, an email will be sent to you shortly.")
        } else {
          setErrors(response.data.errors);
        }
      })
      .catch(error => {
        if (error.response && error.response.statusText) {
          return setErrors(error.response.statusText);
        }
        setErrors(error.message);
      })
      .finally(() => setLoading(false));
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleForgotPassword();
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h2>Reset Password</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-7 mb-2">
            <div className="login bg-white shadow p-5">
              {errors && (
                <div className="row">
                  <div className="col-lg-12">
                    <ul className="text-danger">
                      <li>{errors}</li>
                    </ul>
                  </div>
                </div>
              )}
              {message && (
                <div className="row">
                  <div className="col-lg-12">
                    <ul className="text-success">
                      <li>{message}</li>
                    </ul>
                  </div>
                </div>
              )}
              <p>If you have forgotten your password, you may request a new password by email.</p>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Email address <span className="text-danger">*</span></label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    defaultValue={emailParam}
                    onChange={e => setEmailParam(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>
                <div className="form-group">
                  <label>Birthdate <span className="text-danger">*</span></label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="MM/DD/YYYY"
                    defaultValue={birthdateParam}
                    onChange={e => setBirthdateParam(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading && true}
                >
                  Reset Password
                </button>
                {loading && (
                  <span
                    className="spinner-border"
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      marginLeft: '1rem',
                    }}
                  />
                )}
                <p className="mt-2 mb-0"><a href="sign-in">Back to sign in</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;

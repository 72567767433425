import InnerHeader from '../components/InnerHeader';

function PrivacyPolicy() {
  return (
    <>
      <InnerHeader />
      <section className="space-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            <h4 className="text-primary">Personal Information</h4>
            <p>The price is something not necessarily defined as financial. It could be time.</p>
            <p>One of the main areas that I work on with my clients is shedding these non-supportive beliefs and replacing them with beliefs that will help them to accomplish their desires.</p>
            <p>It is truly amazing the damage that we, as parents, can inflict on our children. So why do we do it? For the most part, we don’t do it intentionally or with malice. In the majority of cases, the cause is a well-meaning but unskilled or un-thinking parent, who says the wrong thing at the wrong time, and the message sticks – as simple as that!</p>
            <ul className="list-unstyled">
              <li className="mb-2">
                <i className="fa fa-angle-right pr-2 mb-2"></i>
                The bottom line is that we must shed the bad and replace with good beliefs.
              </li>
              <li className="mb-2">
                <i className="fa fa-angle-right pr-2 mb-2"></i>
                Get the oars in the water and start rowing.
              </li>
              <li className="mb-2">
                <i className="fa fa-angle-right pr-2 mb-2"></i>
                So the faster and better your execution
              </li>
              <li>
                <i className="fa fa-angle-right pr-2 mb-2" />
                Briefly imagine that you are not going to live
              </li>
            </ul>
            <h4 className="mt-4 text-primary">Use of User Information.</h4>
            <p>Notice how your body is, how your mind is, how you feel about staying on this path. Walkout into your future to the 10 year point. Walkout 10 years into your future and feel how it feels to carry on doing the same thing. This path is just like today.</p>
            <p>The price is something not necessarily defined as financial. It could be time, effort, sacrifice, money or perhaps, something else. The point is that we must be fully aware of the price and be willing to pay it, if we want to have success.</p>
            <h4 className="mt-4 text-primary">Disclosure of User Information.</h4>
            <p>Really experience that. See what you see, hear what you hear, feel the feelings. Disappointment? Anger? Frustration? Failure? How does that feel? How do you affect those around you? How do they feel? Absorb every aspect of this path that you can take today if you so choose.<a href="/"> support@academic.com</a> </p>
            <ul className="list-unstyled">
              <li className="mb-2"> <i className="fa fa-angle-right pr-2 mb-2"></i> Imagine you are 10 years into the future but this time it’s different.</li>
              <li> <i className="fa fa-angle-right pr-2 mb-2"></i> You have the freedom to be how you want to be.</li>
            </ul>
            <p className="mt-3 mb-0">Absorb all you need from this moment in your future and the positive things that you can learn consciously and unconsciously and then drift and float back to the place where the path splits. Step Six: Now that you know and have experienced the two contrasting futures.<a href="/"> support@academic.com</a></p>
            <h4 className="mt-4 text-primary">Is It Necessary?</h4>
            <p className="mb-0">Notice how your body is, how your mind is, how you feel about staying on this path. Walkout into your future to the 10 year point. Walkout 10 years into your future and feel how it feels to carry on doing the same thing. This path is just like today.</p>
           </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;

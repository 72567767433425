// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    position: relative;
    overflow: hidden;
}

.button h3 {
    color: #1a2844; /* Default text color */
    transition: color 0.3s ease-in-out;
}

#footer-button-text {
    font-size: 2vw;
    font-size: min(max(14px, 2vw), 20px); /* This ensures the size stays between 12px and 24px */
}


/*.button:hover img {*/
/*    !*mix-blend-mode: multiply; !* Change the blend mode to multiply *!*!*/
/*    !*;filter: hue-rotate(240deg) !* Adjust the degree for blue color (240deg) *!*!*/
/*    color: #1a2844;*/
/*}*/

.hovered-image {
    mix-blend-mode: multiply;
    background-color: rgba(169, 169, 169, 0.7); /* Light Grey with 0.7 opacity */
    transition: background-color 0.3s ease; /* Add a transition for a smoother effect */
}

`, "",{"version":3,"sources":["webpack://./src/css/footer-button.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,cAAc,EAAE,uBAAuB;IACvC,kCAAkC;AACtC;;AAEA;IACI,cAAc;IACd,oCAAoC,EAAE,sDAAsD;AAChG;;;AAGA,sBAAsB;AACtB,4EAA4E;AAC5E,sFAAsF;AACtF,sBAAsB;AACtB,IAAI;;AAEJ;IACI,wBAAwB;IACxB,0CAA0C,EAAE,gCAAgC;IAC5E,sCAAsC,EAAE,2CAA2C;AACvF","sourcesContent":[".button {\n    position: relative;\n    overflow: hidden;\n}\n\n.button h3 {\n    color: #1a2844; /* Default text color */\n    transition: color 0.3s ease-in-out;\n}\n\n#footer-button-text {\n    font-size: 2vw;\n    font-size: min(max(14px, 2vw), 20px); /* This ensures the size stays between 12px and 24px */\n}\n\n\n/*.button:hover img {*/\n/*    !*mix-blend-mode: multiply; !* Change the blend mode to multiply *!*!*/\n/*    !*;filter: hue-rotate(240deg) !* Adjust the degree for blue color (240deg) *!*!*/\n/*    color: #1a2844;*/\n/*}*/\n\n.hovered-image {\n    mix-blend-mode: multiply;\n    background-color: rgba(169, 169, 169, 0.7); /* Light Grey with 0.7 opacity */\n    transition: background-color 0.3s ease; /* Add a transition for a smoother effect */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/bg/campus.png";
import Divider from "../components/divider";

function Campuses() {

    return (
        <>
            <InnerHeader customName='Campuses' backgroundImage={imgBg}/>
            <Divider/>

            <div className="container">
                <div className="header">
                    <h4>Campuses</h4>
                </div>
                <div className="info-section text-justify">
                    <p>Sakya Buddha University comprises a main campus and three additional houses campuses in Santa
                        Ana, California, all conveniently connected by public transportation or a short bike ride.</p>
                    <p>The main campus, located at 3404 Westminster Ave., Santa Ana, CA 92703, houses the Administration
                        Building, Presidential offices, Classrooms, and a Meditation hall. The Meditation halls are
                        accessible to students, faculty, staff, and visitors whenever the buildings are open.
                        Additionally, there are occasional facilitated drop-in sitting sessions open to all.</p>
                    <p>The SBU library, situated at 18042 Villa Park Rd, Villa Park, CA 92861, serves as the primary
                        library for Sakya Buddha University.</p>
                    <p>The SBU Bodhi Buddha Sangha, located at 14052 Flower St, Garden Grove, CA 92843, functions as
                        the resident house for the Bhikkhunī (nuns) sangha.</p>
                    <p>Similarly, the SBU Budhi Buddha Sangha, situated at 9631 Stanford Ave, Garden Grove, CA 92841,
                        serves as the resident house for the Bhikkhu (monks) sangha.</p>
                </div>
            </div>
        </>
    );
}

export default Campuses;

import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getApp, convertKeysToCamelCase } from '../utils';
import axios from 'axios';
import { map } from 'lodash';
import PayPalButtonsWrapper from '../components/PayPalButtonsWrapper';

function Payment({ awaitingPayment, selectedAppId, updatePaymentData }) {
  const { loggedInUser, login } = useAuth();

  const [errors, setErrors] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(null);

  function createOrder(data, actions) {
    return actions.order.create({
      purchase_units: [
        { amount: { value: '50.00' } }
      ]
    })
  }

  function onApprove(data) {
    setErrors(null);
    setMessage(null);
    setLoading(true);

    return axios.post(`${process.env.REACT_APP_BACKEND_API}/online_applications/${selectedAppId}/payments`,
      data,
      { headers: { Authorization: loggedInUser.auth } },
    )
      .then((response) => {
        if (response.data && response.data?.status === 200) {
          updatePaymentData(convertKeysToCamelCase(response.data.data));
          setMessage(response.data.message);
        } else {
          setErrors(response.data.errors);
        }
      })
      .catch(error => {
        if (error.response && error.response.statusText) {
          return setErrors(error.response.statusText);
        }
        setErrors(error.message);
      })
      .finally(() => setLoading(false));
  }

  function onCancel(data) {
    console.log('onCancel', data)
  }

  function onError(e) {
    setErrors(`PayPal encountered an error: ${e.message}`);
  }

  return (
    <div className="my-3">
      {awaitingPayment && (
        <>
          <a href="#">Pay now</a>
          <PayPalButtonsWrapper
            showSpinner
            style={{
              shape: 'rect',
              color: 'gold',
              layout: 'vertical',
              label: 'pay'
            }}
            createOrder={createOrder}
            onApprove={onApprove}
            onCancel={onCancel}
            onError={onError}
          />
        </>
      )}
      {loading && (
        <span
          className="spinner-border"
          style={{
            width: '1.2rem',
            height: '1.2rem',
            marginLeft: '1rem',
          }}
        />
      )}
      {errors && (
        <div className="row">
          <div className="col-lg-12">
            <ul className="text-danger">
              <li>{errors}</li>
            </ul>
          </div>
        </div>
      )}
      {message && (
        <div className="row">
          <div className="col-lg-12">
            <ul className="text-success">
              <li>{message}</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Payment;

import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/bg/bg_academics.png";
import hoaSen from "../images/gallery/hoa_sen.jpg";
import { useHashChange } from "../hooks/useHashChange";
import Mission from "../about-us/Mission";
import TrusteeMsg from "../about-us/TrusteeMsg";
import History from "../about-us/History";
import BodhiSeed from "../about-us/BodhiSeed";
import React, {useEffect} from "react";
import GovernBoard from "../about-us/GovernBoard";

const links = [
    { name: 'Statement of mission', component: Mission, id: 'mission' },
    { name: 'History of SBU', component: History, id: 'history' },
    { name: 'The Planter of the Bodhi Seed', component: BodhiSeed, id: 'bodhi' },
    { name: 'Board of Trustees message', component: TrusteeMsg, id: 'trusteeMsg' },
    { name: 'Governing Board Profiles', component: GovernBoard, id: 'governBoard' },
];

function AboutSBU() {
    const handleLinkClick = (link) => {
        setActiveLink(link);
        const element = document.getElementById(link.id);
        console.log("handle with click scroll outside")
        if (element) {
            const offsetTop = element.getBoundingClientRect().top + window.scrollY - 300; // Adjust the offset as needed
            window.scrollTo({ top: offsetTop, behavior: 'smooth' });
        }
    };

    const [activeLink, setActiveLink] = useHashChange(links, handleLinkClick);
    let Component = activeLink.component;

    // Ensure that the component is mounted before calling document.getElementById
    useEffect(() => {
        const element = document.getElementById(activeLink.id);
        if (element) {
            const offsetTop = element.getBoundingClientRect().top + window.scrollY - 300; // Adjust the offset as needed
            window.scrollTo({ top: offsetTop, behavior: 'smooth' });

        }
    }, [activeLink]);

    return (
        <>
            <InnerHeader backgroundImage={imgBg} />
            <div className="container" id="about-us">
                <div className="row mb-1 ">
                    <div className="col-lg-8 pr-0 pl-0">
                        <div>
                            <div style={{
                                backgroundColor: '#192f59',
                                color: 'white',
                                padding: '50px 10px 40px 10px'
                            }}>
                                <h4 className="title" style={{ color: 'white' }}>ABOUT SBU</h4>
                                <p className="text-justify" style={{ whiteSpace: 'pre-line' }}>
                                    Sakya Buddha University (SBU), a nonprofit private institution located in Santa Ana, California, boasts a
                                    charming campus, modern classrooms, dedicated faculty, and a comprehensive library—forming an
                                    educational haven in vibrant Orange County.<br/><br/>
                                    At SBU, we offer top-notch instruction rooted in Buddhist-based teaching methods, sparking a subtle
                                    revolution in the educational environment. We draw on the Buddhist values of self-reflection and
                                    mindfulness exercises to help students succeed in their studies, achieve goals, uncover strengths, and
                                    evolve into mindful leaders. Through intimate class settings and dedicated mentors, SBU provides a
                                    personalized college experience focused on each student&#39;s success. As a pioneering accredited university,
                                    SBU blends Buddhist-based education with Western academia and state-of-the-art technology,
                                    transforming and inspiring our students.<br/><br/>
                                    SBU upholds the core values of academia by empowering students to discover and pursue their societal
                                    responsibilities—fueling personal growth and leadership within their selected fields. We steer
                                    professionals toward greater presence and engagement in their endeavors. <br/><br/>
                                    In our nurturing and serene campus environment, students from diverse backgrounds can flourish.
                                    Continue reading to learn more about the value of an SBU education, our focus on student mission and
                                    holistic learning, and our dedication to providing an accessible and affordable education for all.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 pl-0 pr-0"
                         style={{ display: 'flex' }}>
                        <img src={hoaSen} alt=""
                             style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'cover' }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 mb-5 mb-lg-0">
                        <div className="shadow border-radius p-4" id={activeLink.id}>
                            <Component />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="course-sidebar shadow">
                            <div className="widget">
                                <div className="widget-title">
                                    <h5>More About SBU</h5>
                                </div>
                                <div className="widget-categories">
                                    {links.map(link => (
                                        <button
                                            key={link.name}
                                            className="btn btn-link btn-block pl-0 text-left d-flex justify-content-between align-items-center"
                                            type="button"
                                            onClick={() => handleLinkClick(link)}
                                        >
                                            <span>{link.name}</span>
                                            <i className="fas fa-chevron-right text-right ml-auto"></i>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutSBU;

import React, { useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import InnerHeader from '../components/InnerHeader';
import App from './App';
import imgBg from "../images/bg/application_home.png";

function StartApplication() {
  const [loggedInUser, setLoggedInUser] = useState(null);

  return (
    <AuthContext.Provider value={{loggedInUser, setLoggedInUser}}>
      <>
        <InnerHeader backgroundImage={imgBg}/>
        <App/>
      </>
    </AuthContext.Provider>
  );
}

export default StartApplication;

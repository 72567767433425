import React from 'react';
import '../css/shared.css'; // Import your CSS file for styling if needed

const Divider = () => {
  return (
    <section className="space-xs-ptb">
      <div className="container">
        <div className="divider">
          <span className="ic ic--lotus"></span>
        </div>
      </div>
    </section>
  );
};

export default Divider;

import { useEffect } from 'react';
import { counter } from './animation';

function Counter() {
  useEffect(() => {
    counter();
  }, []);


  return (
    <section className="space-pb">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
            <div className="counter">
              <div className="counter-icon">
                <i className="flaticon-team-1"></i>
              </div>
              <div className="counter-content">
                <span className="timer" data-to="1790" data-speed="10000">1790</span>
                <label>Satisfied Students</label>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
            <div className="counter">
              <div className="counter-icon">
                <i className="flaticon-diploma-1"></i>
              </div>
              <div className="counter-content align-self-center">
                <span className="timer" data-to="245" data-speed="10000">245</span>
                <label>Course Completed</label>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-4 mb-md-0">
            <div className="counter">
              <div className="counter-icon">
                <i className="flaticon-support"></i>
              </div>
              <div className="counter-content">
                <span className="timer" data-to="491" data-speed="10000">491</span>
                <label>Expert Advisors</label>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="counter">
              <div className="counter-icon">
                <i className="flaticon-trophy-1"></i>
              </div>
              <div className="counter-content">
                <span className="timer" data-to="1090" data-speed="10000">1090</span>
                <label>Award Winning</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Counter;

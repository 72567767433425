import { useEffect } from 'react';
import { countdownTimer} from '../components/animation';
import bg from '../images/bg/02.jpg';
import logo from '../images/logo.svg';

function ComingSoon() {
  useEffect(() => {
    countdownTimer();
  }, []);
  return (
    <>
      <section
        className="coming-soon vh-100 py-0"
        style={{backgroundImage: `url(${bg})`}}
      >
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-8 col-md-10 align-self-center">
                <div className="row align-items-center">
                  <div className="col-12 mb-4">
                    <a className="navbar-brand" href="/">
                      <img className="img-fluid logo" src={logo} alt="logo" style={{width: '300px'}} />
                    </a>
                  </div>
                </div>
              <h1 className="text-dark">Coming Soon</h1>
              <p className="text-dark">Benjamin Franklin, inventor, statesman, writer, publisher and economist relates in his autobiography that early in his life he decided to focus on arriving at moral perfection.</p>

              <div className="countdown-wrapper">
                <div className="countdown">
                  <span className="text-dark days">00</span>
                  <p className="text-dark days_ref">days</p>
                </div>
                <div className="countdown">
                  <span className="text-dark hours">00</span>
                  <p className="text-dark hours_ref">hours</p>
                </div>
                <div className="countdown">
                  <span className="text-dark minutes">00</span>
                  <p className="text-dark minutes_ref">minutes</p>
                </div>
                <div className="countdown">
                  <span className="text-dark seconds">00</span>
                  <p className="text-dark seconds_ref">seconds</p>
                </div>
              </div>
              <h4 className="text-dark">We will notify you when site is ready:</h4>
              <p className="lead text-dark">Provide your email address!</p>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="mt-1">
                        <form>
                          <div className="form-group">
                            <input type="email" className="form-control border" placeholder="Email Address" />
                          </div>
                          <a href="/" className="btn btn-primary">Subscribe<i className="far fa-paper-plane pl-2"></i></a>
                        </form>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ComingSoon;

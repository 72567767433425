import bg from '../images/bg/09.jpg';

function CallToAction() {
  return (
    <section
      className="space-ptb bg-overlay-black-80 bg-holder"
      data-jarallax='{"speed": 0.5}'
      style={{backgroundImage: `url(${bg})`}}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center">
            <span className="text-white d-block mb-4 h6 font-weight-normal">Are you ready to start your journey with us?</span>
            <h2 className="text-white mb-4 display-7">Education for Individual and Social Responsibility</h2>
            <a className="btn btn-primary mr-sm-3 mt-3" href="/">Become A Student</a>
            <a className="btn btn-white mt-3" href="/">Discover More</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CallToAction;

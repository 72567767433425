import { useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';

function ApplicationWelcome() {
  useEffect(() => {
    window.jQuery('body,html').animate({
      scrollTop: 460
    }, 1000);
  }, []);

  const [selectedApp, setSelectedApp] = useOutletContext();
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12 text-justify">
            <div id="main" role="main" >
              <form
                method="post"
                noValidate="novalidate"
                data-fw-form={1}
                autoComplete="off"
              >
                <h1>Welcome</h1>
                <p>Thank you for initiating your application for graduate studies at Sakya Buddha
                  University. Below are
                  links containing detailed information about the academic programs available for
                  application:</p>

                <ul>
                  <li>Master of Art in Buddhist Philosophy</li>
                </ul>

                <p>Take a moment to carefully review the admission requirements specific to the graduate
                  program you
                  wish to apply for. Your application will undergo evaluation upon the submission of
                  the application,
                  fee, and all required supporting materials by the program's application
                  deadline.</p>

                <p>To ensure that you receive important communications related to your application,
                  kindly add <a
                    href="mailto:info@sakyauniversity.com">info@sakyauniversity.com</a> to your list
                  of contacts in your
                  email account.</p>

                <p>Should you require any assistance, feel free to reach out to us via the email address
                  or phone number
                  provided below.</p>
                <div className="contact-info mb-4" style={{textAlign: "center"}}>
                  <strong>Sakya Buddha University | Office of Graduate Education</strong><br/>
                    3404 Westminster Avenue, Santa Ana, CA 92703<br/>
                    Phone: <a href="tel:+17143286087">(714) 328 6087</a><br/>
                    Email: <a
                    href="mailto:info@sakyauniversity.com">info@sakyauniversity.com</a><br/>
                    Hours: Monday-Friday, 8 AM-5 PM (PST)
                </div>
                <div className="action">
                  <Link
                    to="/application/personal-background"
                    className="btn btn-block btn-primary"
                    type="button"
                  >
                    Continue
                  </Link>
                </div>
              </form>
            </div>
            <div style={{clear: "both"}}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationWelcome;

import img from '../images/about/about-01.jpg';
import thc2 from '../images/gallery/bodhi_seed.JPG';
import React from "react";
import './css/aboutUs.css';

function BodhiSeed() {
    return (
        <>
            <div>
                <h4 className='text-center mb-4'>THE PLANTER OF THE BODHI SEED</h4>
                <div className='center-icon-img'>
                    <img
                        src={thc2}
                        alt=""
                        className="img-fluid rounded-circle mr-3 icon-img"
                        style={{width: '250px', height: '250px', border: '5px solid gray'}}
                    />
                </div>
                <p className="text-justify">Illuminate the lamp of Prajna to guide people to the Dharma Realm,</p>
                <p className="text-justify">Sow the Bodhi seeds fearlessly while traversing the vast ocean</p>
                <p className="text-justify">After nearly a decade of persistent effort, navigating challenges and
                    obstacles, Venerable Thich
                    Huyen Chau formally announced the establishment of Sakya Buddha University to the Buddhist
                    community on October 13, 2022. Consequently, on January 8, 2023, Congressman Ro Khanna,
                    who represents the 17th Congressional District of California, presided over a ceremony to honor
                    Venerable Thich Huyen Chau for his educational initiatives, which have rendered “outstanding
                    and invaluable service to the community.” The seeds of wisdom planted by the Venerable Master
                    in the United States have not only symbolized the teachings of the Buddha, emphasizing virtuous
                    deeds, but have also borne tangible fruits acknowledged and embraced by the community.</p>
                <p className=" text-justify">Over three decades ago, Venerable Master Thich Huyen Chau embarked on his
                    journey as a
                    novice monk, immersing himself in the nurturing wisdom cultivated at Phuoc Long Pagoda. This
                    distinctive sanctuary, established by National Master Phước Huệ, stands in Tay Son, Binh Dinh,
                    Vietnam. National Master Phước Huệ, revered as a master monk across three royal lineages,
                    served as a mentor to the young monk. Under the guidance of erudite Buddhist scholars, the
                    Venerable Master matured along the path of an esteemed monk, imbibing profound insights and
                    lessons from revered mentors in his homeland, ultimately embodying noble virtues. In
                    September 2009, his Master entrusted him with the patriarch&#39;s seal, bestowing upon him the
                    responsibility to uphold and perpetuate the legacy of National Master Phước Huệ.</p>
                <p className=" text-justify">After nearly five years dedicated to illuminating the illustrious path of
                    the National Master,
                    Venerable Thich Huyen Chau was granted approval by the U.S. Immigration Service on May 29,
                    2013. Departing from Phuoc Long Pagoda, he ventured to Linh Son Pagoda in Worcester,
                    Massachusetts, to spread the teachings of Dharma.</p>
                <p className=" text-justify">He later relocated to Santa Ana, California, one of the first cities in the
                    state to embrace
                    Buddhism, where he founded a monastic community in October of the same year. Orange
                    County, with its dense population of Vietnamese Buddhists, has been a thriving center for
                    Buddhist cultural activities for over five decades. However, the absence of a university
                    dedicated to guiding spiritual philosophy for those seeking to study and practice the teachings of
                    Buddha left a noticeable gap.</p>
                <p className=" text-justify">In 2014, recognizing the need for a dedicated institution, the Venerable
                    Master initiated the
                    establishment of the first facility at 3404 Westminster Avenue, Santa Ana, California. Originally
                    an old, dilapidated Christian church, the facility underwent extensive repairs and renovations led
                    by the Venerable Master and fellow Buddhists, transforming it into the Bodhi Buddharealm
                    Society Temple.</p>
                <p className=" text-justify">The Inauguration Ceremony, held on June 3, 2015, drew over 1,250 Buddhist
                    practitioners,
                    monks, and nuns. The solemn ambiance resembled a spiritual beacon, marking the onset of
                    Buddhist education in the United States.</p>
                <p className=" text-justify">The Bodhi Buddharealm Society Temple functions as a hub for the Buddhist
                    community,
                    facilitating worship and traditional ceremonies while also serving as a center for Buddhist
                    education. Within its walls, esteemed monks and nuns conduct structured classes, imparting
                    fundamental Buddhist teachings to attendees.</p>
                <p className=" text-justify">Upon the completion of the facility, the Venerable Master immediately began
                    laying the
                    groundwork for the temple&#39;s educational pursuits, aiming for sustained academic growth. In
                    2015, the Master organized an Open Conference within the local Vietnamese Buddhist
                    Community, focused on &quot;Buddhism Education in the United States.&quot; The outcome of this
                    gathering was the aspiration to establish a future Buddhist university. However, rather than wait
                    for the stringent academic standards typical of American universities to be fully met, the Bodhi
                    Buddharealm Society Institute took a proactive step. On May 29, 2016, it officially initiated the
                    inaugural specialized Buddhism courses, known as the Pre-Intermediate program. This initiative
                    exemplified the temple&#39;s commitment to immediate educational advancement while also working
                    toward the eventual realization of a comprehensive Buddhist university.</p>
                <p className=" text-justify">In addition, the Venerable Master inaugurated the Dharma Music program, a
                    reverent artistic
                    initiative intertwining sacred scriptures, intricate wood carvings, and Buddhist melodies. The 50
                    songs in this program encapsulate the essence of Buddhist teachings and inspire multi-
                    dimensional emotional tranquility within the audience.</p>
                <p className=" text-justify">The Dharma Music program is a great first step toward embracing Buddhist
                    courses, starting
                    with the pre-intermediate program, structured to span three years, followed by an additional
                    three-year Advanced course, culminating in the Abhidhamma Concentration course.</p>
                <p className=" text-justify">Through these Buddhist classes, the Bodhi Buddharealm Society has
                    progressively broadened its
                    impact and popularity. The community now congregates at the institute for traditional Dharma
                    seminars, retreats, meditation courses, and various engagements.</p>
                <p className=" text-justify">Recognizing the limitations faced by compassionate and devout Buddhists
                    unable to attend
                    physical academic sessions, the Venerable Master launched the Bodhi Buddharealm Society
                    television station in 2019, broadcasting on channel 57.15. This television channel is an important
                    tool for disseminating the Dharma, wielding substantial influence within the Vietnamese
                    Buddhist community. During pandemic stay-at-home orders, this TV program conveyed
                    compassionate messages, aiding Buddhists worldwide in maintaining serenity through the
                    liberating teachings of the Tathagata.</p>
                <p className=" text-justify">Moreover, the Bodhi Buddharealm Society Temple orchestrated four solemn
                    Grand Precept
                    ceremonies, transmitting the sacred guidelines of the Tathagata that steer Buddha students
                    toward noble and virtuous lifestyles. These ritualistic ceremonies for reciting the precepts, as
                    delineated in the Dharma, occur bi-monthly, carrying with them the sanctity preserved by the
                    Buddha’s Sangha Buddhist disciples for over 2,600 years. These specific activities have
                    progressively yielded positive outcomes, contributing to the steady growth of the Bodhi
                    Buddharealm Society.</p>
                <p>In 2020, one of the Venerable Master&#39;s nun disciples, previously a medical doctor, showed her
                    gratitude and support for the Venerable Master’s commitment to the Dharma by donating a
                    residence located at 18042 Villa Park Road in Villa Park, California. The house was converted
                    into a monastery, and its inauguration was held in 2021. Currently, it also serves as the
                    international liaison office, accommodating both the library and translation departments for
                    Buddhism University. Other disciples have made financial contributions to establish a boarding
                    monastery, envisioned as a nurturing ground for wise and virtuous monks and nuns aspiring to
                    become holy Bodhisattvas. However, the ongoing project faces several challenges and remains
                    incomplete at this time.</p>
                <p>Despite the setbacks, the Venerable Master led the initiative to construct a boarding nunnery at
                    14052 Flower Street in Garden Grove, California, which was completed in 2021. Envisioned as a
                    future residence for 45 student nuns for both living and study purposes, this nunnery stands as a
                    testament to the Master&#39;s dedication to Buddhist education.</p>
                <p>In early May 2022, as the Bodhi Buddharealm Society prepared for the weeklong celebration of
                    the Buddha&#39;s birthday, an unexpected turn of events unfolded. Despite a meticulously planned
                    schedule comprising various activities such as Dharma seminars, the great precepts ceremony,
                    and graduation, an urgent situation emerged for Venerable Thich Huyen Chau.</p>
                <p>Prompt actions were required as Venerable Thich Huyen Chau had to swiftly acquire the Santa
                    Ana Mini Mall at 3412 Westminster Ave, Santa Ana, CA 92703, adjacent to the Bodhi
                    Buddharealm Society premises. This expansive property was seen as highly advantageous for
                    establishing the new Sakya University. The Venerable Master presided over the Grand
                    Groundbreaking Ceremony for the new temple land, symbolically laying the foundation stone for
                    Sakya Buddha University on May 28, 2022. The university&#39;s construction is planned across 3404
                    and 3412 Westminster Avenue, Santa Ana, California, encompassing nearly 300,000 square feet
                    with an initial cost estimate exceeding $25 million.</p>
                <p>For nearly a decade, Venerable Thich Huyen Chau has dedicated himself to tirelessly rallying
                    intellectuals within the Buddhist community to participate, particularly encouraging members of
                    the Sakya University Board of Education, which includes teacher monks and nuns. This
                    unwavering commitment to spirituality marks a significant milestone in the ongoing endeavor of
                    preserving and safeguarding Buddhism, perpetuating the Bodhi seed in the vast expanse of
                    wisdom.</p>
                <p>The Vietnamese Buddhist community has received abundant wisdom and teachings from the
                    Bodhi Buddharealm Society over nearly a decade, earning recognition from Congressman Ro
                    Khanna for Venerable Thich Huyen Chau&#39;s &quot; outstanding and invaluable&quot; educational
                    contributions to the community. Sakya University&#39;s aspirations don&#39;t halt there. In the near future,
                    it aims to enroll students in both undergraduate and graduate programs. We invite you to witness
                    the burgeoning academic institution of Sakya Buddha University—a place where liberated ideas
                    converge with enlightenment, contributing to the collective happiness of humanity worldwide.</p>

            </div>
        </>
    )
        ;
}

export default BodhiSeed;

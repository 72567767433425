import img1 from '../images/team/01.jpg';
import img2 from '../images/team/02.jpg';
import img3 from '../images/team/03.jpg';

function MeetOurTeam() {
  return (
    <section className="space-ptb bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10 text-center">
            <div className="section-title">
              <h2>Meet Our Team</h2>
              <p>Meet the outstanding performers in our industry-award-winning team of professionals</p>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-0">

            <div className="team">
              <div className="team-img">
                <img className="img-fluid" src={img1} alt="" />
              </div>
              <div className="team-info">
                <a href="teachers-single" className="team-name">Mellissa Doe</a>
                <p className="team-leader">Team Leader</p>
                <ul className="list-unstyled">
                  <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                  <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                  <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                  <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                </ul>
              </div>
            </div>

          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-0">

            <div className="team">
                <img className="img-fluid" src={img2} alt="" />
              <div className="team-info">
                <a href="teachers-single" className="team-name">Ora Bryan</a>
                <p className="team-leader">Illustration</p>
                <ul className="list-unstyled">
                  <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                  <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                  <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                  <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                </ul>
              </div>
            </div>

          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">

            <div className="team">
              <div className="team-img">
                <img className="img-fluid" src={img3} alt="" />
              </div>
              <div className="team-info">
                <a href="teachers-single" className="team-name">Gwen Bass</a>
                <p className="team-leader">Sales and Marketing</p>
                <ul className="list-unstyled">
                  <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                  <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                  <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                  <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default MeetOurTeam;

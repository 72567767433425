import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getApp, updateApp } from '../utils';

function ApplicationFinancialAid() {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const [errors, setErrors] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();
  const [data, setData] = useState(selectedApp || {});

  useEffect(() => {
    window.jQuery('body,html').animate({
      scrollTop: 460
    }, 1000);
  }, []);

  function onSuccess(data) {
    setSelectedApp(data);
    return navigate('/application/academic');
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrors(null);
    updateApp(
      loggedInUser,
      selectedApp,
      data,
      onSuccess,
      setErrors,
    );
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <h1>Financial Aid</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>
                  Do you intend to apply for federal financial aid?
                </label>
                <div className="" role="group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="financial_aid"
                    id="financial_aid_yes"
                    autoComplete="off"
                    defaultChecked={data.financialAid}
                    onChange={e => setData({ ...data, financialAid: true })}
                  />
                  <label className="pl-2">Yes</label>
                  <input
                    type="radio"
                    className="btn-check ml-4"
                    name="financial_aid"
                    id="financial_aid_no"
                    autoComplete="off"
                    defaultChecked={!data.financialAid}
                    onChange={e => setData({ ...data, financialAid: false })}
                  />
                  <label htmlFor="financial_aid_no" className="pl-2">No</label>
                </div>
              </div>
              <div className="form-group">
                <label>
                  Are/were you a first-generation college student?
                </label>
                <div className="" role="group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="first_generation"
                    id="first_generation_yes"
                    autoComplete="off"
                    defaultChecked={data.firstGeneration}
                    onChange={e => setData({ ...data, firstGeneration: true })}
                  />
                  <label htmlFor="first_generation_yes" className="pl-2">Yes</label>
                  <input
                    type="radio"
                    className="btn-check ml-4"
                    name="first_generation"
                    id="first_generation_no"
                    autoComplete="off"
                    defaultChecked={!data.firstGeneration}
                    onChange={e => setData({ ...data, firstGeneration: false })}
                  />
                  <label htmlFor="first_generation_no" className="pl-2">No</label>
                </div>
              </div>
              <div className="form-group">
                <div className="row justify-content-md-center">
                  <button className="btn btn-primary" type="submit">Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationFinancialAid;

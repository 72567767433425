import { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { AuthContext } from '../context/AuthContext';
import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/bg/application_home.png";
import React from "react";

const allLinks = [
  {name: 'Home', href: '/application'},
  {name: 'Welcome', href: '/application/welcome'},
  {name: 'Personal Background', href: '/application/personal-background'},
  {name: 'Program Selection', href: '/application/program'},
  {name: 'Affiliations', href: '/application/affiliations'},
  {name: 'Financial Aid', href: '/application/financial-aid'},
  {name: 'Academic Record', href: '/application/academic'},
  {name: 'Required Supplemental Information', href: '/application/supplemental'},
  {name: 'Signature', href: '/application/signature'},
  {name: 'Review', href: '/application/review'},
];

const submittedAppLinks = [
  {name: 'Home', href: '/application'},
  {name: 'Status', href: '/application/status'},
];

const homeLink = [
  {name: 'Home', href: '/application'},
];

function isAuthProtected(pathname) {
  return [
    '/application/sign-in',
    '/application/sign-up',
    '/application/forgot-password',
    '/application/reset-password',
    '/application',
  ].includes(pathname);
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedInUser } = useAuth();
  const [selectedApp, setSelectedApp] = useState({});

  useEffect(() => {
    if (!loggedInUser && !isAuthProtected(location.pathname)) {
      navigate('/application');
    }
  }, [loggedInUser]);

  function getLinks() {
    if (selectedApp.submittedAt && selectedApp.applicationStatus === 'Submitted') {
      return submittedAppLinks;
    }
    if (selectedApp.id) {
      return allLinks;
    }
    return homeLink;
  }

  return (
    <section className="space-ptb">
      <div className="container">
        <div className="row">
          {loggedInUser && (
            <div className="col-lg-3">
              <div className="course-sidebar shadow">
                <div className="widget">
                  <div className="widget-categories">
                    {getLinks().map(link => (
                      <Link
                        key={link.name}
                        className="btn btn-link btn-block px-0 py-1 text-left d-flex justify-content-between align-items-center"
                        to={link.href}
                      >
                        <span>{link.name}</span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={`${loggedInUser ? 'col-lg-9' : 'col-lg-12'} mb-5 mb-lg-0`}>
            <div className="row">
              <div className="col-lg-12">
                <div className="shadow border-radius p-4">
                  <Outlet context={[selectedApp, setSelectedApp]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;

import {useState, useEffect} from 'react';
import InnerHeader from '../components/InnerHeader';
import AcademicsIntro from '../academics/AcademicsIntro';
import Faculty from '../academics/Faculty';
import Gradudate from '../academics/Graduate'
import Undergraduate from '../academics/Undergraduate'
import Certificate from '../academics/Certificate'
import AcademicCalendar from '../academics/AcademicCalendar';
import Libraries from '../academics/Libraries';
import CourseCatalog from '../academics/CourseCatelog';
import Divider from "../components/divider";
import imgBg from '../images/gallery/make-a-gift.png'
import {useHashChange} from "../hooks/useHashChange";


const links = [{name: 'Donor Bill of Rights', component: ''},
    {name: 'Ways to Give', component: 'way-to-give'},
];

function MakeaGift() {
    const [activeLink, setActiveLink] = useHashChange(links);
    const Component = activeLink.component;

    return (
        <>

            <InnerHeader backgroundImage={imgBg}/>
            <Divider />
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mb-5 mb-lg-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="shadow border-radius p-4">
                                    <div className="section-title my-sm-3 text-justify">
                                        <h4 className="mb-4">Be a Change Maker with SBU</h4>
                                        <p>At SBU, we champion the potential for transformation, not only within ourselves, but also across
                                            the globe. We know you also carry this enthusiasm for promoting positive change. When you
                                            contribute to SBU, you join a movement dedicated to reshaping lives and cultivating a more
                                            equitable and radiant world.</p>
                                        <p>Your support at SBU holds immense significance. Whether you opt for the freedom of
                                            unrestricted giving through the SBU fund, extend life-altering scholarships to deserving students,
                                            or create a lasting legacy through an endowed or planned gift, your involvement serves as the
                                            cornerstone of our mission.</p>
                                        <p>By making a gift to our university, you play a vital role in nurturing talent, fostering innovation,
                                            and creating an environment where students flourish. Your support enables us to provide
                                            scholarships, enhance academic programs, and expand our outreach, ensuring every student
                                            receives an exceptional education and the chance to fulfill their potential.</p>
                                        <p>Your generosity has been an inspiration, and we are immensely grateful for your commitment to
                                            our university&#39;s success. Together, let&#39;s continue to build a brighter future for our students and
                                            our community.</p>
                                    </div>
                                    <div className='mb-4'>
                                        <a className="btn btn-primary" href="https://www.paypal.com/donate/?hosted_button_id=RGSNDW5Z2XA38">MAKE A GIFT TO SBU NOW</a>
                                    </div>
                                    <div>
                                        <h4>Empower the Heart of SBU</h4>
                                        <p>Your support powers the core of SBU, enabling us to:</p>
                                        <ul className='text-justify'>
                                            <li className="pb-2"><strong>Cultivate Faculty Excellence:</strong> Exceptional faculty form the core of a holistic education.
                                                With your assistance, we can recognize those who share their knowledge and expertise
                                                with the world, providing faculty with the necessary resources and opportunities to
                                                inspire our students.
                                            </li>
                                            <li className="pb-2"><strong>Enhance Academic Programs:</strong> Your contribution allows us to broaden and enrich our
                                                varied academic offerings, reinforcing the fusion of classroom teaching, mindfulness,
                                                compassionate practices, and hands-on learning. This empowers students to pursue their
                                                interests and create positive change.
                                            </li>
                                            <li className="pb-2"><strong>Foster Student Success:</strong> Your investment enables us to recruit and admit dedicated and
                                                compassionate individuals, irrespective of their financial backgrounds.
                                            </li>
                                            <li className="pb-2"><strong>SSupport Campus Life Initiatives:</strong> Through your backing of campus life initiatives, we can
                                                create an inclusive and supportive environment where each student feels appreciated,
                                                bolstered, and embraced.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="course-sidebar shadow">
                            <div className="widget">
                                <div className="widget-title">
                                    <h5>Invest in SBU</h5>
                                </div>
                                <div className="widget-categories">
                                    {links.slice(1).map(link => (
                                        <a
                                            href="/ways-to-give"
                                            className="btn btn-link btn-block pl-0 text-left d-flex justify-content-between align-items-center"
                                            type="button"
                                        >
                                            <span>{link.name}</span>
                                            <i className="fas fa-chevron-right text-right ml-auto"></i>
                                        </a>
                                    ))}
                                </div>
                            </div>

                            <div className="widget">
                                <div className="widget-title">
                                    <h5>Contact the Office</h5>
                                </div>
                                <div className="widget-categories">
                                    <h6>Mailing Address</h6>
                                    <p>3404-3412 Westminster Ave Santa Ana, CA 92703</p>
                                    <h6>Email</h6>
                                    <p>info@sakyauniversity.com</p>
                                    <h6>Phone</h6>
                                    <p>(714) 328 6087</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
        ;
}

export default MakeaGift;

import InnerHeader from '../components/InnerHeader';
import Divider from "../components/divider";
import imgBg from '../images/bg/bg_academics.png'
import {LinksContext} from "../academics/LinksContext";
import {useContext, useEffect} from "react";
import {useHashChange} from "../hooks/useHashChange";


function Academics() {
  const { links,activeLink,setActiveLink } = useContext(LinksContext);
  let Component = activeLink.component;

  const handleLinkClick = (link) => {
    setActiveLink(link);
    const element = document.getElementById(link.id);
    console.log("handle with click scroll outside")
    if (element) {
      const offsetTop = element.getBoundingClientRect().top + window.scrollY - 300; // Adjust the offset as needed
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  };

  // Ensure that the component is mounted before calling document.getElementById
  useEffect(() => {
    const element = document.getElementById(activeLink.id);
    if (element) {
      const offsetTop = element.getBoundingClientRect().top + window.scrollY - 300; // Adjust the offset as needed
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });

    }
  }, [activeLink]);




  return (
    <>
      <InnerHeader  backgroundImage={imgBg} customName="Academics" />
      <Divider />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mb-5 mb-lg-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="shadow border-radius p-4">
                  <Component />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="course-sidebar shadow">
              <div className="widget">
                <div className="widget-title">
                  <h5>Academics</h5>
                </div>
                <div className="widget-categories">
                  {links.slice(1).map(link => (
                    <button
                      key={link.name}
                      className="btn btn-link btn-block pl-0 text-left d-flex justify-content-between align-items-center"
                      type="button"
                      onClick={() => handleLinkClick(link)}
                    >
                      <span>{link.name}</span>
                      <i className="fas fa-chevron-right text-right ml-auto"></i>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Academics;

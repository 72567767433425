import { useEffect } from 'react';
import { useUser } from './useUser';
import { useCookies } from './useCookies';

export const useAuth = () => {
  const { loggedInUser, addUser, removeUser } = useUser();
  const { getItem } = useCookies();

  useEffect(() => {
    const user = getItem('user');
    if (user) {
      addUser(user);
    }
  }, []);

  const login = (user) => {
    addUser(user);
  };

  const logout = () => {
    removeUser();
  };

  return { loggedInUser, login, logout };
};

import axios from 'axios';
import {useState} from "react";

function Form() {
  const [emailMsg, setEmailMsg] = useState("");

  function handleContactUs(event) {
        event.preventDefault();
        const fd = new FormData(event.target)
        const data = Object.fromEntries(fd.entries());
        console.log("btn request info is clicked")
        console.log(data);
        axios.post(
            `${process.env.REACT_APP_BACKEND_API}/notifications/send_mail`, data
        ).then(response => {
            if (response.status === 200) {
                setEmailMsg("Request Information email have been successfully sent")
            } else {
                setEmailMsg("Request Information email have not been sent")
            }
        }).catch(error => {
            console.log('api errors:', error);
            setEmailMsg("Request Information email have not been sent")
        })
  }

  return (
    <section className="space-pb">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mb-4 mb-lg-0">
            <h4 className="mb-4">Need Assistance? Please Complete The Contact Form</h4>
            <form onSubmit={handleContactUs}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <input type="text" className="form-control bg-white border" id="Website" placeholder="Website URL" />
                </div>
                <div className="form-group col-md-6">
                  <input type="text" className="form-control bg-white border" id="name" name="name" placeholder="Your Name" required />
                </div>
                <div className="form-group col-md-6">
                  <input type="email" className="form-control bg-white border" id="email" name="email" placeholder="Email Address" required />
                </div>
                <div className="form-group col-md-6">
                  <input type="text" className="form-control bg-white border" id="phone" name="phone" placeholder="Phone Number" />
                </div>
                <div className="form-group col-md-12">
                  <textarea className="form-control bg-white border" rows="4" placeholder="Message" id="message" name="message" required ></textarea>
                </div>
                <div className="col-md-12">
                  <button className="btn btn-primary">Submit</button>
                  <div className="mt-3">{emailMsg}</div>
                </div>

              </div>
            </form>
          </div>
          <div className="col-lg-4">
            <div className="row">
              <div className="col-lg-12">
                  <div className="d-flex mb-3 bg-primary p-4 border-radius mb-4">
                    <div className="text-primary mr-3">
                      <i className="fas fa-3x fa-map-signs text-white"></i>
                    </div>
                    <div className="recent-post-info">
                      <span className="text-white">3404 & 3412 Westminster Ave.</span>
                      <br />
                      <span className="text-white">Santa Ana, CA 92703</span>
                    </div>
                  </div>
                </div>
              <div className="col-lg-12">
                  <div className="d-flex mb-3 bg-light p-4 border-radius mb-4">
                    <div className="text-primary mr-3">
                      <i className="far fa-3x fa-envelope"></i>
                    </div>
                    <div className="recent-post-info">
                      <span>sakyabuddha@vienphathoc.org</span>
                      <br />
                      <span>Monday - Friday, 8 AM - 5 PM (PST)</span>
                    </div>
                  </div>
                </div>
              <div className="col-lg-12">
                  <div className="d-flex bg-dark p-4 border-radius">
                    <div className="text-white mr-3">
                      <i className="fas fa-3x fa-phone-alt"></i>
                    </div>
                    <div className="recent-post-info">
                      <span className="text-white">(714) 328 6087</span>
                      <br />
                      <span className="text-white">Monday - Friday, 8 AM - 5 PM (PST)</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form;
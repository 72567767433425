import InnerHeader from '../components/InnerHeader';

function TermsAndConditions() {
  return (
    <>
      <InnerHeader customName={"Terms & Conditions"} />
      <section className="space-ptb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="mb-4">For those of you who are serious about having more, doing more, giving more and being more, success is achievable with some understanding of what to do, some discipline around planning and execution of those plans and belief that you can achieve your desires.</p>
              <h5 className="text-primary mt-4">1. Your Registration Obligations</h5>
              <p className="mb-4">The best way is to develop and follow a plan. Start with your goals in mind and then work backwards to develop the plan. What steps are required to get you to the goals? Make the plan as detailed as possible. Try to visualize and then plan for, every possible setback. Commit the plan to paper and then keep it with you at all times. Review it regularly and ensure that every step takes you closer to your Vision and Goals. If the plan doesn’t support the vision then change it!</p>
              <h5 className="text-primary mt-4">2. User Account, Password, and Security</h5>
              <p className="mb-4">One of the main areas that I work on with my clients is shedding these non-supportive beliefs and replacing them with beliefs that will help them to accomplish their desires.</p>
              <h5 className="text-primary mt-4">3. User Conduct</h5>
              <p className="mb-4">I truly believe Augustine’s words are true and if you look at history you know it is true. There are many people in the world with amazing talents who realize only a small percentage of their potential. We all know people who live this truth. </p>
              <ul className="mb-4 list-unstyled">
                <li className="mb-2"><i className="fas fa-chevron-right pr-2"></i>Making a decision to do something</li>
                <li className="mb-2"><i className="fas fa-chevron-right pr-2"></i>Focus is having the unwavering attention to complete what you set out to do.</li>
                <li className="mb-2"><i className="fas fa-chevron-right pr-2"></i>Nothing changes until something moves</li>
                <li className="mb-2"><i className="fas fa-chevron-right pr-2"></i>Commit your decision to paper</li>
                <li className="mb-2"><i className="fas fa-chevron-right pr-2"></i>Execution is the single biggest factor in achievement</li>
              </ul>
              <h5 className="text-primary mt-4">4. International Use</h5>
              <p className="mb-0">The best way is to develop and follow a plan. Start with your goals in mind and then work backwards to develop the plan. What steps are required to get you to the goals? Make the plan as detailed as possible. Try to visualize and then plan for, every possible setback. Commit the plan to paper and then keep it with you at all times. Review it regularly and ensure that every step takes you closer to your Vision and Goals. If the plan doesn’t support the vision then change it</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsAndConditions;

const $ = window.jQuery;

export default function mediaPopup() {
	if ($(".popup-single").length > 0 || $(".popup-gallery").length > 0 || $('.modal-onload').length > 0 || $(".popup-youtube, .popup-vimeo, .popup-gmaps").length > 0) {
    if ($(".popup-single").length > 0) {
      $('.popup-single').magnificPopup({
        type: 'image'
      });
    }
    if ($(".popup-gallery").length > 0) {
      $('.popup-gallery').magnificPopup({
        delegate: 'a.portfolio-img',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        }
      });
    }
    if ($(".popup-youtube, .popup-vimeo, .popup-gmaps").length > 0) {
      $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
      });
    }
    var $modal = $('.modal-onload');
    if ($modal.length > 0) {
      $('.popup-modal').magnificPopup({
        type: 'inline'
      });
      $(document).on('click', '.popup-modal-dismiss', function (e) {
        e.preventDefault();
        $.magnificPopup.close();
      });
      var elementTarget = $modal.attr('data-target');
      setTimeout(function () {
        $.magnificPopup.open({
          items: {
            src: elementTarget
          },
          type: "inline",
          mainClass: "mfp-no-margins mfp-fade",
          closeBtnInside: !0,
          fixedContentPos: !0,
          removalDelay: 500
        }, 0)
      }, 1500);
    }
  }
}

import Banner from '../components/Banner';
import Form from '../components/Form';
import About from '../about-us/About';
import Counter from '../components/Counter';
import CallToAction from '../components/CallToAction';
import Courses from '../components/Courses';
import WhyChooseUs from '../components/WhyChooseUs';
import Events from '../components/TemplateEvents';
import MeetOurTeam from '../components/MeetOurTeam';
import Articles from '../components/Articles';
import Subscribe from '../components/Subscribe';

function Index2() {
  return (
    <>
      <Banner />
      <Form />
      <About />
      <Counter />
      <CallToAction />
      <Courses />
      <Events />
      <MeetOurTeam />
      <Articles />
      <Subscribe />
    </>
  );
}

export default Index2;

import img1 from '../images/events/01.jpg';
import img2 from '../images/events/02.jpg';
import img3 from '../images/events/03.jpg';

function TemplateEvents() {
  return (
    <section className="space-ptb">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-7">
                <div className="section-title">
                  <h2 className="title">Latest Events</h2>
                  <p className="mb-0">Success isn’t really that difficult. There is a significant portion of the population</p>
                </div>
              </div>
              <div className="col-md-5 text-md-right mb-4 mb-md-0">
                <a className="btn btn-primary btn-round" href="/">View All</a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="events">
                  <div className="row">
                    <div className="col-12">
                    <div className="events shadow p-4 bg-white border-radius">
                      <div className="row">
                      <div className="col-lg-3">
                        <div className="events-img mb-4 mb-lg-0">
                          <img
                            className="img-fluid border-0"
                            src={img1}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 align-self-center">
                        <div className="events-content">
                          <div className="events-tag mb-3">
                            <a href="/" className="badge-danger">Art Competition</a>
                          </div>
                          <a href="event-detail.html" className="text-dark h5">Young leaders in tech policy fellowship</a>
                           <div className="events-meta my-3">
                            <ul className="list-unstyled mb-0 d-flex">
                              <li className="mr-3"><a href="/"><i className="fas fa-calendar-alt pr-1"></i> 5 Oct, 2020</a></li>
                              <li><a href="/"><i className="fas fa-clock pr-1"></i>  9:00 AM - 11:00 AM</a></li>
                            </ul>
                          </div>
                          <p className="text-dark mb-lg-0">Franklin’s extraordinary success in life and politics can be attributed to his perseverance to overcome his personal liabilities, and his desire to constantly become better.</p>
                        </div>
                      </div>
                      <div className="col-lg-3 align-self-center text-lg-right text-left">
                          <a className="btn btn-dark btn-round" href="/">Join Event</a>
                      </div>
                    </div>
                    </div>
                    <div className="events shadow mt-4 p-4 bg-white border-radius">
                      <div className="row">
                      <div className="col-lg-3">
                        <div className="events-img mb-4 mb-lg-0">
                          <img
                            className="img-fluid border-0"
                            src={img2}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 align-self-center">
                        <div className="events-content">
                          <div className="events-tag mb-3">
                            <a href="/" className="badge-success">Architecture Events</a>
                          </div>
                          <a href="event-detail.html"  className="text-dark h5">Expert panel- careers in tech policy</a>
                           <div className="events-meta my-3">
                            <ul className="list-unstyled mb-0 d-flex">
                              <li className="mr-3"><a href="/"><i className="fas fa-calendar-alt pr-1"></i> 05 Nov, 2020</a></li>
                              <li><a href="/"><i className="fas fa-clock pr-1"></i>  2:00 PM - 4:00 PM</a></li>
                            </ul>
                          </div>
                          <p className="text-dark mb-lg-0">Next time you really want to achieve something, take time to focus on your own personal journal. What is your temptation that is standing in your way to greatness?</p>
                        </div>
                      </div>
                      <div className="col-lg-3 align-self-center text-lg-right text-left">
                          <a className="btn btn-dark btn-round" href="/">Join Event</a>
                      </div>
                    </div>
                    </div>
                    <div className="events shadow mt-4 p-4 bg-white border-radius">
                      <div className="row">
                      <div className="col-lg-3">
                        <div className="events-img mb-4 mb-lg-0">
                          <img
                            className="img-fluid border-0"
                            src={img3}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 align-self-center">
                        <div className="events-content">
                          <div className="events-tag mb-3">
                            <a href="/" className="badge-warning">Medicine Events</a>
                          </div>
                          <a href="event-detail.html" className="text-dark h5">Health and life expectancy</a>
                           <div className="events-meta my-3">
                            <ul className="list-unstyled mb-0 d-flex">
                              <li className="mr-3"><a href="/"><i className="fas fa-calendar-alt pr-1"></i> 27 Nov, 2020</a></li>
                              <li><a href="/"><i className="fas fa-clock pr-1"></i>  4:00 PM - 6:00 PM</a></li>
                            </ul>
                          </div>
                          <p className="text-dark mb-lg-0">Get yourself nice and relaxed and settled. Concentrate on your breathing, engage in the moment and spend some time being still, quiet and drift inside of your own mind.</p>
                        </div>
                      </div>
                      <div className="col-lg-3 align-self-center text-lg-right text-left">
                          <a className="btn btn-dark btn-round" href="/">Join Event</a>
                      </div>
                    </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TemplateEvents;

import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';

function SubmitTranscript({ academicRecordId, transcript }) {
  const { loggedInUser } = useAuth();

  const [errors, setErrors] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();

  function onFileUpload(e) {
    const formData = new FormData();

    formData.append("transcript", e.target.files[0]);
    formData.append("record_id", academicRecordId);

    axios.post(
      `${process.env.REACT_APP_BACKEND_API}/online_applications/${selectedApp.id}/upload_transcript`,
      formData,
      { headers: { Authorization: loggedInUser.auth } },
    )
      .then(response => {
        if (response.data.status === 200) {
          console.log('Success:', response.data.data);
        } else {
          console.log('Error:', response.data.errors);
        }
      })
      .catch(error => {
        console.log('Error:', error.response.data.message);
      })
  };

  return (
    <>
      <div className="bg-dark clearfix">
        <h6 className="p-2">Submit Transcript</h6>
      </div>
      <p>
        Please upload a scanned copy or digital facsimile of your transcript from this institution.
        You may upload those pages now as a single- or multi-page PDF, or each page as an image file.
        Your scanned document may be large and may take several minutes to upload depending upon the speed
        of your connection.
      </p>
      {transcript
        ? (
          <p className="text-success">
            We have successfully received your transcript.
          </p>
        ) : (
          <>
            <label>PDF or Scanned Pages</label>
            <input
              type="file"
              className="form-control"
              onChange={onFileUpload}
            />
          </>
        )
      }
    </>
  );
}

export default SubmitTranscript;

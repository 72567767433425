import { useLocation } from 'react-router-dom';
import logo from '../images/logo/logo_nbg.png';
import Academics from "../routes/Academics";
import Faculty from "../academics/Faculty";

const lmsLink = 'https://canvas.sakyauniversity.com/login/canvas';
const libraryLink = 'https://library.sakyauniversity.com/';

function hasNegativeMargin(pathname) {
  return ['/index-02', '/teachers', '/teachers-single', '/gallery'].includes(pathname);
}

function Footer() {
  let location = useLocation();

  return (
    <>
      <footer
          className={`bg-overlay-black-100 bg-holder footer ${hasNegativeMargin(location.pathname) ? 'mt-n5' : ''}`}
      >
        <div className="container pt-5">
          <div className="horizontal-line"></div>
          <div className="row mb-lg-3">
            <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0 pr-lg-5">
              <a href="/">
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img className="img-fluid footer-logo" src={logo} alt=""/>
                  <div className="ml-2 mt-2 align-items-center flex-column" style={{display: 'flex'}}>
                    <div className="text-white" style={{fontSize: '18px', fontWeight: 'bold', lineHeight: 1}}>SAKYA
                      BUDDHA
                    </div>
                    <div className="text-white" style={{fontSize: '18px', fontWeight: 'bold'}}>UNIVERSITY</div>
                  </div>
                </div>

              </a>
              <div className="footer-contact-info">
                <div className="contact-address mt-4">
                  <div className="contact-item">
                    <i className="fas fa-map-marker-alt"></i>
                    <p className="ml-3 mb-0 text-white">
                      3404-3412 Westminster Ave.
                      <br/>
                      Santa Ana, CA 92703
                      <br/>
                      United States
                    </p>
                  </div>
                  <div className="contact-item">
                    <i className="fas fa-microphone-alt"></i>
                    <p className="mb-0 font-weight-bold ml-3"><a className="text-white" href="/">+1-714-328–6087</a></p>
                  </div>
                  <div className="contact-item mb-4">
                    <i className="fas fa-headset"></i>
                    <a className="text-white ml-3 text-white" href="/">info@sakyauniversity.com</a>
                  </div>
                  <div className="social-icon d-flex justify-content-start" style={{marginLeft: '38px'}}>
                    <ul className="social-icon ">
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=61556014311099" target="_blank"><i
                            className="fab fa-facebook-f"></i></a>
                      </li>
                      <li>
                        <a href="https://twitter.com/sakyauniversity" target="_blank"><i className="fab fa-twitter"></i></a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/sakyauniversity" target="_blank"><i
                            className="fab fa-instagram"></i></a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/channel/UCsSqWlWuYvwV13KW9pnaMdw" target="_blank"><i
                            className="fab fa-youtube"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
              <h5 className="text-white mb-2 mb-sm-4 mt-3">About SBU</h5>
              <div className="footer-link">
                <ul className="list-unstyled mb-0">
                  <li><a className="text-white" href="/about-SBU#mission">SBU's Mission</a></li>
                  <li><a className="text-white" href="/about-SBU#history">History of SBU</a></li>
                  <li><a className="text-white" href="/about-SBU#trusteeMsg">Board of Trustees</a></li>
                  <li><a className="text-white" href="/about-SBU#bodhi">Planter of the Bodhi Seed</a></li>
                  <li><a className="text-white" href="/academics#Faculty">Faculty</a></li>

                  <li><a className="text-white" href="/Accreditation">Accreditation</a></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-4 mb-sm-0">
              <h5 className="text-white mb-2 mb-sm-4 mt-3">Our Campus</h5>
              <div className="footer-link">
                <ul className="list-unstyled mb-0">
                  <li><a className="text-white" href="/admissions/financial-aid">Financial Aid</a></li>
                  {/*<li><a className="text-white" href="/private-policy">Privacy Policy</a></li>*/}
                  <li><a className="text-white" href="/information-tech">Information Technologies</a></li>
                  <li><a className="text-white" href="/campuses">Campuses</a></li>
                  <li><a className="text-white" href="news-letter">News Letter</a></li>
                  <li><a className="text-white" href="/all-events">Events</a></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2">
              <h5 className="text-white mb-2 mb-sm-4 mt-3">Campus Life</h5>
              <div className="footer-link">
                <ul className="list-unstyled mb-0">
                  <li><a className="text-white" href={lmsLink}>Canvas</a></li>
                  <li><a className="text-white" href="/academics#AcademicCalendar">Academic Calendar</a></li>
                  <li><a className="text-white" href="/academics#CourseCatalog">Courses Catalog</a></li>
                  <li><a className="text-white" href={libraryLink}>Library</a></li>
                  <li><a className="text-white" href="/admissions/requirements">Apply For Admissions</a></li>
                  {/*<li><a className="text-white" href="faq">Pay My Tuition</a></li>*/}
                </ul>
              </div>
              <div className="d-flex justify-content-end pt-5">
                <a className="btn btn-primary" href="/">MySBU</a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-dark py-4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 text-center">
                <p className="mb-0 text-white">©Copyright 2024 Sakya Buddha University</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

function Tuition() {
  return (
    <>
      <div className="row text-justify">
        <div className="col-lg-12">
          <img className="img-fluid border-radius" src="images/blog/03.jpg" alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="mb-3 mt-4">Tuition, Fees, and Costs</h4>
              <p>A student&#39;s Cost of Attendance is comprised of tuition charges (per academic year) along with an
                estimated budget covering living expenses, books and supplies, and transportation. It is
                standardized for all students annually, and actual expenses may fluctuate based on individual
                student lifestyles.</p>
              <p>Tuition rates are determined by a per-unit pricing structure specific to each program and are
                subject to an annual increase of 4-6% as determined by Sakya Buddha University. It is essential
                to note that a student&#39;s total financial aid awarded should not surpass their Cost of Attendance.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive mt-4">
            <table className="table table-striped text-center mb-0">
              <thead>
                <tr className="bg-dark">
                  <th className="text-white" scope="col">Fees Descriptions</th>
                  <th className="text-white" scope="col">Cost/unit </th>
                  <th className="text-white" scope="col">Per Semester</th>
                  <th className="text-white" scope="col">Per Program</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Application fee (one time fee, non-refundable)</td>
                  <td></td>
                  <td></td>
                  <td>200</td>
                </tr>
                <tr>
                  <td>Enrollment deposit fee (one time fee, non-refundable)</td>
                  <td></td>
                  <td></td>
                  <td>500</td>
                </tr>
                <tr>
                  <td>Tuition Cost*</td>
                  <td>500</td>
                  <td>6,000</td>
                  <td>24,000</td>
                </tr>
                <tr>
                  <td>Books (estimate $100/class)** </td>
                  <td>100</td>
                  <td>400</td>
                  <td>1,600</td>
                </tr>
                <tr>
                  <td>Students Association Fees (optional) </td>
                  <td>150</td>
                  <td>150</td>
                  <td>600</td>
                </tr>
                <tr>
                  <td>Library Service Fees</td>
                  <td>50</td>
                  <td>50</td>
                  <td>200</td>
                </tr>
                <tr>
                  <td>Computer Labs</td>
                  <td>75</td>
                  <td>75</td>
                  <td>300</td>
                </tr>
                <tr>
                  <td>Student Health Insurance (optional) </td>
                  <td>1,200</td>
                  <td>1,200</td>
                  <td>4,800</td>
                </tr>
                <tr>
                  <td>Dormitory Room & Board (estimate $1,000 per month) (only for Monastics)</td>
                  <td>1,000</td>
                  <td>6,000</td>
                  <td>24,000</td>
                </tr>
                {/*<tr className="font-weight-bold">*/}
                {/*  <td></td>*/}
                {/*  <td>$3,075.00</td>*/}
                {/*  <td>$13,875.00</td>*/}
                {/*  <td>$56,200.00</td>*/}
                {/*</tr>*/}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive mt-4">
            <table className="table table-striped text-center mb-0">
              <thead>
                <tr className="bg-dark">
                  <th className="text-white" scope="col">Other Expenses</th>
                  <th className="text-white" scope="col">Cost/unit </th>
                  <th className="text-white" scope="col">Per Semester</th>
                  <th className="text-white" scope="col">Per Program</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Meals, essentials, travels, etc. $1,000/month***</td>
                  <td>1,000</td>
                  <td>6,000</td>
                  <td>24,000</td>
                </tr>
                {/*<tr className="font-weight-bold">*/}
                {/*  <td>Total Estimate of Tuition & Living Costs </td>*/}
                {/*  <td></td>*/}
                {/*  <td>$19,875.00</td>*/}
                {/*  <td>$80,200.00</td>*/}
                {/*</tr>*/}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12">
              <div className="mt-4">
                <p>*$500 per unit, each class takes up to 3 units, students will take 4 classes per semester.</p>
                <p>**Students take 4 classes per semester for a total of 4 semesters.</p>
                <p>***Students out of pocket cost - not paid to the university.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tuition;

import axios from 'axios';
import { noop } from 'lodash';

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const convertKeysToSnakeCase = obj => {
  let newObj = {};
  Object.keys(obj).forEach(key => newObj[camelToSnakeCase(key)] = obj[key]);
  return newObj;
}

export const snakeToCamelCase = str => str.replace(/([-_][a-z])/g, group => group
  .toUpperCase()
  .replace('-', '')
  .replace('_', ''));

export const convertKeysToCamelCase = obj => {
  let newObj = {};
  Object.keys(obj).forEach(key => newObj[snakeToCamelCase(key)] = obj[key]);
  return newObj;
}

export function getApps(loggedInUser, successCallback, errorCallback, unauthorizedCallback = noop) {
  return axios.get(
    `${process.env.REACT_APP_BACKEND_API}/online_applications`,
    { headers: { Authorization: loggedInUser.auth } },
  )
    .then(response => {
      if (response.data.status === 200) {
        successCallback(response.data.data);
      } else {
        errorCallback(response.data.errors);
      }
    })
    .catch(error => {
      errorCallback(error.message);
      if (error.response.status === 401) {
        unauthorizedCallback();
      }
    })
}

export function getApp(loggedInUser, selectedApp, successCallback, errorCallback) {
  return axios.get(
    `${process.env.REACT_APP_BACKEND_API}/online_applications/${selectedApp.id}`,
    { headers: { Authorization: loggedInUser.auth } },
  )
    .then(response => {
      if (response.data.status === 200) {
        successCallback(convertKeysToCamelCase(response.data.data));
      } else {
        errorCallback(response.data.errors);
      }
    })
    .catch(error => {
      errorCallback(error);
    })
}

export function createApp(loggedInUser, successCallback, errorCallback) {
  return axios.post(
    `${process.env.REACT_APP_BACKEND_API}/online_applications`,
    {},
    { headers: { Authorization: loggedInUser.auth } },
  )
    .then(response => {
      if (response.data.status === 200) {
        successCallback(response.data.data);
      } else {
        errorCallback(response.data.errors);
      }
    })
    .catch(error => {
      errorCallback(error.response.data.message);
    })
}

export function updateApp(loggedInUser, selectedApp, data, successCallback, errorCallback) {
  return axios.put(
    `${process.env.REACT_APP_BACKEND_API}/online_applications/${selectedApp.id}`,
    convertKeysToSnakeCase(data),
    { headers: { Authorization: loggedInUser.auth } },
  )
    .then(response => {
      if (response.data.status === 200) {
        successCallback(convertKeysToCamelCase(response.data.data));
      } else {
        errorCallback(response.data.errors);
      }
    })
    .catch(error => {
      errorCallback(error.response.data.message);
    })
}


function Libraries() {
    return (
      <>
          <div className="my-sm-3">
            <h4 className="mb-4">Undergraduate Academics</h4>
            <p>Content Required...</p>
          </div>
      </>
    );
  }
  
  export default Libraries;
import React from 'react';
import thc1 from '../images/gallery/history_sbu.jpeg';

function History() {
    return (
        <>
            <div id='history'>

                <h4 className="title text-center mb-4">HISTORY OF SBU</h4>
                <div className='center-icon-img'>
                    <img
                        src={thc1}
                        alt=""
                        className="img-fluid rounded-circle mr-3 icon-img"
                        style={{width: '250px', height: '250px', border: '5px solid gray'}}
                    />
                </div>
                <p className="text-justify">
                    The inception of Sakya Buddha University dates to 2022 when Venerable Thich Huyen Chau founded the school in Santa Ana, California, in Orange County. The university opened for classes during the fall semester of 2023. This nonprofit, private institution is a groundbreaking collaboration between Vietnamese and American Buddhists aiming to establish an American university that harmonizes the traditions of liberal arts with the profound insights of Buddhism.
                </p>
                <p className="text-justify">
                    SBU stands as a liberal arts university rooted in Buddhist principles, catering to both undergraduate and graduate students. It fuses Western academic knowledge with the wisdom ingrained in Eastern traditions, fostering a comprehensive, experiential learning atmosphere for its students. Our primary aim is to offer an affordable, high-quality education that empowers students to confidently navigate the world, embodying and promoting the values of their SBU degree.
                </p>
                <p className="text-justify">
                    In line with our commitment to fairness and accessibility, SBU offers programs at their production cost. SBU remains dedicated to serving a diverse array of students, providing programs centered on Buddhist philosophy and liberal arts, and welcoming individuals from varied walks of life.
                </p>
            </div>
        </>
    );
}

export default History;

import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { noop } from 'lodash';

const PayPalButtonsWrapper = ({
  showSpinner,
  style = {},
  createOrder = noop,
  onApprove = noop,
  onCancel = noop,
  onError = noop,
}) => {
  const [{ isPending }] = usePayPalScriptReducer();

  return (
    <>
      { (showSpinner && isPending) && <div className="spinner-border" /> }
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[style]}
        fundingSource="paypal"
        createOrder={createOrder}
        onApprove={onApprove}
        onCancel={onCancel}
        onError={onError}
      />
    </>
  );
}

export default PayPalButtonsWrapper;

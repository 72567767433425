function SBUAdmissions() {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <img className="img-fluid border-radius" src="images/blog/03.jpg" alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="mb-3 mt-4">SBU Admissions</h4>
              <p>Sakya Buddha University offers a distinctive graduate education, where students attain advanced
                and professional degrees in tailored learning environments dedicated to addressing both personal
                and professional aspirations.</p>
              <p>Our array of graduate programs encompasses master&#39;s and doctoral degrees, along with
                specialized certifications. Classes meet at SBU’s Southern California campus and online.</p>

              <h5>Master of Arts in Buddhist Philosophy Admission</h5>
              <p>Sakya Buddha University invites you to embark on a transformative educational journey with
                our Master of Art in Buddhist Philosophy program. Immerse yourself in a dynamic learning
                environment where experienced faculty members are dedicated to teaching and mentoring.
                Collaborate with peers from diverse backgrounds, gain valuable perspectives, and engage in real-
                world learning experiences that will not only deepen your understanding of Buddhist philosophy
                but also prepare you for a successful career.</p>
              <p>Explore the unique offerings of our Master of Arts in Buddhist Philosophy program and learn
                more about the admissions process. We invite you to discover the enriching possibilities that
                await you in our academic community.</p>

              <h6>Application:</h6>
              <label>Students can access online application through SBU’s Admissions</label>
              <label> website: <a href="/admissions/requirements">www.sakyauniversity.com</a></label>

              <h6>Deadlines:</h6>
              <label>This program offers open admissions. We are committed to working with applicants who submit
                their applications after the deadline on a case-by-case basis, accommodating them based on
                available space.</label>

            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive mt-4">
            <table className="table table-striped text-center mb-0">
              <thead>
                <tr className="bg-dark">
                  <th className="text-white" scope="col">Deadlines</th>
                  <th className="text-white" scope="col">Summary</th>
                  <th className="text-white" scope="col">Dates</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Final Tuition Deposit for Spring term </td>
                  <td>The deadline for submitting the student agreement form and enrollment deposit in Canvas</td>
                  <td>December 9 , 2023 </td>
                </tr>
                <tr>
                  <td>Classes starts for Spring term</td>
                  <td>First day of Spring classes </td>
                  <td>January 8, 2024 </td>
                </tr>
                <tr>
                  <td>Final Tuition Deposit for Summer term </td>
                  <td>The deadline for submitting the student agreement form and enrollment deposit in Canvas </td>
                  <td>May 6, 2024</td>
                </tr>
                <tr>
                  <td>Classes starts for Summer term</td>
                  <td>First day of Summer classes </td>
                  <td>June 3, 2024</td>
                </tr>
                <tr>
                  <td>Final Tuition Deposit for Fall term</td>
                  <td>The deadline for submitting the student agreement form and enrollment deposit in Canvas </td>
                  <td>July 8, 2024 </td>
                </tr>
                <tr>
                  <td>Classes starts for Fall term</td>
                  <td>First day of Fall classes </td>
                  <td>September 2, 2024 </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default SBUAdmissions;

import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getApp, convertKeysToCamelCase } from '../utils';
import axios from 'axios';
import { map } from 'lodash';
import Payment from './Payment';

const AWAITING_PAYMENT = 'Awaiting payment';
const PAYMENT_SUBMITTED = 'Payment submitted';
const PAYMENT_WAIVED = 'Payment waived';

const uploadTypes = [
  null,
  'Miscellaneous Document',
  'Proof of English Proficiency',
  'High School Diploma',
  'Resume',
  'Unofficial TOEFL/IELTS/CAE Test Scores',
];

function getPaymentStatus(app) {
  if (app.paymentWaived) {
    return PAYMENT_WAIVED;
  }
  if (app.paymentSubmitted) {
    return PAYMENT_SUBMITTED;
  }
  return AWAITING_PAYMENT;
}

function Status() {
  const { loggedInUser } = useAuth();

  const [errors, setErrors] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();
  const [data, setData] = useState(selectedApp || {});

  useEffect(() => {
    window.jQuery('body,html').animate({
      scrollTop: 460
    }, 1000);
  }, []);

  function onFileChosen(e) {
    setFile(e.target.files[0]);
  };

  function onFileUpload() {
    const formData = new FormData();
    formData.append("file", file);
    if (uploadType) {
      formData.append("upload_type", uploadType);
    }

    return axios.post(
      `${process.env.REACT_APP_BACKEND_API}/online_applications/${selectedApp.id}/upload`,
      formData,
      { headers: { Authorization: loggedInUser.auth } },
    )
      .then(response => {
        if (response.data.status === 200) {
          getApp(loggedInUser, selectedApp, setData, setErrors);
          setFile(null);
          setUploadType(null);
          document.getElementById("uploadCaptureInputFile").value = ""
        } else {
          setErrors(response.data.errors);
        }
      })
      .catch(error => {
        setErrors(error.response.data.message);
      })
  };

  function updatePaymentData(paymentData) {
    setData({
      ...data,
      paymentSubmitted: paymentData.paymentSubmitted,
      paymentSubmittedAt: paymentData.paymentSubmittedAt,
    });
  }

  const records = map(data.academicRecords, convertKeysToCamelCase);
  const uploads = map(data.uploads, u => convertKeysToCamelCase(u));
  const awaitingPayment = !data || getPaymentStatus(data) === AWAITING_PAYMENT;

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <h1>Your Application</h1>
            <p className='text-justify'>
              <strong>Term:</strong>{` ${(data.term || '')} Semester`}
              <br />
              <strong>Program:</strong>{` ${data.program} `}
            </p>
            <div className="bg-dark clearfix">
              <h6 className="p-2">Your Application Status</h6>
            </div>
            <p className='text-justify'>
              Your application file is currently being reviewed for completeness. If we find
              any additional documents are required, we will contact you via email,
              and your checklist will be updated accordingly.
            </p>

            <div className="bg-dark clearfix">
              <h6 className="p-2">Recent Activities</h6>
            </div>
            <table className="table">
              <thead>
                <tr className="bg-gray">
                  <th>Status</th>
                  <th>Details</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i className={data.paymentSubmitted ? "text-success fas fa-check mr-2" : "text-danger fas fa-times mr-2"} />
                    {data.paymentSubmitted ? 'Received' : 'Awaiting'}
                  </td>
                  <td>
                    {`${getPaymentStatus(data)}: 50.00 USD`}
                    <br />
                    Application Fee
                    <br />
                    <Payment
                      awaitingPayment={awaitingPayment}
                      selectedAppId={selectedApp.id}
                      updatePaymentData={updatePaymentData}
                    />
                  </td>
                  <td>{data.paymentSubmittedAt
                    ? new Date(data.paymentSubmittedAt).toLocaleDateString("en-US")
                    : ''}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="bg-dark clearfix">
              <h6 className="p-2">Application Checklist</h6>
            </div>
            <p className='text-justify'>
              We have received all required documents at this time.
              We will notify you by email if any additional documentation is required.
            </p>
            <table className="table">
              <thead>
                <tr className="bg-gray">
                  <th>Status</th>
                  <th>Details</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {/**<tr>
                  <td>
                    <i className="text-success fas fa-check mr-2" />
                    Received
                  </td>
                  <td>
                    Resume
                  </td>
                  <td>01/23/2024</td>
                </tr>**/}
                {records.map(r => (
                  <tr key={`rec-${r.id}`}>
                    <td>
                      <i className={r.transcript ? "text-success fas fa-check mr-2" : "text-danger fas fa-times mr-2"} />
                      {r.transcript ? 'Received' : 'Awaiting'}
                    </td>
                    <td>
                      {`Transcript for ${r.institution}`}
                      <br />
                      {`Dates Attended: ${r.fromMonth || '_'}/${r.fromYear || '_'} to ${r.toMonth || '_'}/${r.toYear || '_'}, Degree: ${r.degree || 'Not provided'}`}
                    </td>
                    <td>{r.transcriptUploadedAt ? new Date(r.transcriptUploadedAt).toLocaleDateString("en-US") : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="bg-dark clearfix">
              <h6 className="p-2">Upload Materials</h6>
            </div>
            <p className='text-justify'>
              To submit documents for inclusion in your admission application,
              please select the document type below and upload a Word document, PDF, or scanned image file.
              Please allow 15-20 minutes for updates to your checklist to be reflected.
            </p>
            {uploads.length > 0 && (
              <>
                <p className='text-justify'>
                  We have received the following documents from you:
                </p>
                <ul>
                  {map(uploads, u => (
                    <li key={`upl-${u.createdAt}`}>
                      {`${new Date(u.createdAt).toLocaleDateString("en-US")}
                      ${new Date(u.createdAt).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })}
                      - ${u.uploadType || 'N/A'}`}
                    </li>
                  ))}
                </ul>
              </>
            )}
            <div className="d-flex align-items-center">
              {errors && (
                <p className="text-left text-danger">{errors}</p>
              )}
              <select
                className="form-control mr-2 px-2"
                onChange={e => setUploadType(e.target.value)}
              >
                {uploadTypes.map((s, idx) => (
                  <option
                    key={`type-${idx}`}
                    value={s}
                    selected={s === uploadType}
                  >
                    {s}
                  </option>
                ))}
              </select>
              <input
                id="uploadCaptureInputFile"
                type="file"
                className="form-control"
                onChange={onFileChosen}
              />
              <button
                className="btn btn-primary ml-2"
                type="button"
                onClick={onFileUpload}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Status;

import InnerHeader from '../components/InnerHeader';
import imgBg from '../images/gallery/wayToGive.png'
import {useHashChange} from "../hooks/useHashChange";


const links = [
    {name: 'Ways to Give', component: ''},
];

function WaysToGive() {
    const [activeLink, setActiveLink] = useHashChange(links);
    const Component = activeLink.component;

    return (
        <>

            <InnerHeader backgroundImage={imgBg}/>
            <section className="space-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mb-5 mb-lg-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="shadow border-radius p-4">
                                        <div className="section-title my-sm-3 text-justify">
                                            <h4 className="mb-4">Ways to Give</h4>
                                            <p>Explore below the diverse range of programs, scholarships, and
                                                opportunities that contribute to the transformative education provided
                                                at Sakya Buddha University thanks to your generous support. We welcome
                                                various forms of contributions, including cash or stock transfers, as
                                                well as deferred gifts like will bequests. You have the flexibility to
                                                make donations conveniently through online platforms, over the phone, by
                                                mail, or in person. Every donation, regardless of size, is invaluable,
                                                ensuring that your support sustains Sakya Buddha University's pioneering
                                                role in contemplative education.</p>
                                            <p>For guidance on how your generosity can shape a lasting legacy, reach out
                                                to Venerable Thich Huyen Chau, the founder of SBU, at
                                                thichhuyenchau@sakyauniversity.com, phone (714) 328 6087.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="course-sidebar shadow">
                                <div className="widget">
                                    <div className="widget-title">
                                        <h5>Invest in SBU</h5>
                                    </div>
                                    <div className="widget-categories">
                                        {links.slice(1).map(link => (
                                            <button
                                                key={link.name}
                                                className="btn btn-link btn-block pl-0 text-left d-flex justify-content-between align-items-center"
                                                type="button"
                                                onClick={() => setActiveLink(link)}
                                            >
                                                <span>{link.name}</span>
                                                <i className="fas fa-chevron-right text-right ml-auto"></i>
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                <div className="widget">
                                    <div className="widget-title">
                                        <h5>Contact the Office</h5>
                                    </div>
                                    <div className="widget-categories">
                                        <h6>Mailing Address</h6>
                                        <p>3404 & 3412 Westminster Ave</p>
                                        <p>Santa Ana, CA 92703</p>
                                        <h6>Email</h6>
                                        <p>sakyabuddha@vienphathoc.org</p>
                                        <h6>Phone</h6>
                                        <p>(714) 328 6087</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
        ;
}

export default WaysToGive;

import Banner from '../components/Banner';
import About from '../about-us/About';
import CallToAction from '../components/CallToAction';
import Event from '../components/Event';
import Divider from "../components/divider";

function Home() {
  return (
    <>
      <Banner/>
      <Divider/>
      <About/>
      <Divider/>
      <Event/>
    </>
  );
}

export default Home;

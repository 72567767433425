import { getNames } from 'country-list';
import states from 'states-us';
import majors from '../utils/majors';
import SubmitTranscript from './SubmitTranscript';
import {useEffect, useState} from "react";

const degrees = [
  'No Degree Awarded or Expected',
  'Certificate',
  "Associate's",
  "Bachelor's",
  "Master's",
  "Ph.D",
];
const US = 232

function InstitutionForm({ data, setData, handleSubmit, handleClose }) {
  data.country = getNames()[US]
  return (
    <div className="modal fade" id="institutionModal" tabIndex="-1" aria-labelledby="institutionModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="institutionModalLabel">Add Institution</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true"><i className="fas fa-times" /></span>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <p>
                If your particular field of study is not listed, you may select
                the most similar major or simply leave this blank.
              </p>
              <div className="form-group">
                <label>Institution</label>
                <input
                    type="text"
                    className="form-control"
                    defaultValue={data.institution}
                    onChange={e => setData({...data, institution: e.target.value})}
                />
              </div>
              <div className="form-group">
                <label>Country</label>
                <select
                  className="form-control"
                  id="country"
                  onChange={e => setData({ ...data, country: e.target.value })}
                >
                  {getNames().map((c, idx) => (
                    <option
                      key={`country-${idx}`}
                      value={c}
                      selected={c === data.country}
                    >
                      {c}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                    type="text"
                    className="form-control"
                    defaultValue={data.city}
                    onChange={e => setData({...data, city: e.target.value})}
                />
              </div>
              <div className="form-group">
                <label htmlFor="state">State</label>
                <select
                    className="form-control"
                    placeholder="Select State"
                    onChange={e => setData({...data, state: e.target.value})}
                >
                  <option selected={!data.state}>N/A</option>
                  {states.map((s, idx) => (
                      <option
                          key={`state-${idx}`}
                          value={s.abbreviation}
                          selected={s.abbreviation === data.state}
                      >
                        {s.name}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Dates Attended</label>
                <div className="d-flex align-items-center">
                  <input
                      type="number"
                      min="1"
                      max="12"
                      step="1"
                      className="form-control mr-2"
                      defaultValue={data.fromMonth}
                      onChange={e => setData({...data, fromMonth: e.target.value})}
                  />
                  <input
                      type="number"
                      min="1900"
                      max="2099"
                      step="1"
                      className="form-control"
                      defaultValue={data.fromYear}
                      onChange={e => setData({...data, fromYear: e.target.value})}
                  />
                  <p className="mx-3 my-0">to</p>
                  <input
                      type="number"
                      min="1"
                      max="12"
                      step="1"
                      className="form-control mr-2"
                      defaultValue={data.toMonth}
                      onChange={e => setData({...data, toMonth: e.target.value})}
                  />
                  <input
                      type="number"
                      min="1900"
                      max="2099"
                      step="1"
                      className="form-control"
                      defaultValue={data.toYear}
                      onChange={e => setData({...data, toYear: e.target.value})}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Level of Study</label>
                <select
                    className="form-control"
                    onChange={e => setData({...data, levelOfStudy: e.target.value})}
                >
                  {[null, 'Undergraduate', 'Graduate'].map((s, idx) => (
                      <option
                          key={`level-${idx}`}
                          value={s}
                          selected={s === data.levelOfStudy}
                      >
                        {s}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Degree</label>
                <select
                    className="form-control"
                    onChange={e => setData({...data, degree: e.target.value})}
                >
                  {degrees.map((s, idx) => (
                      <option
                          key={`degree-${idx}`}
                          value={s}
                          selected={s === data.degree}
                      >
                        {s}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Major</label>
                <select
                    className="form-control"
                    onChange={e => setData({...data, major: e.target.value})}
                >
                  {majors.map((s, idx) => (
                      <option
                          key={`major-${idx}`}
                          value={s}
                          selected={s === data.major}
                      >
                        {s}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                {/*addm more major*/}
                <label>Major 2</label>
                <select
                    className="form-control"
                    onChange={e => setData({...data, secondMajor: e.target.value})}
                >
                  {majors.map((s, idx) => (
                      <option
                          key={`secondMajor-${idx}`}
                          value={s}
                          selected={s === data.secondMajor}
                      >
                        {s}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>GPA</label>
                <div className="d-flex align-items-center">
                  <input
                      type="text"
                      className="form-control"
                      style={{width: '120px'}}
                      defaultValue={data.gpa}
                      onChange={e => setData({...data, gpa: e.target.value})}
                  />
                  <p className="mx-3">on a scale of</p>
                  <input
                      type="text"
                      className="form-control"
                      style={{width: '120px'}}
                      defaultValue={data.maxGpa}
                      onChange={e => setData({...data, maxGpa: e.target.value})}
                  />
                  <p className="mx-3">(e.g., 4.0, 4.3, 5.0, 15, 100) (format 4.00)</p>
                </div>
              </div>
              <div className="form-group">
                <label>List scholarships, prizes, honors or other recognitions</label>
                <textarea
                    rows="2"
                    className="form-control"
                    defaultValue={data.recognitions}
                    onChange={e => setData({...data, recognitions: e.target.value})}
                />
              </div>
              <SubmitTranscript
                  academicRecordId={data.id}
                  transcript={data.transcript}
              />
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">Save changes</button>
              <button type="button" className="btn btn-secondary" onClick={handleClose}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default InstitutionForm;

import React from 'react';

const UpcomingEvent = ({ dateClass = '', monthClass = '', date, month, title, time, header }) => {

    return (
        <div
            className="d-flex justify-content-start mb"
            style={{ height: '100%', marginBottom: '55px' }}
        >
            <div
                className="d-flex flex-column flex-shrink-0 justify-content-center align-items-center col-4"
                style={{ minWidth: '100px' }}
            >
                <span className={dateClass}>{date}</span>
                <span className={monthClass}>{month}</span>
            </div>
            <div className="px-2 align-self-center">
                <div className="events-content">
                    <div className="events-tag">
                        <a className="badge-danger">{title}</a>
                    </div>
                    {header && (
                        <p className="text-dark text-justify" dangerouslySetInnerHTML={{ __html: header }}></p>
                    )}

                    <div className="events-meta my-3">
                        <ul className="list-unstyled mb-0 d-flex">
                            <li><a href="/"><i className="fas fa-clock pr-1"></i> {time}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpcomingEvent;

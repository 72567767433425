const $ = window.jQuery;
const jQuery = window.jQuery;

export default function select2() {
	if ($('.basic-select').exists()) {
    var select = jQuery(".basic-select");
    if (select.length > 0) {
      $('.basic-select').select2({dropdownCssClass : 'bigdrop'});
    }
  }
}


function Libraries() {
    return (
      <>
          <div className="my-sm-3">
            <h4 className="mb-4">SBU Libraries</h4>
            <p>Content Required...</p>
            <p>Visit our <a href="https://library.sakyauniversity.com/"> online library</a></p>
          </div>
      </>
    );
  }
  
  export default Libraries;
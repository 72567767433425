import InnerHeader from '../components/InnerHeader';

function CourseDetail() {
  return (
    <>
      <InnerHeader />
      <section className="space-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-5 mb-lg-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="shadow border-radius p-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <img className="img-fluid border-radius" src="images/blog/03.jpg" alt="" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="mb-3 mt-4">Course Features</h4>
                            <p>The first thing to remember about success is that it is a process – nothing more, nothing less. There is really no magic to it and it’s not reserved only for a select few people. As such, success really has nothing to do with luck, coincidence or fate. It really comes down to understanding the steps in the process and then executing on those steps.</p>
                          </div>
                          <div className="col-md-6">
                            <div className="list">
                              <ul className="pl-0 mb-md-0">
                                <li><i className="fas fa-check text-primary pr-2"></i>Once you have a clear.</li>
                                <li><i className="fas fa-check text-primary pr-2"></i>It could be time, effort, sacrifice.</li>
                                <li><i className="fas fa-check text-primary pr-2"></i>Have some fun and hypnotize yourself.</li>
                                <li><i className="fas fa-check text-primary pr-2"></i>Introspection is the trick.</li>
                                <li><i className="fas fa-check text-primary pr-2"></i>Understand what you want.</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="list">
                              <ul className="pl-0 mb-0">
                                <li><i className="fas fa-check text-primary pr-2"></i>This is a critical factor.</li>
                                <li><i className="fas fa-check text-primary pr-2"></i>For this reason.</li>
                                <li><i className="fas fa-check text-primary pr-2"></i>Simply by asking ourselves lots.</li>
                                <li><i className="fas fa-check text-primary pr-2"></i>What does success look like.</li>
                                <li><i className="fas fa-check text-primary pr-2"></i>How will this achievement change.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="section-title mt-5 mb-4">
                      <h4 className="mb-4">Lecturers</h4>
                        <div className="course-details-lecturer bg-dark border-radius p-3 p-lg-4">
                          <ul className="pl-0 mb-0">
                              <li className="d-md-flex align-items-center justify-content-between">
                                  <div className="lecturer-img mr-md-2">
                                    <img src="images/avatar/02.jpg" className="img-fluid" alt="" />
                                  </div>
                                  <div className="lecturer-content">
                                      <h6><a href="/" className="text-white">Joana Williams</a></h6>
                                      <p className="mb-0">psychologist</p>
                                  </div>
                                  <div className="lecturer-schedule">
                                      <ul className="pl-0 d-flex">
                                        <li>
                                          <h6>Day</h6>
                                          <p className="mb-0">Mon Day</p>
                                        </li>
                                        <li>
                                          <h6>Time</h6>
                                          <p className="mb-0">9 am - 11 am</p>
                                        </li>
                                      </ul>
                                  </div>
                                  <div className="lecturer-details">
                                    <a href="/" className="btn btn-primary">Details</a>
                                  </div>
                              </li>
                          </ul>
                      </div>
                    </div>
                    <div className="course-details-lecturer bg-dark border-radius p-3 p-lg-4 mt-4">
                        <ul className="pl-0 mb-0">
                            <li className="d-md-flex align-items-center justify-content-between">
                                <div className="lecturer-img mr-md-2">
                                  <img src="images/avatar/03.jpg" className="img-fluid" alt="" />
                                </div>
                                <div className="lecturer-content">
                                    <h6><a href="/" className="text-white">Paul Flavius</a></h6>
                                    <p className="mb-0">Economics Professor</p>
                                </div>
                                <div className="lecturer-schedule">
                                    <ul className="pl-0 d-flex">
                                      <li>
                                        <h6>Day</h6>
                                        <p className="mb-0">Fri Day</p>
                                      </li>
                                      <li>
                                        <h6>Time</h6>
                                        <p className="mb-0">10 am - 12 pm</p>
                                      </li>
                                    </ul>
                                </div>
                                <div className="lecturer-details">
                                  <a href="/" className="btn btn-primary">Details</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="course-details-lecturer bg-dark border-radius p-3 p-lg-4 mt-4">
                        <ul className="pl-0 mb-0">
                            <li className="d-md-flex align-items-center justify-content-between">
                                <div className="lecturer-img mr-md-2">
                                  <img src="images/avatar/04.jpg" className="img-fluid" alt="" />
                                </div>
                                <div className="lecturer-content">
                                    <h6><a href="/" className="text-white">Mellissa Doe</a></h6>
                                    <p className="mb-0">Senior UI Designer</p>
                                </div>
                                <div className="lecturer-schedule">
                                    <ul className="pl-0 d-flex">
                                      <li>
                                        <h6>Day</h6>
                                        <p className="mb-0">Fri Day</p>
                                      </li>
                                      <li>
                                        <h6>Time</h6>
                                        <p className="mb-0">11 am - 01 pm</p>
                                      </li>
                                    </ul>
                                </div>
                                <div className="lecturer-details">
                                  <a href="/" className="btn btn-primary">Details</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <h4 className="mt-5">Student Reviews</h4>
                    <div className="review mt-4">
                      <div className="row">
                          <div className="col-md-2 col-sm-3 mb-3 mb-sm-0">
                              <img className="img-fluid border-radius" src="images/avatar/02.jpg" alt="" />
                          </div>
                          <div className="col-md-10 col-sm-9">
                            <div className="review-body">
                                <h6 className="mb-3"><a href="/">Michael Bean</a></h6>
                                <span className="bg-success border-radius p-1 px-2 text-white">Excellent course!</span>
                                <p className="mt-3 mb-0">I STRONGLY recommend Potenza to EVERYONE interested in running a successful online business! Very easy to use.</p>
                                <div className="review-rating">
                                    <ul>
                                        <li><i className="fa fa-star text-success" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star text-success" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star text-success" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star text-success" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                    </ul>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="review mt-4">
                      <div className="row">
                        <div className="col-md-2 col-sm-3 mb-3 mb-sm-0">
                          <img className="img-fluid border-radius" src="images/avatar/03.jpg" alt="" />
                        </div>
                          <div className="col-md-10 col-sm-9">
                            <div className="review-body">
                                <h6 className="mb-3"><a href="/">Ricardo Marshall</a></h6>
                                <span className="bg-primary border-radius p-1 px-2 text-white">Excellent course!</span>
                                <p className="mt-3 mb-0">The very best. Thanks guys, keep up the good work! Nice work on your Potenza. I wish I would have thought of it first.</p>
                                <div className="review-rating">
                                    <ul>
                                        <li><i className="fa fa-star text-primary" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star text-primary" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star text-primary" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                        <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                    </ul>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="review mt-4">
                      <div className="row">
                        <div className="col-md-2 col-sm-3 mb-3 mb-sm-0">
                          <img className="img-fluid border-radius" src="images/avatar/06.jpg" alt="" />
                        </div>
                        <div className="col-md-10 col-sm-9">
                          <div className="review-body">
                              <h6 className="mb-3"><a href="/">Felica Queen</a></h6>
                              <span className="bg-warning border-radius p-1 px-2 text-white">Excellent course!</span>
                              <p className="mt-3 mb-0">I just can't get enough of web service. I want to get a T-Shirt with web service on it so I can show it off to everyone.</p>
                              <div className="review-rating">
                                  <ul>
                                      <li><i className="fa fa-star text-warning" aria-hidden="true"></i></li>
                                      <li><i className="fa fa-star text-warning" aria-hidden="true"></i></li>
                                      <li><i className="fa fa-star text-warning" aria-hidden="true"></i></li>
                                      <li><i className="fa fa-star text-warning" aria-hidden="true"></i></li>
                                      <li><i className="fa fa-star text-warning" aria-hidden="true"></i></li>
                                  </ul>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="review mt-4">
                      <div className="row">
                        <div className="col-md-2 col-sm-3 mb-3 mb-sm-0">
                          <img className="img-fluid border-radius" src="images/avatar/04.jpg" alt="" />
                        </div>
                        <div className="col-md-10 col-sm-9">
                          <div className="review-body">
                              <h6 className="mb-3"><a href="/">Sara Lisbon</a></h6>
                              <span className="bg-info border-radius p-1 px-2 text-white">Excellent course!</span>
                              <p className="mt-3 mb-0">I don't always clop, but when I do, it's because of Potenza. Web service saved my business. This is simply unbelievable!</p>
                              <div className="review-rating">
                                  <ul>
                                      <li><i className="fa fa-star text-info" aria-hidden="true"></i></li>
                                      <li><i className="fa fa-star text-info" aria-hidden="true"></i></li>
                                      <li><i className="fa fa-star text-info" aria-hidden="true"></i></li>
                                      <li><i className="fa fa-star text-info" aria-hidden="true"></i></li>
                                      <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                  </ul>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-radius mt-4">
                      <h4 className="mb-4">Leave a Comment</h4>
                      <form>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input type="text" className="form-control bg-white border" id="name" placeholder="Your Name" />
                          </div>
                          <div className="form-group col-md-6">
                            <input type="email" className="form-control bg-white border" id="inputEmail4" placeholder="Email Address" />
                          </div>
                          <div className="form-group col-md-12">
                            <textarea className="form-control bg-white border" rows="4" placeholder="Message"></textarea>
                          </div>
                          <div className="col-md-12">
                            <a className="btn btn-primary" href="/">Submit Now</a>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="course-sidebar shadow">


                <div className="widget">
                  <div className="widget-title">
                    <h5>Course Details</h5>
                  </div>
                  <div className="widget-course">
                    <ul className="list-unstyled list-style mb-0">
                      <li className="d-flex"><a href="/"> Students: 10</a><i className="fa fa-user pr-2"></i></li>
                      <li className="d-flex"><a href="/"> Students: 25</a><i className="fa fa-user pr-2"></i></li>
                      <li className="d-flex"><a href="/"> Students: 38</a><i className="fa fa-user pr-2"></i></li>
                      <li className="d-flex"><a href="/"> Students: 55</a><i className="fa fa-user pr-2"></i></li>
                      <li className="d-flex"><a href="/"> Students: 70</a><i className="fa fa-user pr-2"></i></li>
                    </ul>
                  </div>
                </div>



                <div className="widget">
                  <div className="widget-title">
                    <h5>Course Rating</h5>
                  </div>
                  <div className="review d-flex">
                  <div className="review-rating">
                    <ul className="pl-0">
                      <li><i className="fa fa-star text-danger" aria-hidden="true"></i></li>
                      <li><i className="fa fa-star text-danger" aria-hidden="true"></i></li>
                      <li><i className="fa fa-star text-danger" aria-hidden="true"></i></li>
                      <li><i className="fa fa-star text-danger" aria-hidden="true"></i></li>
                      <li><i className="fa fa-star" aria-hidden="true"></i></li>
                    </ul>
                   </div>
                   <h6 className="ml-auto">4.0</h6>
                  </div>
                  <span>5 Stars</span>
                  <div className="progress mt-2">
                    <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{width: '90%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="90"></div>
                  </div>
                  <span>4 Stars</span>
                  <div className="progress mt-2">
                    <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{width: '75%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="75"></div>
                  </div>
                  <span>3 Stars</span>
                  <div className="progress mt-2">
                    <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{width: '65%'}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="65"></div>
                  </div>
                  <span>2 Stars</span>
                  <div className="progress mt-2">
                    <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{width: '60%'}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="60"></div>
                  </div>
                  <span>1 Stars</span>
                  <div className="progress mt-2">
                    <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{width: '50%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="50"></div>
                  </div>
                </div>


                <div className="widget">
                  <div className="widget-title">
                    <h5>Other Events</h5>
                  </div>
                  <div>
                    <div className="events">
                      <div className="media mb-3 pb-3 border-bottom">
                        <img className="img-fluid avatar border-radius" src="images/events/02.jpg" alt="" />
                        <div className="media-body ml-3">
                          <a className="d-block date text-dark" href="/">September 10, 2020</a>
                          <a className="text-dark" href="event-detail.html"><b>Young leaders in tech policy fellowship</b></a>
                        </div>
                      </div>
                    </div>
                    <div className="events mt-3">
                      <div className="media mb-3 pb-3 border-bottom">
                        <img className="img-fluid avatar border-radius" src="images/events/03.jpg" alt="" />
                        <div className="media-body ml-3">
                          <a className="d-block date text-dark" href="/">September 28, 2020</a>
                          <a className="text-dark" href="event-detail.html"><b>Expert panel- careers in tech policy</b></a>
                        </div>
                      </div>
                    </div>
                    <div className="events mt-3">
                      <div className="media mb-3 pb-3">
                        <img className="img-fluid avatar border-radius" src="images/events/04.jpg" alt="" />
                        <div className="media-body ml-3">
                          <a className="d-block date text-dark" href="/">November 02, 2020</a>
                          <a className="text-dark" href="event-detail.html"><b>Health and life expectancy</b></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="widget">
                  <div className="widget-title">
                    <h5>Categories</h5>
                  </div>
                  <div className="widget-categories">
                    <ul className="list-unstyled list-style mb-0">
                      <li><a href="/" className="d-flex"><span>Economics &amp; Finance</span><i className="fas fa-chevron-right text-right ml-auto"></i></a></li>
                      <li><a href="/" className="d-flex"><span>Engineering &amp; Technology</span><i className="fas fa-chevron-right text-right ml-auto"></i></a></li>
                      <li><a href="/" className="d-flex"><span>Natural Sciences</span><i className="fas fa-chevron-right text-right ml-auto"></i></a></li>
                      <li><a href="/" className="d-flex"><span>Masters in Accounting</span><i className="fas fa-chevron-right text-right ml-auto"></i></a></li>
                      <li><a href="/" className="d-flex"><span>Electrical Engineering</span><i className="fas fa-chevron-right text-right ml-auto"></i></a></li>
                    </ul>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CourseDetail;

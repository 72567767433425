import img from '../images/about/about-01.jpg';
import React from "react";
import thc3 from "../images/gallery/bot.JPG";

function TrusteeMsg() {
    return (
        <>
            <div>


                <h4 className='text-center mb-4'>BOARD OF TRUSTEES MESSAGE</h4>
                <div className='center-icon-img'>
                    <img
                        src={thc3}
                        alt=""
                        className="img-fluid rounded-circle mr-3 icon-img"
                        style={{
                            width: '250px',
                            height: '250px',
                            border: '5px solid gray',
                            paddingTop: '500x'
                        }}
                    />
                </div>
                <p className=" text-justify">Dear Esteemed Sakya Buddha University Community,</p>
                <p className=" text-justify">As the Governing Board of Sakya Buddha University, we stand united in our commitment to uphold the highest standards of academic excellence, spiritual growth, and global citizenship.</p>
                <p className=" text-justify">Our collective mission reverberates with the teachings of compassion, wisdom, and enlightenment. We pledge unwavering dedication to fostering an environment where knowledge transcends boundaries and where diversity is celebrated as a catalyst for understanding.</p>
                <p className='text-justify'>Guided by the essence of Buddha's teachings, we envision a university that not only educates but also nurtures the spirit, encouraging each individual to explore their potential and contribute meaningfully to society.</p>
                <p className='text-justify'>We affirm our dedication to:</p>
                <p className=" text-justify">Providing a transformative education that integrates wisdom, spirituality, and modern knowledge.</p>
                <p className=" text-justify">Cultivating an inclusive community that embraces diversity as a source of richness and understanding.</p>
                <p className=" text-justify">Fostering a global perspective through collaborations and exchanges that bridge cultures and traditions.</p>
                <p className=" text-justify">Instilling values of compassion, service, and ethical leadership in every facet of our educational journey where learning extends beyond the classroom, and the pursuit of knowledge is intertwined with the quest for inner wisdom.</p>
                <p className=" text-justify">With profound gratitude for your unwavering support and commitment to our shared vision,</p>
                <p className=" text-justify">The Governing Board of Sakya Buddha University</p>

            </div>
        </>
    )
        ;
}

export default TrusteeMsg;

import {useParams} from 'react-router-dom';
import ReligiousStudies from "./ReligiousStudies";
import BuddhistChaplaincy from "./BuddhistChaplaincy";
import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/bg/acadamic_program.png";
import Divider from "../components/divider";
import {useContext} from "react";
import {LinksContext} from "./LinksContext";
import {useNavigate} from 'react-router-dom';
import BuddhistPhilosophy from "./BuddhistPhilosophy";
import Divinity from "./Divinity";

function Component() {
    return null;
}

function AcademicsProgram() {
    const params = useParams();
    const programId = params.programId;
    const {links, activeLink, setActiveLink} = useContext(LinksContext);
    const navigate = useNavigate();
    const handleOnClick = (link) => {
        setActiveLink(link);
        navigate('/academics');
    }

    return (
        <>
            <InnerHeader customName="SBU Graduate Academics" backgroundImage={imgBg}/>
            <Divider/>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mb-5 mb-lg-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="shadow border-radius p-4">
                                    <Component/>
                                    {programId === '1' && <ReligiousStudies/>}
                                    {programId === '2' && <BuddhistChaplaincy/>}
                                    {programId === '3' && <BuddhistPhilosophy/>}
                                    {programId === '4' && <BuddhistChaplaincy/>}
                                    {programId === '5' && <ReligiousStudies/>}
                                    {programId === '6' && <BuddhistChaplaincy/>}
                                    {programId === '7' && <ReligiousStudies/>}
                                    {programId === '8' && <Divinity/>}
                                    {programId === '9' && <ReligiousStudies/>}
                                    {programId === '10' && <ReligiousStudies/>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="course-sidebar shadow">
                            <div className="widget">
                                <div className="widget-title">
                                    <h5>Academics</h5>
                                </div>
                                <div className="widget-categories">
                                    {links.slice(1).map(link => (
                                        <button
                                            key={link.name}
                                            className="btn btn-link btn-block pl-0 text-left d-flex justify-content-between align-items-center"
                                            type="button"
                                            onClick={() => handleOnClick(link)}
                                        >
                                            <span>{link.name}</span>
                                            <i className="fas fa-chevron-right text-right ml-auto"></i>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AcademicsProgram;

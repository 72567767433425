import { useLocation } from 'react-router-dom';

function InnerHeader({ customName, backgroundImage = null }) {
  let location = useLocation();
  let pageName = location.pathname.replaceAll('/', ' ').replace(/\d+/g, '').split('-').join(' ');
  pageName = customName || `${pageName[0].toUpperCase()}${pageName.slice(1)}`;

  return (
    <section
      className={`inner-banner ${backgroundImage ? '' : 'bg-overlay-black-70'} bg-holder`}
      style={{backgroundImage: `url(${backgroundImage})`, height: '500px'}}
    >
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <div className="text-center">
              <h1 className="text-white mt-6">{pageName}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerHeader;

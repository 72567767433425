
function CourseCatalog() {
    return (
      <>
          <div className="my-sm-3 text-justify">
              <h4 className="mb-4">2023 - 2025 Course Catalog</h4>
              <h6>Required Courses (Nine core courses)</h6>
              <ul>
                  <li><strong>SBUIB 515 - Introduction to Buddhist Philosophy (3 units):</strong> Studies the
                      foundational principles, ideologies, and doctrines of Buddhism. This course explores the
                      multifaceted aspects of Buddhist thought, including its core philosophies, historical development,
                      diverse schools of belief, and the intricate interplay between theory and practice within
                      Buddhism. Prerequisite: N/A</li>
                  <li><strong>SBUBM 525 - Buddhist Meditation (3 units):</strong> A comprehensive exploration of the
                      theory, techniques, and transformative aspects of meditation within the Buddhist tradition. This
                      course delves into the diverse array of meditation practices advocated in various Buddhist
                      schools, ranging from mindfulness (vipassana) to loving-kindness (metta), concentration (samatha),
                      and insight meditation. Prerequisite: N/A</li>
                  <li><strong>SBUCP 530 - Comparative Religions and Philosophy (3 units):</strong> A profound
                      exploration aimed at deepening comprehension of Buddhist traditions and other religions, and
                      philosophies globally. Gain insights into the diverse perspectives that shape the understanding of
                      spirituality and human existence. Navigate through the intricacies of belief systems, fostering a
                      nuanced appreciation for the interconnectedness of religious and philosophical thought across
                      cultures and epochs. Prerequisite: SBUIB 515 - Introduction to Buddhist Philosophy</li>
                  <li><strong>SBUBW 545 - Buddhist Scripture Translations in the West (3 units):</strong> Studies the
                      history, adaptation, and contemporary manifestations of Buddhism as it has spread and evolved in
                      Western contexts. It examines the transmission of Buddhist teachings to the West, exploring the
                      challenges, cultural adaptations, and the diverse forms that Buddhism has taken in Western
                      societies. Prerequisite: N/A</li>
                  <li><strong>SBUSB 560 - Sacred Texts in Buddhism:</strong> Analyze the foundational scriptures and
                      revered texts within Buddhist traditions. Explore primary Buddhist scriptures such as the Pali
                      Canon (Tripitaka), Mahayana Sutras, and Tibetan Buddhist texts, exploring their historical,
                      cultural, and philosophical significance. Prerequisite: N/A</li>
                  <li><strong>SBUPB 570 - Philosophical Discussions of Buddhism:</strong> The Philosophical Discussions
                      of Buddhism course is a comprehensive exploration of the profound ideas, principles, and
                      philosophical frameworks that underpin Buddhism. Students delve into the core tenets of Buddhist
                      thought, examining key concepts such as the Four Noble Truths, karma, impermanence, and the nature
                      of reality. Prerequisite: SBUCP 530 - Comparative Religions and Philosophy</li>
                  <li><strong>SBUWB 575 - Women in Buddhism:</strong> Discover the roles, contributions, and challenges
                      faced by women within the context of Buddhist traditions. Delving into historical perspectives and
                      contemporary issues, the course examines the experiences of women in various Buddhist cultures.
                      Topics include the portrayal of women in Buddhist scriptures, the evolution of gender roles, and
                      the voices of women in shaping Buddhist practices. Prerequisite: N/A</li>
                  <li><strong>SBUBP 580 - Buddhist Psychology:</strong> Offers a unique exploration into the
                      intersection of Buddhist teachings and psychological principles. It delves into the fundamental
                      aspects of human experience, emotions, and mental states as elucidated in Buddhist psychology.
                      Students examine the intricate workings of the mind, exploring concepts such as mindfulness,
                      compassion, and the nature of consciousness as outlined in Buddhist traditions. Prerequisite:
                          N/A</li>
                  <li><strong>SBUAS 595 - Applied Buddhism in Society:</strong> This course bridges Buddhist principles
                      with their practical applications in real-world contexts. It explores how Buddhist teachings,
                      ethics, and practices can be integrated and utilized to address contemporary societal challenges
                      and foster positive change. Prerequisite: N/A</li>
              </ul>
              <h6>Elective Courses (Three elective courses)</h6>
              <ul>
                  <li><strong>SBUNC 500 - Nonverbal Communication Across Cultures:</strong> We communicate with each
                      other verbally and nonverbally. The ability to encode and decode nonverbal messages is central to
                      effective communication within and across cultures. This course provides broad and specific
                      knowledge and understanding of nonverbal communication in everyday life and social interactions.
                      It aims to increase your knowledge, awareness, and understanding of nonverbal communication and
                      its functions so you can mindfully appreciate and improve your nonverbal communication skills when
                      communicating across and between cultures. Prerequisite: N/A</li>

                  <li><strong>SBUIC 520 - Intercultural Communication:</strong> The Intercultural Communication course
                      explores the complexities of communication across diverse cultural contexts. Students examine how
                      cultural differences influence interactions, perceptions, and the exchange of ideas in various
                      global settings. This course navigates through topics like cultural norms, values, language
                      nuances, and non-verbal communication, fostering an understanding of how these factors impact
                      intercultural relationships. Prerequisite: N/A</li>

                  <li><strong>SBUCW 590 - Chinese / Vietnamese Buddhist Writing:</strong> The Chinese / Vietnamese
                      Buddhist Writing course familiarizes students with the basics of Mandarin Chinese, with a specific
                      emphasis on comprehending the Chinese/Vietnamese text Tipitaka. Prerequisite: N/A</li>
              </ul>
              <h6 className="text-left">Research Methodology Courses (Four research methodology courses)</h6>
              <ul>
                  <li><strong>SBUAW 510 - Academic Writing:</strong> This course equips students with essential skills
                      for effective scholarly communication. It covers various facets of academic writing, including
                      structuring essays, conducting research, citing sources accurately, and refining critical thinking
                      abilities. Throughout the course, students learn to craft clear, concise, and well-organized
                      academic papers across disciplines. Prerequisite: N/A</li>

                  <li><strong>SBURW 540 - Reading the Buddha’s Words:</strong> Delves into the profound teachings of the
                      Buddha. Designed to provide participants with a deep understanding of Buddhist scriptures, the
                      course explores key texts, sutras, and discourses attributed to the Buddha. Prerequisite:
                          N/A</li>

                  <li><strong>SBURM 592 - Research Methodology:</strong> The Research Methodology course builds upon
                      foundational writing skills, focusing on advanced techniques for crafting essays and developing
                      thesis-level work. It offers a comprehensive guide to writing essays, equipping students with
                      tools to construct well-structured, compelling, and academically rigorous papers. Prerequisite:
                          SBUAW 510 - Academic Writing</li>

                  <li><strong>SBUCP 599 - Capstone Thesis Project:</strong> The course delves into the intricacies of
                      essay composition, emphasizing thesis development, evidence gathering, critical analysis, and
                      argumentation. Students learn advanced research methodologies, refining their abilities to locate
                      credible sources, synthesize information, and incorporate scholarly literature effectively. Prerequisite:
                          SBURM 592 - Research Methodology</li>
              </ul>
          </div>
      </>
    );
}

export default CourseCatalog;
import { useEffect } from 'react';
import { swiper } from './animation';
import bg1 from '../images/bg/01.jpg';
import bg3 from '../images/bg/03.jpg';
import silder2 from '../images/home/slider_2.webp';
import silder1 from '../images/home/slider_1.webp';
import silder3 from '../images/home/slider_3.webp';

function Banner() {
  useEffect(() => {
    swiper();
  }, []);

	return (
    <section className="banner position-ralative">
      <div id="main-slider" className="swiper-container h-500 h-lg-700 h-md-600 h-sm-400">
        <div className="swiper-wrapper">
          <div
          	className="swiper-slide align-items-center d-flex bg-overlay-black-40"
          	style={{
          		backgroundImage: `url(${silder2})`,
          		backgroundSize: 'cover',
              backgroundPosition: 'center 20%',
          	}}
          >
            <div className="swipeinner container" style={{ paddingRight: '' }}>
              <div className="row justify-content-start">
                <div className="col-xl-8 col-lg-10 col-md-12">
                  <div className="animated" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="0.25s">
                    <h2 className="animated text-white mb-4" style={{ marginTop: '300px'}}  data-swiper-animation="fadeInUp" data-duration="1s" data-delay="0.25s">
                      DISCOVER AND EMBRACE YOUR GOAL
                    </h2>
                    <div className="animated text-dark" data-swiper-animation="fadeInUp" data-duration="2.5s" data-delay="0.25s">
                      <p className="text-white d-none d-sm-block" style={{ fontSize: '1.2rem' }}>
                        Become part of an educational community centered on awareness, where Buddhist-based pedagogy guides both learning and life experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
          	className="swiper-slide align-items-center d-flex bg-overlay-black-40"
          	style={{
          		backgroundImage: `url(${silder1})`,
          		backgroundSize: 'cover',
              backgroundPosition: 'center 10%',
          	}}
          >
            <div className="swipeinner container" style={{ paddingRight: '' }}>
              <div className="row justify-content-start">
                <div className="col-xl-8 col-lg-10 col-md-12">
                  <div className="animated" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="0.25s">
                    <h2 className="animated text-white" style={{ marginTop: '300px'}} data-swiper-animation="fadeInUp" data-duration="1s" data-delay="0.25s">
                      DISCOVER AND EMBRACE YOUR GOAL
                    </h2>
                    <div className="animated text-dark" data-swiper-animation="fadeInUp" data-duration="2.5s" data-delay="0.25s">
                      <p className="text-white d-none d-sm-block" style={{ fontSize: '1.2rem' }}>
                        Become part of an educational community centered on awareness, where Buddhist-based pedagogy guides both learning and life experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-button-prev text-white"><i className="fa fa-arrow-left"></i></div>
      <div className="swiper-button-next text-white"><i className="fa fa-arrow-right"></i></div>
    </section>
	);
}

export default Banner;

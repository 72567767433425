import {useEffect, useState} from 'react';
import InnerHeader from '../components/InnerHeader';
import imgBg from '../images/gallery/santa-ana.png'
import {useHashChange} from "../hooks/useHashChange";


const links = [{name: 'Donor Bill of Rights', component: ''},
    {name: 'Admissions Events', component: ''},
    {name: 'Campuses', component: 'Campuses'},
    {name: 'Request Information', component: ''},
];

function PlanaVisit() {
    const [activeLink, setActiveLink] = useHashChange(links);
    const Component = activeLink.component;

    return (
        <>

            <InnerHeader backgroundImage={imgBg}/>
            <section className="space-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-5 mb-lg-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="shadow border-radius p-4 text-justify">
                                        <div className="section-title my-sm-3">
                                            <h4 className="mb-4">Plan a Visit</h4>
                                            <h6 className="mb-4">Visit Sakya Buddha University in Santa Ana, CA</h6>
                                            <p>Sakya Buddha University holds a prominent position in Santa Ana, CA. As a
                                                liberal arts institution grounded in Buddhist principles, SBU serves
                                                undergraduate and graduate students. It merges Western academic
                                                expertise with the rich wisdom found in Eastern traditions, cultivating
                                                a holistic learning environment. Our core mission is to provide
                                                accessible, top-tier merchandise that enables individuals to proudly
                                                display their affiliation with SBU and promote its guiding
                                                principles.</p>

                                            <h6>Visit SBU in Person</h6>
                                            <p>Arrange a personalized Information Session with an Admission Officer.
                                                Plan a campus tour to explore our meditation spaces, edible landscaping,
                                                art galleries, and classrooms. Gain firsthand experience of the SBU
                                                classroom by observing a class session.</p>

                                            <h6>Visit SBU Virtually</h6>
                                            <p>If an in-person visit isn't feasible, you can engage virtually.
                                                Explore a self-guided journey through our virtual tour. Coordinate a
                                                personalized one-on-one virtual Information Session with an Admission
                                                Officer. Plan a virtual meeting with Financial Services. Engage in a
                                                virtual "Experience SBU" open house, offering a sample class. Contact:
                                                sakyabuddha@vienphathoc.org</p>

                                            <h6>Living in in Santa Ana, California</h6>
                                            <p>Santa Ana is an excellent destination for both visiting and residing. If
                                                you haven't experienced Santa Ana, CA, the beaches that define our
                                                scenery will deeply touch your heart in ways that words alone can't
                                                convey. Wander through the vibrant streets like Westminster Street.
                                                Delight in Vietnamese, Korean, or Taiwanese coffee or tea. Indulge in
                                                the diverse vegetarian/vegan dining options Santa Ana offers. Explore
                                                the local offerings, such as produce from Desneyland. And immerse
                                                yourself in the splendid Long Beach, where nature imparts lessons on
                                                harmony and tranquility that words cannot fully capture.</p>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="col-lg-4">*/}
                        {/*    <div className="course-sidebar shadow">*/}
                        {/*        <div className="widget">*/}
                        {/*            <div className="widget-title">*/}
                        {/*                <h5>Invest in SBU</h5>*/}
                        {/*            </div>*/}
                        {/*            <div className="widget-categories">*/}
                        {/*                {links.slice(1).map(link => (*/}
                        {/*                    <button*/}
                        {/*                        key={link.name}*/}
                        {/*                        className="btn btn-link btn-block pl-0 text-left d-flex justify-content-between align-items-center"*/}
                        {/*                        type="button"*/}
                        {/*                        onClick={() => setActiveLink(link)}*/}
                        {/*                    >*/}
                        {/*                        <span>{link.name}</span>*/}
                        {/*                        <i className="fas fa-chevron-right text-right ml-auto"></i>*/}
                        {/*                    </button>*/}
                        {/*                ))}*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        </>
    )
        ;
}

export default PlanaVisit;

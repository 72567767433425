import img from '../images/about/about-01.jpg';
import thc1 from '../images/gallery/thc_6598.JPG';
import React from "react";

function TrusteeMsg() {
    return (
        <>
            <div className='text-justify'>

                <h4 className='mb-3 text-center mb-4'>GOVERNING BOARD PROFILES</h4>
                <div className='center-icon-img'>
                    <img
                        src={thc1}
                        alt=""
                        className="img-fluid rounded-circle mr-3 icon-img"
                        style={{width: '250px', height: '250px', border: '5px solid gray'}}
                    />
                </div>
                <div>
                    <h5>VENERABLE MASTER THICH HUYEN CHAU</h5>
                    <p>Venerable Master Thich Huyen Chau was born in Tay Son, Binh Dinh, Vietnam, in 1976. At the age of 14, he joined the Phuoc Long Buddhist monastery in Tay Son, Binh Dinh, under the tutelage of scholarly Buddhist Masters. He completed his ordination in 2000 and was officially declared as the 43rd patriarch of the Lin-Chi Ch’an School of Mahāyana Buddhism.
                        In 2014, Venerable Master Thich Huyen Chau founded the Bodhi Buddharealm Society Temple in Santa Ana, California, which has since evolved into the Sakya Buddhist University, where he fulfilled his longstanding commitment to advance Buddhist-based education. For nearly three decades, the Venerable Master has devoted his endeavors to reshaping our world by practicing Buddha’s teachings, focusing on the holistic development of individuals.
                    </p>
                    <p>His perceptive and engaging lectures have reached diverse audiences, underscoring the importance of achieving comprehensive fulfillment for bringing about global change. In his travels, he advocates for the unification of both local and global communities, aiming for a world characterized by complete equality, joy, and perfect peace.</p>
                    <p>Venerable Master Thich Huyen Chau oversees nearly 80 disciples and is an extensive writer, having authored over 20 books in Vietnamese, which are translated into English and other languages.</p>
                </div>

                <div>
                    <h5>M.D LAN THI HOANG NGUYEN</h5>
                    <p>Dr. Lan Nguyen's significant contributions to medical education as a former Clinical Associate Professor of Family Medicine at the University of Southern California underscore her commitment to academic excellence. Alongside her medical licenses and hospital staff privileges, Dr. Nguyen's remarkable achievements include numerous publications, honors, and awards, as well as active participation in national scientific forums.</p>
                    <p>Her unique expertise as a medical professional, proficient in both English and Vietnamese, enriches the university's cultural diversity and brings a multifaceted perspective to the governing board of Sakya Buddha University. Dr. Nguyen's distinguished career, educational expertise, leadership experience, and dedication to core values position her as an invaluable asset for the university.</p>
                    <p>In the field of Buddhist studies, her interdisciplinary perspective not only bridges medical knowledge with spiritual aspects but also enhances the academic discourse. Dr. Nguyen's wealth of experience, coupled with her commitment to excellence and cultural inclusivity, significantly contributes to Sakya Buddha University's success in fostering a holistic approach to education and well-being.</p>
                </div>

                <div>
                    <h5>PROFESSOR LE MANH THAT</h5>
                    <p>Professor Dr. Le Manh That possesses a distinguished academic career, marked by global engagement in Buddhist affairs and an unwavering commitment to advancing Buddhist education. His extensive experience uniquely positions him to contribute significantly to the strategic vision and governance of Sakya Buddha University. Dr. Le Manh That's wealth of knowledge and steadfast dedication to the institution's values make him an invaluable asset to the SBU governing board.</p>
                    <p>As Vice President of the Vietnam Buddhist University and a member of various international Buddhist committees, including the Executive Council of the Asian Buddhist Conference for Peace and the International Organizing Committee for United Nations Day of Vesak, Dr. Le Manh That brings a global perspective to Buddhist education and values.</p>
                    <p>His extensive academic journey, highlighted by a Ph.D. from the University of Wisconsin and a B.A. from the University of Saigon, underscores a profound understanding of Mahāyāna, Theravāda, Zen, Pure Land Buddhism, and other facets of Asian religions. As a Professor with a focus on Buddhist history, philology, and psychology, Dr. Le Manh That has made significant contributions to the field, aligning with Sakya Buddha University's commitment to academic excellence.</p>
                    <p>Notable for chairing conferences and leading international committees, Dr. Le Manh That has demonstrated his leadership capabilities, ensuring the successful organization of events that foster dialogue on Buddhist principles, ethics, and societal contributions. His dedication to the principles of Engaged Buddhism, as evident in his published works, aligns seamlessly with Sakya Buddha University's mission.</p>
                    <p>With a vast body of publications, both in English and Vietnamese, covering a wide range of Buddhist subjects, Dr. Le Manh That exemplifies a commitment to the dissemination of knowledge. His expertise in Buddhist philosophy, languages, and culture adds a valuable dimension to the diverse skill set required for the governance of Sakya Buddha University.</p>
                </div>

                <div>
                    <h5>BICHVAN VUONG</h5>
                    <p>BichVan Vuong, a seasoned Accounting Analyst with over eight years of dedicated experience at Ally Financial, brings a wealth of expertise in financial analysis, meticulous risk assessment, and a commitment to excellence. Holding a bachelor's degree in accounting from California State University Long Beach, BichVan has proven her ability to navigate complex financial landscapes</p>
                    <p>Her roles at Robertshaw Controls Co., Leica Geosystems, and Mitsubishi Motors showcase her proficiency in month-end closing, financial consolidation, account reconciliation, and strategic decision-making. BichVan's contributions have consistently enhanced the accuracy of financial reporting, reflecting her commitment to maintaining the highest standards.</p>
                    <p>BichVan's commitment to fostering strong client relationships aligns seamlessly with the values of Sakya Buddha University. Her track record of contributing to the creation and improvement of business processes demonstrates her proactive approach to continuous improvement.</p>
                    <p>Given her extensive experience, financial acumen, and commitment to excellence, BichVan Vuong is well-qualified to serve on the governing board of Sakya Buddha University, where her strategic insights and dedication to continual improvement contribute significantly to the institution's growth and success.</p>
                </div>

                <div>
                    <h5>THUYHONG BUI</h5>
                    <p>As an experienced pharmacist and accomplished business owner, Thuyhong has consistently
                        exhibited strategic acumen, operational excellence, and an unwavering dedication to community
                        welfare. Her entrepreneurial journey, evident during her ownership and operation of Del Mar
                        Pharmacy from 2004 to 2021, serves as a testament to her ability to navigate complex operations,
                        ensure stringent regulatory compliance, and cultivate meaningful customer relationships. Her
                        business expertise extends into strategic planning, operational management, and financial
                        acuity.</p>
                    <p>Thuyhong&#39;s passion for education and community service seamlessly aligns with Sakya Buddha
                        University&#39;s core values. Her diverse skill set, demonstrated leadership, and commitment to the
                        holistic well-being of individuals make her an ideal candidate to contribute to the strategic vision
                        and governance of Sakya Buddha University. Thuyhong Bui&#39;s wealth of experience, coupled with
                        her unwavering dedication to education and community enrichment, positions her as a valuable
                        asset to the university.
                    </p>
                </div>

                <div>
                    <h5>MINHHOANG CHAU</h5>
                    <p>As an accomplished French language instructor and a master’s candidate in French Language
                        and Literature at Boston College, Minh consistently showcases a deep commitment to
                        curriculum development and the implementation of innovative teaching methodologies.</p>
                    <p>Minh&#39;s influential role in establishing the Faculty of Pedagogy in French at Can Tho University,
                        Vietnam, underscores her visionary leadership and unwavering dedication to advancing language
                        education. Her active involvement in shaping curricula, focus on a student-centered approach,
                        and advocacy for the significance of French language education reflect a profound commitment
                        to academic excellence.</p>
                    <p>Boasting a diverse background in nurturing cross-cultural communication and fostering student
                        engagement, Minh Hoang Chau&#39;s dedication extends beyond the classroom through active
                        participation in extracurricular activities and mentorship initiatives. Fluent in French, Spanish,
                        English, and Vietnamese, Minh offers a multicultural perspective and robust interpersonal skills
                        to the governing board.</p>
                    <p>Minh&#39;s proficiency in language teaching approaches, coupled with her expertise in program
                        coordination, seamlessly aligns with Sakya Buddha University&#39;s objectives. Additionally, her
                        familiarity with language acquisition technologies underscores her commitment to staying well-
                        informed of the latest trends in education, making her a valuable addition to the Sakya Buddha
                        University governing board.
                    </p>
                </div>

            </div>
        </>
    )
        ;
}

export default TrusteeMsg;

const $ = window.jQuery;

const $window = $(window);

export default function goToTop() {
  var $goToTop = $('#back-to-top');
  $goToTop.hide();
  $window.scroll(function () {
    if ($window.scrollTop() > 100) $goToTop.fadeIn();
    else $goToTop.fadeOut();
  });
  $goToTop.on("click", function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });
}

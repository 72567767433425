import React, {useEffect} from 'react';
import {mediaPopup} from '../components/animation';
import hat from '../images/home/hat.png';
import book from '../images/home/book.jpg';
import cert from '../images/home/cert.png';
import Gradudate from "../academics/Graduate";
import Undergraduate from "../academics/Undergraduate";
import Certificate from "../academics/Certificate";

// Additional images

// Function to initialize media popup (assuming it's defined in 'animation.js')
const initMediaPopup = () => {
  mediaPopup();
};

function About() {
  // Run the initMediaPopup function on component mount
  useEffect(() => {
    initMediaPopup();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 mb-4 mb-lg-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="shadow border-radius p-4">
                <div className="section-title my-sm-3">
                  <h2 className="mb-4">About SBU</h2>
                  <p className="text-justify">
                    Sakya Buddha University (SBU), a nonprofit private institution located in Santa Ana, California, boasts a
                    charming campus, modern classrooms, dedicated faculty, and a comprehensive library—forming an
                    educational haven in vibrant Orange County.<br/><br/>
                    At SBU, we offer top-notch instruction rooted in Buddhist-based teaching methods, sparking a subtle
                    revolution in the educational environment. We draw on the Buddhist values of self-reflection and
                    mindfulness exercises to help students succeed in their studies, achieve goals, uncover strengths, and
                    evolve into mindful leaders. Through intimate class settings and dedicated mentors, SBU provides a
                    personalized college experience focused on each student&#39;s success. As a pioneering accredited university,
                    SBU blends Buddhist-based education with Western academia and state-of-the-art technology,
                    transforming and inspiring our students.<br/><br/>
                  </p>
                </div>
                <a href="/about-SBU" className="btn btn-primary btn-round">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row align-items-center" style={{paddingTop: '200px'}}>
              <ImageWithHeading image={hat} height={60} heading="Graduate Academics" linkUrl="/academics#Gradudate"/>
              {/*<ImageWithHeading image={book} height={60} heading="Undergraduate Academics" linkUrl="/academics#Undergraduate" />*/}
              {/*<ImageWithHeading image={cert} height={60} heading="Certificate Academics" linkUrl="/academics#Certificate"  />*/}
            </div>
          </div>
        </div>
      </div>
      );
      }

      // Component for images with headings
      const ImageWithHeading = ({ image, height, heading, linkUrl }) => (
  <div className="col-sm-12 text-center mb-4">
    <div className="shadow border-radius p-4">
      <a href={linkUrl} rel="noopener noreferrer">
        <img src={image} className="img-fluid smaller-image mb-4 mt-4" style={{ height }} alt="" />
      </a>
      <h5>{heading}</h5>
    </div>
  </div>
);

export default About;

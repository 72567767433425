import React, {useEffect} from 'react';
import {postData} from '../data/postsData';
import {useParams} from "react-router-dom";
import Post from "../components/Post";
import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/gallery/post-detail-img.png";
import Divider from "../components/divider";

function PostDetail() {
    // Assuming you want to display the post with id 1
    const {id} = useParams();
    const postId = parseInt(id, 10); // Convert id to a number
    const post = postData.find(p => p.id === postId);
    useEffect(() => {
        window.scrollTo(0, 500);
    },); // The empty dependency array ensures this runs only once when the component mounts


    return (
        <>
            <InnerHeader backgroundImage={imgBg}/>
            <Divider />
            <div className='container'>
                <div className="row">
                    <div className="col-md-8 col-sm-12">
                        <div className="course-sidebar shadow">
                            {post ? (
                                <>
                                    <h2>{post.header}</h2>
                                    <img className="img-fluid mb-2 mr-2" src={post.image}/>
                                    <div className='text-justify' dangerouslySetInnerHTML={{__html: post.fullContent}}/>
                                </>
                            ) : (
                                <p>Post not found</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4">
                        {postData.map(post => (
                            <Post key={post.id} {...post} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default PostDetail;

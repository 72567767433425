import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/bg/student-life.png";
import Divider from "../components/divider";
import {useState} from "react";
import axios from "axios";

function NewsLetter() {
    const [emailMsg, setEmailMsg] = useState("");

    function handleSubscribeNewsLetter(event) {
        event.preventDefault();
        const fd = new FormData(event.target);

        const formDataObj = Object.fromEntries(fd.entries());
        const data = {
            newsletter_subscription: {
                ...formDataObj,
                is_active: true  // Set 'is_active' to true

            }
        };

        console.log("Newsletter subscription request is clicked");
        console.log(data);

        axios.post(
            `${process.env.REACT_APP_BACKEND_API}/newsletter_subscription`, data
        )
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    console.log(response.data);
                    setEmailMsg("Newsletter subscription has been successfully sent");
                } else {
                    console.log(response.data.errors);
                }
            })
            .catch(error => {
                console.log('API errors:', error);
                if (error.response) {
                    console.log(error.response.data.message);
                }
                setEmailMsg("Newsletter subscription has not been successful");
            });
    }

    return (
        <>
            <InnerHeader backgroundImage={imgBg}/>
            <Divider/>

            <div className="container text-justify">
                <div className='row'>
                    <div className="col-lg-12 ml-0 shadow border-radius pt-5 pb-4" >
                        <h4>Sign up for our Newsletter!</h4>
                        <p>Get important information from Sakya Buddha University in your inbox.</p>
                        <form onSubmit={handleSubscribeNewsLetter}>
                            <div className="form-group w-50">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    required
                                />
                                <span className="focus-border"></span>
                            </div>


                            <button className="btn btn-primary">Submit</button>
                            <div className="mt-3">{emailMsg}</div>
                        </form>
                        <p>By filling out this form, you are indicating your agreement to receive marketing emails from
                            Sakya Buddha University located at 3404-3412 Westminster Ave, Santa Ana, CA 92703. These
                            emails encompass information about the various activities, such as learning, teaching, and
                            practicing, taking place at our university.</p>
                    </div>
                </div>
            </div>
        </>
    )
        ;
}

export default NewsLetter;

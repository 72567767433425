import imgDorjee from '../images/team/tenzin_dorjee.jpg'
import imgGiaimo from '../images/team/ben_giaimo.jpg';
import imgKelley from '../images/team/Catherine.jpg';
import imgGabriel from '../images/team/victor_gabriel.jpg';
import imgJohn from '../images/team/john_freese.jpeg';

export const lecturerIds = [
  "tenzindorjee",
  "benjamingiaimo",
  "catherinekelly",
  "victorgabriel",
  "johnfreese",
];
export const lecturers = {
  tenzindorjee: {
    name: "Tenzin Dorjee",
    image: imgDorjee,
    possition: "Professor",
    phone: "",
    email: "tenzin.dorjee@vienphathoc.org",
    address: "3404 Westminster Avenue, Santa Ana, CA 92703",
    intro: [
      "Dr. Dorjee's background in Tibetan culture, identity, and diasporic communication aligns well with Sakya Buddha University's focus on Buddhist philosophy. As a prominent translator and recognized figure in religious freedom advocacy, his experiences contribute valuable perspectives to the academic environment.",
      "His leadership role as former commissioner of the U.S. Commission on International Religious Freedom further underscores his commitment to promoting religious freedom, making him a significant asset to Sakya Buddha University."
    ],
    qualifications: [
      "Ph.D. in Communication, University of California, Santa Barbara.",
      "M.A. in Communication Studies, California State University, Long Beach.",
      "The U.S. Commission on International Religious Freedom, former commissioner.",
    ],
    publications: [
    ],
  },
  benjamingiaimo: {
    name: "Benjamin Giaimo",
    image: imgGiaimo,
    possition: "Professor",
    phone: "",
    email: "benjamin.giaimo@vienphathoc.org",
    address: "3404 Westminster Avenue, Santa Ana, CA 92703",
    intro: [
      "Professor Ben Giaimo is a highly accomplished educator with expertise in Ancient Chinese Philosophy and Literature. Fluent in various languages, including English, Mandarin, Thai, and American Sign Language, Professor Giaimo exhibits a rare linguistic versatility that aligns well with the diverse academic environment at Sakya Buddha University.",
      "His extensive contributions to education as a Guest Lecturer, Translator, and Professor of Translation showcase not only his teaching prowess but also his ability to bridge cultural and linguistic gaps, enriching the educational experience for students."
    ],
    qualifications: [
      "Ph.D. in Ancient Chinese Philosophy, Ren Min University.",
      "Master's in Ancient Chinese Literature and Characters, Shaanxi Normal University.",
    ],
    publications: [
    ],
  },
  catherinekelly: {
    name: "Catherine Kelley",
    image: imgKelley,
    possition: "Professor",
    phone: "",
    email: "catherine.kelley@vienphathoc.org",
    address: "3404 Westminster Avenue, Santa Ana, CA 92703",
    intro: [
      "Catherine Kelley is a dedicated ESL and Academic Writing Instructor with a wealth of experience in diverse educational settings. She is a knowledgeable and compassionate educator who crafts engaging lessons aligned with high standards, utilizing various modalities and technology integration. Catherine is proficient in skills-based learning, and fostering vocabulary development through interactive methods. She is known for creating dynamic and challenging lessons that stimulate higher-order thinking. Catherine provides extensive and encouraging feedback, emphasizing a Growth Mindset."
    ],
    qualifications: [
      "M.A. in English Composition, California State University, San Bernardino.",
      "M.F.A. in Creative Writing, Goddard College.",
    ],
    publications: [
    ],
  },
  victorgabriel: {
    name: "Victor Gabriel",
    image: imgGabriel,
    possition: "Professor",
    phone: "",
    email: "",
    address: "3404 Westminster Avenue, Santa Ana, CA 92703",
    intro: [
      "Dr. Victor Gabriel is an Assistant and Tenured Professor in the Department of Buddhist Chaplaincy at the University of the West. He was chair from 2014-2018 and 2022 to present. He is program coordinator for the Master of Divinity in Buddhist Chaplaincy. He is a board member for the Society of Buddhist-Christian Studies and a Buddhist co-convener of the National Council of Churches’ Buddhist-Christian Dialogue.",
      "His dissertation was titled Embodying Generosity: Buddhist and Feminist views of the Physical Body as seen through the Tibetan Buddhist Ritual of Chöd and it explores the contribution of Feminists to the construction of American Tibetan Buddhism by way of Feminist Theory and the intersectionality of the body and ritual. His publications included: “Ecology of the Bodhisattva” in Refuge in the Storm: Buddhist Approaches to Compassionate Crisis Care, North Atlantic, 2022; “Leading and Facilitating Spiritual Reflection” with Rev. Duane Bidwell PhD in Chaplaincy and Spiritual Care in the 21st Century, University of North Carolina, 2022; “Implications for Interfaith Chaplaincy from a Tibetan Buddhist Understanding of Religious Location and the Two Truths” in Navigating Religious Difference in Spiritual Care and Counseling, Claremont School of Theology, 2019; “Buddhism and Sexuality” in A Thousand Arms: A Guide to Caring for your Buddhist Community, Toronto: Sumeru Books, 2016.",
      "His research areas include Applied Buddhist “theology,” Feminist and Queer Theory, conceptualizations of the Body as seen in Buddhist Art, Ritual Studies and the inculturation of American Buddhism. He was a psychotherapist and is a Buddhist lay minister.",
    ],
    qualifications: [
      "Ph.D., Buddhist Studies, University of the West, USA",
      "MA., Indo-Tibetan Buddhism, Naropa University, USA",
      "BA, Social Sciences, Curtin University, Australia",
    ],
    publications: [
    ],
  },

  johnfreese: {
    name: "John Freese",
    image: imgJohn,
    possition: "Professor",
    phone: "",
    email: "johnfreese72@gmail.com",
    address: "3404 Westminster Avenue, Santa Ana, CA 92703",
    intro: [
      "Reverend Dr. John Freese is a minister in the Dhamma Vinaya Order and the abbot of Mukti Vihara Temple, the online Buddhist temple of the order. He is a professor in world religions and contemplative studies at University of the West, and a professor in spiritual care and counseling at Naropa University. He was a Buddhist monk for 12 years from 1998-2010. He ordained with Thich Nhat Hanh and trained with him for six years. He then trained with other Buddhist and Hindu teachers in Europe and Asia for six more years. His main research areas are early Buddhism, early Daoism, Yogacara, somatic trauma therapy, and Acceptance and Commitment Therapy. He maintains a private practice in Buddhist counseling, edits The New Order on Substack, and is the host of Down with the Dharma podcast. His Dharma name is Dhammabodhi.",
    ],
    qualifications: [
      "PhD in Practical Theology, Claremont School of Theology",
      "M.Div., University of the West",
      "B.A. in Film, Vassar College",
    ],
    publications: [
      "Dhammabodhi, ed. An Anthology of 50 Discourses from the Samyutta Nikaya. Translated by Bhikkhu Sujato. Peñasco New Mexico: Dhamma Vinaya Press, 2023.",
      "Freese, John Brooks. “Towards Trauma-Informed Buddhist Spiritual Care: A Mutual Critical Correlation of Vipassana Meditation and Somatic Experiencing.” Pastoral Psychology 72, no. 3 (June 1, 2023): 447–64.",
      "Freese, John B. “The Dependent Origination of Whiteness.” Kalfou: A Journal of Comparative and Relational Ethnic Studies 5, no. 2 (November 16, 2018). ",
      "Forthcoming Books: The Four Modes of Knowledge Production: A Buddhist Research Methodology , Satipatthana Dipani: A Buddhist Manual of Yogic Spiritual Care ",
    ],

  },
};
function LecturerDetails({name, image, possition, phone, email, address, intro, qualifications,publications}) {
    return (
      <>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="team-single is-sticky">
                    <div className="team-img">
                    <img className="img-fluid border-radius" src={image} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="team-info">
                    <h5 className="team-name h4">{name}</h5>
                    <div className="d-flex">
                      <h6>Phone:</h6>
                      <label className="ml-3">{phone}</label>
                    </div>
                    <div className=" d-flex">
                      <h6>Email:</h6>
                      <label className="ml-3">{email}</label>
                    </div>
                    <div className=" d-flex">
                      <h6>Address:</h6>
                      <label className="ml-3">{address}</label>
                    </div>
                    {
                      intro.map(p => (
                        <p className="mt-3 text-justify">{p}</p>
                      ) )
                    }
                  </div>
                  {qualifications.length > 0 && (
                    <div className="section-title mt-5">
                      <h4 className="title">Qualifications</h4>
                      <div className="list">
                        <ul className="pl-0">
                          {qualifications.map((q, index) => (
                            <li key={index}><i className="fas fa-check text-primary pr-2"></i>{q}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                  {publications.length > 0 && (
                    <div className="section-title mt-5">
                      <h4 className="title">Publications</h4>
                      <div className="list">
                        <ul className="pl-0">
                          {publications.map((q, index) => (
                            <li key={index}><i className="fas fa-check text-primary pr-2"></i>{q}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
      </>
    );
  }
  
  export default LecturerDetails;
import Shuffle from 'shufflejs';

const $ = window.jQuery;
const jQuery = window.jQuery;

export default function shuffle() {
	if ($('.my-shuffle-container').length > 0) {
    var element = document.querySelector('.my-shuffle-container');
    var sizer = element.querySelector('.my-sizer-element');
    var shuffleInstance = new Shuffle(element, {
      itemSelector: '.grid-item',
      sizer: sizer, // could also be a selector: '.my-sizer-element'
      speed: 700,
      columnThreshold: 0
    });
    jQuery(document).ready(function(){
      jQuery(".btn-filter").on( 'click', function(){
        var data_group = jQuery(this).attr('data-group');
        if( data_group !== 'all' ){
          shuffleInstance.filter([data_group]);
        } else {
          shuffleInstance.filter();
        }
      });
      $(".filters-group .btn-filter").on( 'click', function(){
          $(".filters-group .btn-filter").removeClass("active");
          $(this).addClass("active");
      });
    });
  }
}

function Form() {
  return (
    <section className="space-pb">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mb-4 mb-lg-0">
            <h4 className="mb-4">Need Assistance? Please Complete The Contact Form</h4>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <input type="text" className="form-control bg-white border" id="Website" placeholder="Website URL" />
                </div>
                <div className="form-group col-md-6">
                  <input type="text" className="form-control bg-white border" id="name" placeholder="Your Name" />
                </div>
                <div className="form-group col-md-6">
                  <input type="email" className="form-control bg-white border" id="inputEmail4" placeholder="Email Address" />
                </div>
                <div className="form-group col-md-6">
                  <input type="text" className="form-control bg-white border" id="number" placeholder="Phone Number" />
                </div>
                <div className="form-group col-md-12">
                  <textarea className="form-control bg-white border" rows="4" placeholder="Message"></textarea>
                </div>
                <div className="col-md-12">
                  <a className="btn btn-primary" href="/">Send Message</a>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-4">
            <div className="row">
              <div className="col-lg-12">
                  <div className="d-flex mb-3 bg-primary p-4 border-radius mb-4">
                    <div className="text-primary mr-3">
                      <i className="fas fa-3x fa-map-signs text-white"></i>
                    </div>
                    <div className="recent-post-info">
                      <span className="text-white">3404-3412 Westminster Ave.</span>
                      <br />
                      <span className="text-white">Santa Ana, CA 92703</span>
                    </div>
                  </div>
                </div>
              <div className="col-lg-12">
                  <div className="d-flex mb-3 bg-light p-4 border-radius mb-4">
                    <div className="text-primary mr-3">
                      <i className="far fa-3x fa-envelope"></i>
                    </div>
                    <div className="recent-post-info">
                      <span>info@sakyauniversity.com</span>
                      <br />
                      <span>Monday - Friday, 8 AM - 5 PM (PST)</span>
                    </div>
                  </div>
                </div>
              <div className="col-lg-12">
                  <div className="d-flex bg-dark p-4 border-radius">
                    <div className="text-white mr-3">
                      <i className="fas fa-3x fa-phone-alt"></i>
                    </div>
                    <div className="recent-post-info">
                      <span className="text-white">(714) 328 6087</span>
                      <br />
                      <span className="text-white">Monday - Friday, 8 AM - 5 PM (PST)</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Form;

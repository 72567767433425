import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getApp, updateApp } from '../utils';

const programs = [
  null,
  'Master of Art in Buddhist Philosophy',
];

const terms = [
  null,
  'fall_2024',
  'spring_2025',
  'fall_2025',
];

function capitalize(str) {
  return (str.charAt(0).toUpperCase() + str.slice(1)).split('_').join(' ');
}

function ProgramSelection() {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const [errors, setErrors] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();
  const [data, setData] = useState(selectedApp || {});

  useEffect(() => {
    window.jQuery('body,html').animate({
      scrollTop: 460
    }, 1000);
  }, []);

  function onSuccess(data) {
    setSelectedApp(data);
    return navigate('/application/affiliations');
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrors(null);
    updateApp(
      loggedInUser,
      selectedApp,
      data,
      onSuccess,
      setErrors,
    );
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <h1>Program Selection</h1>

            {errors && (
              <ul className="text-danger">
                <li>{errors}</li>
              </ul>
            )}

            <form onSubmit={handleSubmit}>
              <p className='text-justify'>
                Explore the graduate degree programs and admission requirements of Sakya Buddha
                University on this webpage.
              </p>
              <div className="form-group">
                <label htmlFor="college_or_school">College or School (<span
                  className="text-danger">*</span>)</label>
                <select
                  className="form-control"
                  onChange={e => setData({ ...data, program: e.target.value })}
                  required
                >
                  {programs.map((s, idx) => (
                    <option
                      key={`program-${idx}`}
                      value={s}
                      selected={s === data.program}
                    >
                      {s}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="college_or_school">Please select your intended start term: (<span
                  className="text-danger">*</span>)</label>
                <select
                  className="form-control"
                  onChange={e => setData({ ...data, term: e.target.value })}
                  required
                >
                  {terms.map((s, idx) => (
                    <option
                      key={`term-${idx}`}
                      value={s}
                      selected={s === data.term}
                    >
                      {capitalize(s || '')}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group text-justify">
                <label htmlFor="college_or_school">*Do you plan to attend the Sakya Buddha University as
                  a full-time or part-time student? (<span className="text-danger">*</span>)</label>
                <div role="group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="fulltime_or_part_time"
                    id="fulltime"
                    autoComplete="off"
                    defaultChecked={data.fullTime}
                    onChange={e => setData({ ...data, fullTime: true })}
                  />
                  <label htmlFor="fulltime" className="pl-2">Fulltime</label>
                  <input
                    type="radio"
                    className="btn-check ml-4"
                    name="fulltime_or_part_time"
                    id="part_time"
                    autoComplete="off"
                    defaultChecked={!data.fullTime}
                    onChange={e => setData({ ...data, fullTime: false })}
                  />
                  <label htmlFor="part_time" className="pl-2">Part-time</label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="previously_applied">*Have you previously applied to the Sakya Buddha
                  University? (<span className="text-danger">*</span>)</label>
                <div className="" role="group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="previously_applied"
                    id="previously_applied_yes"
                    autoComplete="off"
                    defaultChecked={data.previouslyApplied}
                    onChange={e => setData({ ...data, previouslyApplied: true })}
                  />
                  <label htmlFor="previously_applied_yes" className="pl-2">Yes</label>
                  <input
                    type="radio"
                    className="btn-check ml-4"
                    name="previously_applied"
                    id="previously_applied_no"
                    autoComplete="off"
                    defaultChecked={!data.previouslyApplied}
                    onChange={e => setData({ ...data, previouslyApplied: false })}
                  />
                  <label htmlFor="previously_applied_no" className="pl-2">No</label>
                </div>
              </div>
              <div className="form-group">
                <div className="row justify-content-md-center">
                  <button className="btn btn-primary" type="submit">Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramSelection;

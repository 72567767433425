import React from "react";

function AdmissionRequirements() {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <img className="img-fluid border-radius" src="images/blog/03.jpg" alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12 text-justify">
              <h4 className="mb-3 mt-4">Admission Requirements</h4>
              <p>To expedite the processing of your application, we only accept electronic applications. SBU
                applications must include the following:</p>
              <ol>
                <li><p>Your online application for admission along with a nonrefundable application fee.</p></li>
                <div>
                  <a href="/application" className="btn btn-primary mb-3">Apply Here</a>
                </div>
                <li>
                  <p>Unofficial transcript(s) submitted with your online application.</p>
                  <p style={{ marginLeft:'20px'}}> Requests for official transcript(s) will be made after a decision has been made to
                    finalize the applicant&#39;s file and facilitate enrollment in the subsequent term. The
                    official transcript(s) must be sent electronically through one of the approved
                    companies listed below by the institution that conferred the baccalaureate degree to
                    the applicant.</p>
                  <p style={{ marginLeft:'20px'}}>Hard-copy transcript(s) in a sealed and stamped envelope should be mailed to:</p>
                  <p style={{ marginLeft:'20px'}} className="font-weight-bold">
                    Sakya Buddha University
                    <br/>
                    Admission Processing
                    <br/>
                    3404 Westminster Avenue, Santa Ana, CA 92703
                    <br/>
                  </p>
                </li>
                <li><p>Two confidential recommendation letters. Individuals providing recommendations are
                  required to upload their letters of recommendation directly to the online application. One
                  recommendation must be submitted directly by an individual acquainted with the
                  applicant&#39;s academic or professional competencies, character, and potential to excel in
                  rigorous graduate studies. This requirement can be fulfilled by academic
                  recommendations from professors, instructors, or academic advisors, or professional
                  recommendations from supervisors in employment, volunteer work, internships, or
                  related activities.</p></li>
                <li><p>Submit a statement of purpose ranging from 250 to 500 words through the online
                  application. In this section, please articulate your personal and professional goals and
                  explain how obtaining this degree will contribute to the realization of those objectives.</p></li>
                <li><p>English proficiency requirement: Applicants, including U.S. citizens, whose first
                  language is not English must achieve a minimum score of 100 (internet-based) or 600
                  (paper-based) on the Test of English as a Foreign Language (TOEFL) or a minimum
                  score of 6.0 on the International English Language Testing System (IELTS). However,
                  applicants who hold a bachelor&#39;s degree or higher from a U.S. school are typically
                  exempted from this requirement.</p></li>
                <li>
                  <p>
                    A personal interview may be required.
                    <br/>
                    (Optional) Submit your professional CV or resume through the online application.
                  </p>
                </li>
              </ol>
              <p>For any inquiries regarding the application process, please direct your questions to: </p>
              <p className="font-weight-bold">
                Sakya Buddha University
                <br />
                Graduate Administrator
                <br />
                3404 Westminster Avenue, Santa Ana, CA 92703
                <br />
                Phone: (714) 328 6087
                <br />
                Fax:
                <br />
                Email: info@sakyauniversity.com
                <br />
                Hours: Monday-Friday, 8 AM-5 PM (PST)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdmissionRequirements;

import { Outlet } from 'react-router-dom';
import InnerHeader from '../components/InnerHeader';
import Divider from "../components/divider";
import bgImg from '../images/bg/admisstration.jpg';

const links = [
  { name: 'SBU Admissions', href: '/admissions' },
  { name: 'Admission Requirements', href: '/admissions/requirements' },
  { name: 'Tuition, Fees and Costs', href: '/admissions/tuition' },
  { name: 'Financial Aid', href: '/admissions/financial-aid' },
  { name: 'Apply', href: '/application' },
];

function Admissions() {
  return (
    <>
      <InnerHeader
        customName="Admissions & Aid"
        backgroundImage={bgImg}
      />
      <Divider />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mb-5 mb-lg-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="shadow border-radius p-4">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="course-sidebar shadow">
              <div className="widget">
                <div className="widget-title">
                  <h5>Admissions &amp; Aid</h5>
                </div>
                <div className="widget-categories">
                  {links.map(link => (
                    <a
                      key={link.name}
                      className="btn btn-link btn-block pl-0 text-left d-flex justify-content-between align-items-center"
                      href={link.href}
                    >
                      <span>{link.name}</span>
                      <i className="fas fa-chevron-right text-right ml-auto"></i>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admissions;

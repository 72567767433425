import InnerHeader from '../components/InnerHeader';

function EventsList() {
  return (
    <>
      <InnerHeader />
    </>
  );
}

export default EventsList;

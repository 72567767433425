import React, { useState } from 'react';
import requestBtn from "../images/footer/request_info.png";
import planVisitBtn from "../images/footer/plan_visit.png";
import applyBtn from "../images/footer/apply.png";
import '../css/footer-button.css'; // Import a CSS file for styling

const FooterButton = () => {
  const [hoveredButton, setHoveredButton] = useState(null);

  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  return (
    <section className="pb-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 pb-4 pb-md-0">
            <LinkButton
              to="/application"
              image={applyBtn}
              text="Apply"
              hovered={hoveredButton === 'Apply'}
              onMouseEnter={() => handleMouseEnter('Apply')}
              onMouseLeave={handleMouseLeave}
            />
          </div>
          <div className="col-md-4 pb-4 pb-md-0">
            <LinkButton
              to="/request-info"
              image={requestBtn}
              text="Request Information"
              hovered={hoveredButton === 'Request Information'}
              onMouseEnter={() => handleMouseEnter('Request Information')}
              onMouseLeave={handleMouseLeave}
            />
          </div>
          <div className="col-md-4">
            <LinkButton
              to="/plan-a-visit"
              image={planVisitBtn}
              text="Plan a Visit"
              hovered={hoveredButton === 'Plan a Visit'}
              onMouseEnter={() => handleMouseEnter('Plan a Visit')}
              onMouseLeave={handleMouseLeave}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const LinkButton = ({ to, image, text, hovered, onMouseEnter, onMouseLeave }) => {
  return (
    <a
      href={to}
      className={`button ${hovered ? 'hovered' : ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ textAlign: 'center' }}
    >
      <div className="pricing">
        <h4 className="pricing-title" id='footer-button-text'>{text}</h4>
      </div>
    </a>
  );
};

export default FooterButton;

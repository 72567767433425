import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getApp, updateApp } from '../utils';
import { map } from 'lodash';

const affiliations = [
  'Current SBU Employee (full-time)',
  'I have earned a certificate or degree from SBU',
  'Spouse of current SBU employee',
  'Current SBU Employee (part-time)',
  'Current SBU undergraduate student',
];

function Affiliations() {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const [errors, setErrors] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();
  const [data, setData] = useState(selectedApp || {});

  useEffect(() => {
    window.jQuery('body,html').animate({
      scrollTop: 460
    }, 1000);
  }, []);

  function handleChangeAffiliations(selected) {
    let aff = (data.affiliations || '').split(',');
    if (aff.includes(selected)) {
      aff = aff.filter(r => r !== selected);
    } else {
      aff.push(selected);
    }
    setData({ ...data, affiliations: aff.join(',') });
  }

  function onSuccess(data) {
    setSelectedApp(data);
    return navigate('/application/financial-aid');
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrors(null);
    updateApp(
      loggedInUser,
      selectedApp,
      data,
      onSuccess,
      setErrors,
    );
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <h1>Affiliations</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="race">Please let us know which of the following apply to you:</label>
                {map(affiliations, (a, idx) => (
                  <div key={`aff-${idx}`} className="form-check pb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="affiliations"
                      checked={(data.affiliations || '').split(',').includes(a)}
                      onChange={() => handleChangeAffiliations(a)}
                    />
                    <label className="form-check-label">{a}</label>
                  </div>
                ))}
              </div>
              <div className="form-group">
                <div className="row justify-content-md-center">
                  <button className="btn btn-primary" type="submit">Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Affiliations;

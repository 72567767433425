import React from 'react';
import {Link} from "react-router-dom";

const Post = ({ id, author, date, header, content, image}) => {
    return (
        <div className="events shadow p-4 mb-4 bg-white border-radius">
            <div className="row">
                <div className="col-lg-3">
                    <div className="events-img mb-4 mb-lg-0">
                        <img className="img-fluid border-0" src={image} alt={header}/>
                    </div>
                </div>
                <div className="col-lg-9 align-self-center">
                    <div className="events-content">
                        {author && date && <p>by {author} {date}</p>}
                        <Link to={`/news-detail/${id}`} className="text-dark h5">{header}</Link>
                        <p className="text-dark mb-lg-0">{content}</p>
                        {author && date && <Link to={`/news-detail/${id}`} className="btn btn-primary btn-round mt-1">
                            Read More
                        </Link>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Post;

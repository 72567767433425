const $ = window.jQuery;

export default function sticky() {
  $(window).on('scroll',function(event) {
    var scroll = $(window).scrollTop();
    if (scroll < 300) {
      $(".header-sticky").removeClass("sticky-top");
    } else {
      $(".header-sticky").addClass("sticky-top");
    }
  });
}

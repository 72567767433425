import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getApp, updateApp } from '../utils';

function Signature() {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const [errors, setErrors] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();
  const [data, setData] = useState(selectedApp || {});

  useEffect(() => {
    window.jQuery('body,html').animate({
      scrollTop: 460
    }, 1000);
  }, []);

  function onSuccess(data) {
    setSelectedApp(data);
    return navigate('/application/review');
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrors(null);

    if (!data?.signature) {
      setErrors('Your signature is required.');
      return false;
    }

    updateApp(
      loggedInUser,
      selectedApp,
      data,
      onSuccess,
      setErrors,
    );
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <h1>Signature</h1>
            <form onSubmit={handleSubmit}>
              <div className="bg-dark clearfix">
                <h6 className="p-2">Equal Opportunity Statement</h6>
              </div>
              <p className='text-justify'>
                Sakya Buddha University adheres to the principles of Equal Opportunity. The University
                has a policy against discrimination in student admissions, service provision, and
                employment based on race, color, national origin, age, religion, disability, sex, sexual
                orientation, gender identity, gender expression, genetic information, marital status, or
                veteran status. The University ensures compliance with all relevant federal, state, and
                local laws, regulations, and Executive Orders. </p>
              <p className='text-justify'>
                If there are inquiries or allegations of discrimination related to any of the mentioned
                factors, they can be directed to the Sakya Buddha University Office of Equal Opportunity
                at 3404 & 3412 Westminster Ave, Santa Ana, CA 92703, United States
              </p>
              <div className="bg-dark clearfix">
                <h6 className="p-2">Signature of Applicant</h6>
              </div>
              <p className='text-justify'>By electronically signing, you acknowledge your acceptance of the following terms:</p>
              <p className='text-justify'>I acknowledge that this application and all accompanying credentials submitted for my
                admission file are confidential and become the property of Sakya Buddha University.
                These documents are intended for the exclusive use of the University and may not be
                released, shared, used, or reviewed by any other party, except for transcripts from
                institutions outside the U.S., which may be shared with Educational Credential
                Evaluators, Inc. for the purpose of identifying U.S. equivalencies of educational
                qualifications.</p>
              <p className='text-justify'>I affirm that the information provided in this application is truthful and complete,
                without evasion or misrepresentation. I am aware that intentional omission,
                falsification, or incomplete statements within this application may result in the denial
                of admission or dismissal.</p>

              <div className="form-group">
                <label htmlFor="signature">Instead of a physical signature, please type your full legal name:
                  (<span className="text-danger">*</span>)</label>
                <input
                  type="text"
                  className="form-control"
                  id="signature"
                  defaultValue={data.signature}
                  onChange={e => setData({ ...data, signature: e.target.value })}
                />
              </div>
              {errors && (
                <div className="row">
                  <div className="col-lg-12">
                    <ul className="text-danger">
                      <li>{errors}</li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="form-group">
                <div className="row justify-content-md-center">
                  <button className="btn btn-primary" type="submit">Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signature;

function StudentLife() {
  return (
    <section className="space-ptb bg-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-10 col-md-12">
            <div className="section-title text-center">
              <h2>Frequently Asked Questions</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="mb-4">
              <h5 className="mb-3">How can I use this success to make a difference?</h5>
              <p className="pb-2">Introspection is the trick. Understand what you want, why you want it and what it will do for you. This is a critical factor, and as such, is probably the most difficult step.</p>
            </div>
            <div className="mb-4">
              <h5 className="mb-3">Was this just another little prank, courtesy Universe?</h5>
              <p className="pb-2">I’ve even said it myself, although I added the qualifier that the powerful desire is not so much for the Universe.and as such, is probably the most difficult step.</p>
            </div>
            <div className="clearfix">
              <h5 className="mb-3">How can I use this success to make a difference?</h5>
              <p className="mb-0">Along with your plans, you should consider developing an action orientation that will keep you motivated to move forward at all times. This requires a little self-discipline, but is a kind.</p>
            </div>
          </div>
          <div className="col-sm-6 mt-3 mt-sm-0">
            <div className="mb-4">
              <h5 className="mb-3">Things started shifting my way, and they Why?</h5>
              <p className="pb-2">Review it regularly and ensure that every step takes you closer to your Vision and Goals. If the plan doesn’t support the vision then change it!</p>
            </div>
            <div className="mb-4">
              <h5 className="mb-3">But doesn’t something in that story set with you?</h5>
              <p className="pb-2">What about the guy with the burning desire and the grand vision? He ended up disappointed and broke, dying far from his family and friends. Not a happy ending.</p>
            </div>
            <div className="clearfix">
              <h5 className="mb-3">Why is Clarity so important?</h5>
              <p className="mb-0">The sad thing is the majority of people have no clue about what they truly want. They have no clarity. When asked the question, responses will be superficial at best, and at worst,</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StudentLife;

function AcademicsIntro() {
    return (
      <>
          <div className="section-title my-sm-3 text-justify">
            <h4 className="mb-4">SBU Academic Programs</h4>
              <p><b>PROGRAM REQUIREMENTS</b></p>
              <p>This Handbook applies to students commencing their studies in 2025-2027 and serves as a comprehensive
guide to SBU policies and degree requirements. Students should refer to this document for the most current
information. Note that some students are following an earlier curriculum with distinct degree requirements.</p>
              <p>The SBU website provides access to commonly used forms, anticipated course schedules, and various
resources, including this Handbook. Students are also responsible for familiarizing themselves with all SBU
academic requirements, policies, and procedures.</p>
              <p>In situations when required courses overlap between two (2) or more graduate degree programs at SBU,
students who have earned credit for these courses as part of one (1) graduate degree may count those credits
toward fulfilling the requirements of another graduate degree. To be eligible, students must apply for these
overlapping credits within three (3) academic years from the date the credits were earned.</p>
            <p><b>INSTITUTIONAL LEARNING OUTCOMES</b></p>
            <ol>
            <li>Students will provide an innovative and significant contribution to Buddhism.</li>
            <li>Students will participate in interdisciplinary discourse and scholarly activities across various disciplinary
perspectives.</li>
            <li>Students will display proficiency in a distinct area within their area of study.</li>
            <li>Students will proficiently employ theories, methodologies, and insights from marginalized groups.</li>
            <li>Students will showcase their aptitude to effectively communicate the theories, principles, and practices
across diverse contexts.</li>
            </ol>
          </div>
      </>
    );
  }
  
  export default AcademicsIntro;
import InnerHeader from '../components/InnerHeader';
import Form from '../contact-us/Form';
import Map from '../contact-us/Map';
import Divider from "../components/divider";
import bg from '../images/bg/contact-us.jpg';

function ContactUs() {
  return (
    <>
      <InnerHeader backgroundImage={bg} />
      <Divider />
      <Form />
      <Map />
    </>
  );
}

export default ContactUs;

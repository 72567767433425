import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getNames } from 'country-list';
import states from 'states-us';
import { getApp, updateApp } from '../utils';
import { flatten } from 'lodash';

const suffixes = [null, 'Jr.', 'Sr.', 'I', 'II', 'III', 'IV', 'V'];
const sexes = [null, 'Female', 'Male'];
const genderIdentities = [
  'I elect not to self-identify at this time',
  'Man',
  'Non-binary',
  'Woman',
  'Option not listed',
];
const pronouns = [
  'I elect not to self-identify at this time',
  'any/all',
  'fae/faer',
  'he/him/his',
  'he/el',
  'she/her/hers',
  'she/ella',
  'she/he/they',
  'she/they',
  'they/them/theirs',
  'they/elle',
  'they/he',
  'they/she',
  'ze/hir/hirs',
  'Option not listed',
];
const races = [
  'American Indian or Alaska Native',
  'Asian',
  'Black or African American',
  'Native Hawaiian or Other Pacific',
  'White',
];
const languages = flatten([null, getNames()]);

function PersonalBackground() {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const [errors, setErrors] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();
  const [data, setData] = useState(selectedApp || {});
  const US = 232;
  data.country = getNames()[US];
  data.mailingCountry = getNames()[US];

  const [copyAddress, setCopyAddress] = useState(false);
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setCopyAddress(isChecked);

    if (isChecked) {
      // When checked, copy permanent address to mailing address
      setData({
        ...data,
        mailingCountry: data.country,
        mailingStreetAddress: data.streetAddress,
        mailingCity: data.city,
        mailingState: data.state,
        mailingPostalCode: data.postalCode,
      });
    } else {
      setData({
        ...data,
        mailingCountry: data.country,
        mailingStreetAddress: '',
        mailingCity: '',
        mailingState: '',
        mailingPostalCode: '',
      });
    }
  };


  // const handleInputChange = (e) => {
  //   // If the checkbox is checked, prevent updating mailing address fields directly
  //   if (copyAddress && e.target.id.startsWith("mailing_")) {
  //     return;
  //   }
  //   setData({ ...data, [e.target.id]: e.target.value });
  // };

  useEffect(() => {
    window.jQuery('body,html').animate({
      scrollTop: 460
    }, 1000);
  }, []);

  function handleChangeRace(selected) {
    let race = (data.race || '').split(',');
    if (race.includes(selected)) {
      race = race.filter(r => r !== selected);
    } else {
      race.push(selected);
    }
    setData({ ...data, race: race.join(',') });
  }

  function onSuccess(data) {
    setSelectedApp(data);
    return navigate('/application/program');
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrors(null);
    updateApp(
      loggedInUser,
      selectedApp,
      data,
      onSuccess,
      setErrors,
    );
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <h1>Personal Background</h1>
            <form onSubmit={handleSubmit}>
              <div className="bg-dark clearfix">
                <h6 className="p-2">Name</h6>
              </div>
              <div className="form-group">
                <label htmlFor="first_name">First (Given) Name (<span className="text-danger">*</span>)</label>
                <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    placeholder="Enter your first name"
                    defaultValue={data.firstName}
                    onChange={e => setData({...data, firstName: e.target.value})}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="middle_name">Middle Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="middle_name"
                    placeholder="Enter your middle name"
                    defaultValue={data.middleName}
                    onChange={e => setData({...data, middleName: e.target.value})}
                />
              </div>
              <div className="form-group">
                <label htmlFor="last_name">Last (Family) Name (<span
                    className="text-danger">*</span>)</label>
                <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    placeholder="Enter your last name"
                    defaultValue={data.lastName}
                    onChange={e => setData({...data, lastName: e.target.value})}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="suffix">Suffix</label>
                <select
                    className="form-control"
                    id="suffix"
                    onChange={e => setData({...data, suffix: e.target.value})}
                >
                  {suffixes.map((s, idx) => (
                      <option
                          key={`suffix-${idx}`}
                          value={s}
                          selected={s === data.suffix}
                      >
                        {s}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="nickname">Preferred First Name / Nickname</label>
                <input
                    type="text"
                    className="form-control"
                    id="nickname"
                    defaultValue={data.preferredFirstName}
                    onChange={e => setData({...data, preferredFirstName: e.target.value})}
                />
              </div>
              <div className="form-group">
                <label htmlFor="previous_last_name">Previous Last (Family) Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="previous_last_name"
                    defaultValue={data.previousLastName}
                    onChange={e => setData({...data, previousLastName: e.target.value})}
                />
              </div>
              <div className="form-group">
                <label htmlFor="previous_first_name">Previous First (Given) Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="previous_first_name"
                    defaultValue={data.previousFirstName}
                    onChange={e => setData({...data, previousFirstName: e.target.value})}
                />
              </div>
              {/*<div className="form-group">
                <label htmlFor="have_previous_name">Do you have an additional previous name?</label>
                <div className="" role="group">
                  <input type="radio" className="btn-check" name="have_previous_name"
                       id="have_previous_name_yes"
                       autoComplete="off"/>
                  <label htmlFor="have_previous_name_yes" className="pl-2">Yes</label>
                  <input type="radio" className="btn-check ml-4" name="have_previous_name"
                       id="have_previous_name_no"
                       autoComplete="off"/>
                  <label htmlFor="have_previous_name_no" className="pl-2">No</label>
                </div>
              </div>*/}

              <div className="bg-dark clearfix">
                <h6 className="p-2">Address Information</h6>
              </div>
              <div className='text-justify'>
                <p>*The Permanent/Home Address indicated refers to your lasting and legal
                  residence and should not
                  be a P.O. Box.</p>
                {/*<p>For international applicants, this should be your enduring residential address in*/}
                {/*  your home country.*/}
                {/*  Additionally, you are required to furnish at least <strong>ONE</strong> mailing*/}
                {/*  address.</p>*/}
              </div>

              <div className="bg-dark clearfix">
                <h6 className="p-2">Addresses</h6>
              </div>
              <div className="bg-gray clearfix">
                <h6 className="p-2">Permanent Address</h6>
              </div>
              <div className="form-group">
                <label htmlFor="country">Country (<span className="text-danger">*</span>)</label>
                <select
                    className="form-control"
                    id="country"
                    onChange={e => setData({...data, country: e.target.value})}
                    required
                >
                  {getNames().map((c, idx) => (
                      <option
                          key={`country-${idx}`}
                          value={c}
                          selected={c === data.country}
                      >
                        {c}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="street_address">Street Address (<span className="text-danger">*</span>)</label>
                <input
                    type="text"
                    className="form-control"
                    id="street_address"
                    defaultValue={data.streetAddress}
                    onChange={e => setData({...data, streetAddress: e.target.value})}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="city">City (<span className="text-danger">*</span>)</label>
                <input
                    type="text"
                    className="form-control"
                    id="city"
                    defaultValue={data.city}
                    onChange={e => setData({...data, city: e.target.value})}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="state">State</label>
                <select
                    className="form-control"
                    id="state"
                    placeholder="Select State"
                    onChange={e => setData({...data, state: e.target.value})}
                >
                  <option selected={!data.state}>N/A</option>
                  {states.map((s, idx) => (
                      <option
                          key={`state-${idx}`}
                          value={s.abbreviation}
                          selected={s.abbreviation === data.state}
                      >
                        {s.name}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="postal_code">Postal Code</label>
                <input
                    type="text"
                    className="form-control"
                    id="postal_code"
                    defaultValue={data.postalCode}
                    onChange={e => setData({...data, postalCode: e.target.value})}
                />
              </div>

              <div className="form-group">
                <div className="checkbox">
                  <label>
                    <input
                        type="checkbox"
                        checked={copyAddress}
                        onChange={handleCheckboxChange}
                    /> Copy Permanent Address to Mailing Address
                  </label>
                </div>
              </div>

              <div className="bg-gray clearfix">
                <h6 className="p-2">Mailing Address</h6>
              </div>
              <div className="form-group">
                <label htmlFor="country">Country (<span className="text-danger">*</span>)</label>
                <select
                    className="form-control"
                    id="country"
                    onChange={e => setData({...data, mailingCountry: e.target.value})}
                    required
                    disabled={copyAddress}
                >
                  {getNames().map((c, idx) => (
                      <option
                          key={`country-${idx}`}
                          value={c}
                          selected={c === data.mailingCountry}
                      >
                        {c}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="street_address">Street Address (<span className="text-danger">*</span>)</label>
                <input
                    type="text"
                    className="form-control"
                    id="mailing_street_address"
                    defaultValue={data.mailingStreetAddress}
                    onChange={e => setData({...data, mailingStreetAddress: e.target.value})}
                    required
                    disabled={copyAddress}
                />
              </div>
              <div className="form-group">
                <label htmlFor="city">City (<span className="text-danger">*</span>)</label>
                <input
                    type="text"
                    className="form-control"
                    id="mailing_city"
                    defaultValue={data.mailingCity}
                    onChange={e => setData({...data, mailingCity: e.target.value})}
                    required
                    disabled={copyAddress}
                />
              </div>
              <div className="form-group">
                <label htmlFor="state">State</label>
                <select
                    className="form-control"
                    id="mailing_state"
                    placeholder="Select State"
                    onChange={e => setData({...data, mailingState: e.target.value})}
                    disabled={copyAddress}
                >
                  <option selected={!data.mailingState}>N/A</option>
                  {states.map((s, idx) => (
                      <option
                          key={`state-${idx}`}
                          value={s.abbreviation}
                          selected={s.abbreviation === data.mailingState}
                      >
                        {s.name}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="postal_code">Postal Code</label>
                <input
                    type="text"
                    className="form-control"
                    id="mailing_postal_code"
                    defaultValue={data.mailingPostalCode}
                    onChange={e => setData({...data, mailingPostalCode: e.target.value})}
                    disabled={copyAddress}
                />
              </div>
              {/*<div className="bg-gray clearfix">
                <h6 className="p-2">Insert Address</h6>
              </div>*/}

              <div className="bg-dark clearfix">
                <h6 className="p-2">Email Address</h6>
              </div>
              <div className="row">
                <div className="col-lg-3">Current Email</div>
                <div className="col-lg-6">{loggedInUser && loggedInUser.email}</div>
                {/*<div className="col-lg-3 text-right">Change</div>*/}
              </div>

              <div className="bg-dark clearfix">
                <h6 className="p-2">Telephone Numbers <span>(include +country code)</span></h6>
              </div>
              <div className="form-group">
                <label htmlFor="home_phone_number">Home Phone Number (999-999-9999)</label>
                <input
                    type="text"
                    className="form-control"
                    id="home_phone_number"
                    defaultValue={data.homePhoneNumber}
                    onChange={e => setData({...data, homePhoneNumber: e.target.value})}
                />
              </div>
              <div className="form-group">
                <label htmlFor="mobile_phone_number">Mobile Phone Number (999-999-9999) (<span
                    className="text-danger">*</span>)</label>
                <input
                    type="text"
                    className="form-control"
                    id="mobile_phone_number"
                    defaultValue={data.mobilePhoneNumber}
                    onChange={e => setData({...data, mobilePhoneNumber: e.target.value})}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="like_to_receive_messages">Would you like to receive text messages?
                  (optional)<br/>Standard
                  text messaging rates may apply.</label>
                <div className="" role="group">
                  <input
                      type="radio"
                      className="btn-check"
                      name="like_to_receive_messages"
                      id="like_to_receive_messages_yes"
                      autoComplete="off"
                      defaultChecked={data.receiveTextMessages}
                      onChange={e => setData({...data, receiveTextMessages: true})}
                  />
                  <label htmlFor="like_to_receive_messages_yes" className="pl-2">Yes</label>
                  <input
                      type="radio"
                      className="btn-check ml-4"
                      name="like_to_receive_messages"
                      id="like_to_receive_messages_no"
                      autoComplete="off"
                      defaultChecked={!data.receiveTextMessages}
                      onChange={e => setData({...data, receiveTextMessages: false})}
                  />
                  <label htmlFor="like_to_receive_messages_nos" className="pl-2">No</label>
                </div>
              </div>

              <div className="bg-dark clearfix">
                <h6 className="p-2">Biographical Information</h6>
              </div>
              <p className='text-justify'>
                Diversity Statement: At Sakya Buddha University, we highly value the diversity within
                our student community. The collection of this data serves as one means to gauge our
                progress in fostering diversity and to adhere to federal law and other necessary
                external demographic reporting requirements. Except for the required fields of Birthdate
                and Native Language, we kindly ask for your voluntary cooperation in aligning yourself
                with the racial and ethnic reporting categories provided below. If none of the offered
                categories accurately represent you, please choose the option(s) that best correspond.
                Rest assured that the information you provide will not be utilized in any discriminatory
                manner. Thank you for your cooperation.
              </p>
              <div className="form-group">
                <label htmlFor="sex">Sex (<span className="text-danger">*</span>)</label>
                <select
                    className="form-control"
                    id="sex"
                    onChange={e => setData({...data, sex: e.target.value})}
                    required
                >
                  {sexes.map((s, idx) => (
                      <option
                          key={`sex-${idx}`}
                          value={s}
                          selected={s === data.sex}
                      >
                        {s}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="gender_identity">What is your gender identity? (<span className="text-danger">*</span>)</label>
                <select
                    className="form-control"
                    id="gender_identity"
                    onChange={e => setData({...data, genderIdentity: e.target.value})}
                    required
                >
                  {genderIdentities.map((s, idx) => (
                      <option
                          key={`gi-${idx}`}
                          value={s}
                          selected={s === data.genderIdentity}
                      >
                        {s}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="personal_pronouns">What pronouns do you use?</label>
                <select
                    className="form-control"
                    id="personal_pronouns"
                    defaultValue={data.personalPronouns}
                    onChange={e => setData({...data, personalPronouns: e.target.value})}
                >
                  {pronouns.map((s, idx) => (
                      <option
                          key={`pronoun-${idx}`}
                          value={s}
                          selected={s === data.personalPronouns}
                      >
                        {s}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="birthdate">Birthdate (<span className="text-danger">*</span>)</label>
                <input
                    type="date"
                    className="form-control"
                    id="birthdate"
                    defaultValue={data.birthDate}
                    onChange={e => setData({...data, birthDate: e.target.value})}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="birth_country">Birth Country (<span className="text-danger">*</span>)</label>
                <select
                    className="form-control"
                    id="birth_country"
                    defaultValue={data.birthCountry}
                    onChange={e => setData({...data, birthCountry: e.target.value})}
                    required
                >
                  <option value="">Select a country</option>
                  {getNames().map((c, idx) => (
                      <option
                          key={`birth-country-${idx}`}
                          value={c}
                          selected={c === data.birthCountry}
                      >
                        {c}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="birth_city">Birth City (<span className="text-danger">*</span>)</label>
                <input
                    type="text"
                    className="form-control"
                    id="birth_city"
                    defaultValue={data.birthCity}
                    onChange={e => setData({...data, birthCity: e.target.value})}
                    required
                />
              </div>
              <div className="form-group">
                <label htmlFor="birth_state">Birth State</label>
                <select
                    className="form-control"
                    id="birth_state"
                    placeholder="Select State"
                    onChange={e => setData({...data, birthState: e.target.value})}
                >
                  <option selected={!data.birthState}>N/A</option>
                  {states.map((s, idx) => (
                      <option
                          key={`birth-state-${idx}`}
                          value={s.abbreviation}
                          selected={s.abbreviation === data.birthState}
                      >
                        {s.name}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="native_language">Native Language (<span className="text-danger">*</span>)</label>
                <select
                    className="form-control"
                    id="native_language"
                    onChange={e => setData({...data, nativeLanguage: e.target.value})}
                    required
                >
                  {languages.map((c, idx) => (
                      <option
                          key={`native-language-${idx}`}
                          value={c}
                          selected={c === data.nativeLanguage}
                      >
                        {c}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="military_veteran">Are you a veteran of the United States Armed
                  Forces?</label>
                <div className="" role="group">
                  <input
                      type="radio"
                      className="btn-check"
                      name="military_veteran"
                      id="military_veteran_yes"
                      autoComplete="off"
                      defaultChecked={data.militaryVeteran}
                      onChange={e => setData({...data, militaryVeteran: true})}
                  />
                  <label htmlFor="military_veteran_yes" className="pl-2">Yes</label>
                  <input
                      type="radio"
                      className="btn-check ml-4"
                      name="military_veteran"
                      id="military_veteran_no"
                      autoComplete="off"
                      defaultChecked={!data.militaryVeteran}
                      onChange={e => setData({...data, militaryVeteran: false})}
                  />
                  <label htmlFor="military_veteran_no" className="pl-2">No</label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="military_status">Are you an active or reserve member of the United
                  States Armed Forces
                  or National Guard?</label>
                <div className="" role="group">
                  <input
                      type="radio"
                      className="btn-check"
                      name="military_status"
                      id="military_status_yes"
                      autoComplete="off"
                      defaultChecked={data.militaryStatus}
                      onChange={e => setData({...data, militaryStatus: true})}
                  />
                  <label htmlFor="military_status_yes" className="pl-2">Yes</label>
                  <input
                      type="radio"
                      className="btn-check ml-4"
                      name="military_status"
                      id="military_status_no"
                      autoComplete="off"
                      defaultChecked={!data.militaryStatus}
                      onChange={e => setData({...data, militaryStatus: false})}
                  />
                  <label htmlFor="military_status_no" className="pl-2">No</label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="military_dependent">Are you a dependent of an active duty service member
                  or
                  veteran?</label>
                <div className="" role="group">
                  <input
                      type="radio"
                      className="btn-check"
                      name="military_dependent"
                      id="military_dependent_yes"
                      autoComplete="off"
                      defaultChecked={data.militaryDependent}
                      onChange={e => setData({...data, militaryDependent: true})}
                  />
                  <label htmlFor="military_dependent_yes" className="pl-2">Yes</label>
                  <input
                      type="radio"
                      className="btn-check ml-4"
                      name="military_dependent"
                      id="military_dependent_no"
                      autoComplete="off"
                      defaultChecked={!data.militaryDependent}
                      onChange={e => setData({...data, militaryDependent: false})}
                  />
                  <label htmlFor="military_dependent_no" className="pl-2">No</label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="military_benefits">Will you be using VA benefits?</label>
                <div className="" role="group">
                  <input
                      type="radio"
                      className="btn-check"
                      name="military_benefits"
                      id="military_benefits_yes"
                      autoComplete="off"
                      defaultChecked={data.militaryBenefits}
                      onChange={e => setData({...data, militaryBenefits: true})}
                  />
                  <label htmlFor="military_benefits_yes" className="pl-2">Yes</label>
                  <input
                      type="radio"
                      className="btn-check ml-4"
                      name="military_benefits"
                      id="military_benefits_no"
                      autoComplete="off"
                      defaultChecked={!data.militaryBenefits}
                      onChange={e => setData({...data, militaryBenefits: false})}
                  />
                  <label htmlFor="military_benefits_no" className="pl-2">No</label>
                </div>
              </div>
              <div className="bg-dark clearfix">
                <h6 className="p-2">Citizenship Information</h6>
              </div>
              <div className="form-group">
                <label htmlFor="citizenship">Primary Citizenship (<span className="text-danger">*</span>)</label>
                <select
                    className="form-control"
                    id="citizenship"
                    defaultValue={data.citizenship}
                    onChange={e => setData({...data, citizenship: e.target.value})}
                    required
                >
                  <option value="">Select a country</option>
                  {getNames().map((c, idx) => (
                      <option
                          key={`citizenship-${idx}`}
                          value={c}
                          selected={c === data.citizenship}
                      >
                        {c}
                      </option>
                  ))}
                </select>
              </div>
              <div className="bg-gray clearfix">
                <h6 className="p-2">Race/Ethnicity</h6>
              </div>
              <p className='text-justify'>
                Educational institutions receive inquiries from various entities such as the federal
                government, accrediting associations, college guides, and newspapers, seeking
                information on the ethnic and racial composition of their students and staff. To address
                these inquiries, we kindly request your response to the following two questions.
              </p>
              <div className="form-group">
                <label htmlFor="hispanic_or_latino">Do you consider yourself to be Hispanic or
                  Latino?</label>
                <div className="" role="group">
                  <input
                      type="radio"
                      className="btn-check"
                      name="hispanic_or_latino"
                      id="hispanic_or_latino_yes"
                      autoComplete="off"
                      defaultChecked={data.hispanicOrLatino}
                      onChange={e => setData({...data, hispanicOrLatino: true})}
                  />
                  <label htmlFor="hispanic_or_latino_yes" className="pl-2">Yes</label>
                  <input
                      type="radio"
                      className="btn-check ml-4"
                      name="hispanic_or_latino"
                      id="hispanic_or_latino_no"
                      autoComplete="off"
                      defaultChecked={!data.hispanicOrLatino}
                      onChange={e => setData({...data, hispanicOrLatino: false})}
                  />
                  <label htmlFor="hispanic_or_latino_no" className="pl-2">No</label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="race">Regardless of your answer to the prior question, please check one
                  or more of the
                  following groups in which you consider yourself to be a member:</label>

                {races.map(r => (
                    <div key={r} className="form-check">
                      <input
                          className="form-check-input"
                          type="checkbox"
                          id="race"
                          name="race"
                          autoComplete="off"
                          checked={(data.race || '').split(',').includes(r)}
                          onChange={() => handleChangeRace(r)}
                      />
                      <label className="form-check-label">{r}</label>
                    </div>
                ))}
                {errors && (
                  <div className="row my-2 justify-content-md-center">
                    <div className="col-lg-12">
                      <ul className="text-danger">
                        <li>{errors}</li>
                      </ul>
                    </div>
                  </div>
                )}
                <div className="row justify-content-md-center">
                  <button className="btn btn-primary" type="submit">Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  }

  export default PersonalBackground;

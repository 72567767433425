import img1 from '../images/blog/01.jpg';
import img2 from '../images/blog/02.jpg';
import img5 from '../images/blog/05.jpg';
import img8 from '../images/blog/08.jpg';

function Articles() {
  return (
    <section className="space-ptb position-relative">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-10 text-center">

            <div className="section-title">
              <h2 className="title">Latest Articles</h2>
              <p>Check out our latest blog posts, articles, client success stories and essential announcement</p>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-0">

            <div className="blog mb-4 mb-lg-0">

              <div className="blog-image">
                <img className="img-fluid border-radius" src={img2} alt="" />
              </div>

              <div className="blog-content pt-4 border-0">
                <h5 className="blog-title mb-3"><a href="blog-detail.html">The problem with the push for more college degrees</a></h5>
                <div className="blog-meta mb-0">
                  <ul className="list-unstyled mb-0 d-flex">
                    <li className="mr-3"><a href="/"><i className="fas fa-calendar-alt pr-1"></i> 15 Oct, 2020</a></li>
                    <li><a href="/"><i className="fas fa-clock pr-1"></i>  10:00 AM - 12:00 PM</a></li>
                  </ul>
                </div>
                <p className="mt-3 mb-0">Imagine reaching deep inside you for all the strength and wisdom that you need to make this decision today.</p>
              </div>
            </div>

          </div>
          <div className="col-lg-6 col-md-12 mb-0">

            <div className="blog d-md-flex border-bottom mb-4 pb-4">

              <div className="blog-image mr-0 mr-sm-3">
                <img className="img-fluid border-radius" src={img5} alt="" />
              </div>

              <div className="blog-content pt-4 pt-md-0 border-0">
                <h5 className="blog-title mb-3"><a href="blog-detail.html">Colleges with the most hispanic students</a></h5>
                <div className="blog-meta mb-0">
                  <ul className="list-unstyled mb-0 d-flex">
                    <li className="mr-3"><a href="/"><i className="fas fa-calendar-alt pr-1"></i> 20 Sept, 2020</a></li>
                    <li><a href="/"><i className="fas fa-clock pr-1"></i>  12:00 PM - 3:00 PM</a></li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="blog d-md-flex border-bottom mb-4 pb-4">

              <div className="blog-image mr-0 mr-sm-3">
                <img className="img-fluid border-radius" src={img1} alt="" />
              </div>

              <div className="blog-content pt-4 pt-md-0 border-0">
                <h5 className="blog-title mb-3"><a href="blog-detail.html">Get a job, internship during COVID-19</a></h5>
                <div className="blog-meta mb-0">
                  <ul className="list-unstyled mb-0 d-flex">
                    <li className="mr-3"><a href="/"><i className="fas fa-calendar-alt pr-1"></i> 05 Nov, 2020</a></li>
                    <li><a href="/"><i className="fas fa-clock pr-1"></i>  2:00 PM - 4:00 PM</a></li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="blog d-md-flex">

              <div className="blog-image mr-0 mr-sm-3">
                <img className="img-fluid border-radius" src={img8} alt="" />
              </div>

              <div className="blog-content pt-4 pt-md-0 border-0">
                <h5 className="blog-title mb-3"><a href="blog-detail.html">Why student loan default rate matters</a></h5>
                <div className="blog-meta mb-0">
                  <ul className="list-unstyled mb-0 d-flex">
                    <li className="mr-3"><a href="/"><i className="fas fa-calendar-alt pr-1"></i> 27 Nov, 2020</a></li>
                    <li><a href="/"><i className="fas fa-clock pr-1"></i>  4:00 PM - 6:00 PM</a></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Articles;

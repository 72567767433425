import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getApps, createApp, convertKeysToCamelCase } from '../utils';

function programTerm(data) {
  let res = '';
  if (data.program) {
    res += data.program;
  }
  if (data.term) {
    res += ` - ${data.term} Semester`;
  }
  return res;
}

function Table({ handleSignOut }) {
  const navigate = useNavigate();

  const { loggedInUser, logout } = useAuth();

  const [data, setData] = useState([]);
  const [errors, setErrors] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();

  useEffect(() => {
    setErrors(null);
    getApps(loggedInUser, setData, setErrors, logout);
  }, [loggedInUser]);

  function goToNextPage() {
    return navigate('/application/welcome');
  }

  function handleSelectApp(e, app) {
    e.preventDefault();
    setSelectedApp(convertKeysToCamelCase(app));
    if (app.submitted_at && app.application_status === 'Submitted') {
      return navigate('/application/status');
    }
    goToNextPage();
  }

  function onSuccess(app) {
    setSelectedApp(convertKeysToCamelCase(app));
    goToNextPage();
  }

  function handleCreateApp(e) {
    e.preventDefault();
    setErrors(null);
    createApp(loggedInUser, onSuccess, setErrors);
  }

  return (
    <>
      <table className="table text-center mb-0">
        <thead>
          <tr className="bg-dark">
            <th colSpan={4} className="text-white" scope="col">Your Applications</th>
          </tr>
          <tr className="bg-gray">
            <th>Type</th>
            <th>Status</th>
            <th>Started</th>
            <th>Submitted</th>
          </tr>
        </thead>
        <tbody>
          {errors && (
            <tr>
              <td className="text-left text-danger">{errors}</td>
            </tr>
          )}
          <tr>
            <td className="text-left">
              {`You have started ${data.length} application, listed below, using this
              account.`}
            </td>
            <td />
            <td />
            <td />
          </tr>
          {data.map(d => (
            <tr key={`app-${d.attributes.id}`}>
              <td className="text-left">
                <a href="#" onClick={e => handleSelectApp(e, d.attributes)}>
                  <u>Degrees and Certificates</u>
                </a>
                <p>{programTerm(d.attributes)}</p>
              </td>
              <td>{d.attributes.application_status || 'In progress'}</td>
              <td>{new Date(d.attributes.created_at).toLocaleDateString("en-US")}</td>
              <td>{d.attributes.submitted_at ? new Date(d.attributes.submitted_at).toLocaleDateString("en-US") : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center mt-4">
        <a href="#" onClick={handleCreateApp} className="underline">
          <u>Start New Application</u>
        </a>
      </div>
      <div className="text-center mt-4">
        <button className="btn btn-block" onClick={handleSignOut}>
          Log Out
        </button>
      </div>
    </>
  );
}

export default Table;

import InnerHeader from '../components/InnerHeader';

function EventDetail() {
  return (
    <>
      <InnerHeader />
    </>
  );
}

export default EventDetail;

import { useState } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const useCookies = () => {
  const [value, setValue] = useState(null);

  const setItem = (key, value) => {
    cookies.set(key, value, {
      path: '/',
      secure: true,
      maxAge: 7200,
    });
    setValue(value);
  };

  const getItem = (key) => {
    const value = cookies.get(key);
    setValue(value);
    return value;
  };

  const removeItem = (key) => {
    cookies.remove(key,
      {
        path: '/',
      }
    );
    setValue(null);
  };

  return { value, setItem, getItem, removeItem };
}


function Lecturer({img, name, urlDetails}) {
    return (
      <>
        <div className="col-lg-4 col-md-4 col-sm-6  mb-4 mb-md-3">
          <div className="team">
            <div className="team-img">
              <img className="img-fluid" style={{ height: '250px', width: '220px'}} src={img} alt="" />
            </div>
            <a className="team-info" href={urlDetails}>
            <div className="simple-tag">{name}</div>
            </a>
          </div>
        </div>
      </>
    );
  }
  
  export default Lecturer;
import InnerHeader from '../components/InnerHeader';
import Subscribe from '../components/Subscribe';
import img1 from '../images/team/01.jpg';
import img2 from '../images/team/02.jpg';
import img3 from '../images/team/03.jpg';
import img4 from '../images/team/04.jpg';
import img5 from '../images/team/05.jpg';
import img6 from '../images/team/06.jpg';
import img7 from '../images/team/07.jpg';
import img8 from '../images/team/08.jpg';

function Teachers() {
  return (
    <>
      <InnerHeader />
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 text-center">

              <div className="section-title">
                <h2>Our Teachers</h2>
                <p>Meet the outstanding performers in our industry-award-winning team of professionals</p>
              </div>

            </div>
          </div>
          <div className="row mb-xl-4">
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4">

              <div className="team">
                <div className="team-img">
                  <img className="img-fluid" src={img1} alt="" />
                </div>
                <div className="team-info">
                  <a href="teachers-single.html" className="team-name">Mellissa Doe</a>
                  <p className="team-leader">Team Leader</p>
                  <ul className="list-unstyled">
                    <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>

            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4">

              <div className="team">
                <div className="team-img">
                  <img className="img-fluid" src={img7} alt="" />
                </div>
                <div className="team-info">
                  <a href="teachers-single.html" className="team-name">Felica Queen</a>
                  <p className="team-leader">Designer</p>
                  <ul className="list-unstyled">
                    <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>

            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4">

              <div className="team">
                <div className="team-img">
                  <img className="img-fluid" src={img8} alt="" />
                </div>
                <div className="team-info">
                  <a href="teachers-single.html" className="team-name">Ben Aguilar</a>
                  <p className="team-leader">Developer</p>
                  <ul className="list-unstyled">
                    <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>

            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4 mb-sm-0">

              <div className="team">
                <div className="team-img">
                  <img className="img-fluid" src={img4} alt="" />
                </div>
                <div className="team-info">
                  <a href="teachers-single.html" className="team-name">Melanie Byrd</a>
                  <p className="team-leader">Quality control</p>
                  <ul className="list-unstyled">
                    <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4 mb-xl-0">

              <div className="team">
                <div className="team-img">
                  <img className="img-fluid" src={img5} alt="" />
                </div>
                <div className="team-info">
                  <a href="teachers-single.html" className="team-name">Harry Russell</a>
                  <p className="team-leader">Marketing</p>
                  <ul className="list-unstyled">
                    <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>

            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4 mb-xl-0">

              <div className="team">
                <div className="team-img">
                  <img className="img-fluid" src={img2} alt="" />
                </div>
                <div className="team-info">
                  <a href="teachers-single.html" className="team-name">Ora Bryan</a>
                  <p className="team-leader">Illustration</p>
                  <ul className="list-unstyled">
                    <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>

            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4 mb-sm-0">

              <div className="team">
                <div className="team-img">
                  <img className="img-fluid" src={img6} alt="" />
                </div>
                <div className="team-info">
                  <a href="teachers-single.html" className="team-name">Homer Reyes</a>
                  <p className="team-leader">Community</p>
                  <ul className="list-unstyled">
                    <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>

            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6">

              <div className="team">
                <div className="team-img">
                  <img className="img-fluid" src={img3} alt="" />
                </div>
                <div className="team-info">
                  <a href="teachers-single.html" className="team-name">Gwen Bass</a>
                  <p className="team-leader">Sales and Marketing</p>
                  <ul className="list-unstyled">
                    <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <Subscribe />
    </>
  );
}

export default Teachers;

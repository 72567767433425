function Form() {
	return (
		<section className="mt-md-n5 mt-4 position-relative z-index-9">
      <div className="container">
        <form className="course-form border-radius">
          <div className="row">
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="form-group mb-0">
                <label>Full Name</label>
                <input className="form-control" type="text" id="name" placeholder="Your Name" />
                <i className="far fa-user"></i>
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="form-group select-border mb-0">
                <label>Your Courses</label>
                <select className="form-control basic-select">
                  <option value="1" defaultValue="selected">Please Choose</option>
                  <option value="2">Electrical Engineering</option>
                  <option value="3">Computer Science</option>
                  <option value="4">Biological Sciences</option>
                  <option value="4">Masters in Accounting</option>
                </select>
              </div>
            </div>
            <div className="col-md-4 align-self-center">
              <div className="course-serach">
                <a className="btn btn-dark btn-block mt-md-4" href="/">Search Course</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
	);
}

export default Form;

const $ = window.jQuery;

export default function countdownTimer() {
  var $countdownTimer = $('.countdown');
	if ($countdownTimer.length > 0) {
    $countdownTimer.downCount({
      date: '02/25/2024 12:00:00', // Month/Date/Year HH:MM:SS
      offset: -4
    });
  }
}

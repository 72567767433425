import { useEffect } from 'react';
import { shuffle, mediaPopup } from '../components/animation';
import InnerHeader from '../components/InnerHeader';
import Subscribe from '../components/Subscribe';
import img1 from '../images/gallery/01.jpg';
import img2 from '../images/gallery/02.jpg';
import img3 from '../images/gallery/03.jpg';
import img4 from '../images/gallery/04.jpg';
import img5 from '../images/gallery/05.jpg';
import img6 from '../images/gallery/06.jpg';

function Gallery() {
  useEffect(() => {
    shuffle();
    mediaPopup();
  }, []);
  return (
    <>
      <InnerHeader />
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 text-center">

              <div className="section-title">
                <h2>Our Gallery</h2>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="filters-group mb-lg-4 text-center">
                <button className="btn-filter  active" data-group="all">All</button>
                <button className="btn-filter" data-group="branding">Branding</button>
                <button className="btn-filter" data-group="web">Web</button>
                <button className="btn-filter" data-group="photography">Photography</button>
                <button className="btn-filter" data-group="advertising">Advertising</button>
                <button className="btn-filter" data-group="design">Design</button>
              </div>
              <div className="my-shuffle-container columns-3 popup-gallery full-screen">

                <div className="grid-item" data-groups='["branding"]'>
                  <div className="portfolio-item">
                    <div className="position-relative">
                      <img className="img-fluid" src={img2} alt="" />
                      <div className="portfolio-overlay">
                        <a className="portfolio-img" href="images/gallery/02.jpg"><i className="fas fa-arrows-alt"></i></a>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="grid-item" data-groups='["web", "photography", "branding"]'>
                  <div className="portfolio-item">
                    <div className="position-relative">
                      <img className="img-fluid" src={img3} alt="" />
                      <div className="portfolio-overlay">
                        <a className="portfolio-img" href="images/gallery/03.jpg"><i className="fas fa-arrows-alt"></i></a>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="grid-item" data-groups='["photography"]'>
                  <div className="portfolio-item">
                    <div className="position-relative">
                      <img className="img-fluid" src={img4} alt="" />
                      <div className="portfolio-overlay">
                        <a className="portfolio-img" href="images/gallery/04.jpg"><i className="fas fa-arrows-alt"></i></a>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="grid-item" data-groups='["advertising"]'>
                  <div className="portfolio-item">
                    <div className="position-relative">
                      <img className="img-fluid" src={img5} alt="" />
                      <div className="portfolio-overlay">
                        <a className="portfolio-img" href="images/gallery/05.jpg"><i className="fas fa-arrows-alt"></i></a>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="grid-item" data-groups='["design", "advertising"]'>
                  <div className="portfolio-item">
                    <div className="position-relative">
                      <img className="img-fluid" src={img6} alt="" />
                      <div className="portfolio-overlay">
                        <a className="portfolio-img" href="images/gallery/06.jpg"><i className="fas fa-arrows-alt"></i></a>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="grid-item" data-groups='["branding", "web"]'>
                  <div className="portfolio-item">
                    <div className="position-relative">
                      <img className="img-fluid" src={img1} alt="" />
                      <div className="portfolio-overlay">
                        <a className="portfolio-img" href="images/gallery/01.jpg"><i className="fas fa-arrows-alt"></i></a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <Subscribe />
    </>
  );
}

export default Gallery;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';

function SignIn() {
  const navigate = useNavigate();

  const { loggedInUser, login } = useAuth();

  const [emailParam, setEmailParam] = useState(null);
  const [passwordParam, setPasswordParam] = useState(null);
  const [errors, setErrors] = useState(null);

  function handleSignIn() {
    setErrors(null);

    if (loggedInUser) {
      navigate('/application');
      return true;
    }
    axios.post(
      `${process.env.REACT_APP_BACKEND_API}/login`,
      { user: { email: emailParam, password: passwordParam } },
    )
      .then(response => {
        if (response.data && response.data.status.code === 200) {
          login({ ...response.data.data, auth: response.headers.authorization });
          navigate('/application');
        } else {
          setErrors(response.data.errors);
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          return setErrors(error.response.data);
        }
        setErrors(error.message);
      })
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleSignIn();
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h2>Sign In</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-7 mb-2">
            <div className="login bg-white shadow p-5">
              {errors && (
                <div className="row">
                  <div className="col-lg-12">
                    <ul className="text-danger">
                      <li>{errors}</li>
                    </ul>
                  </div>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    onChange={e => setEmailParam(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={e => setPasswordParam(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>
                <div className="form-group custom-control custom-checkbox mr-sm-2">
                  <input type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                  <label className="custom-control-label" htmlFor="customControlAutosizing">Remember me</label>
                </div>
                <button type="submit" className="btn btn-primary">Login</button>
              </form>
              <p className="mt-2 mb-0">Don't have an account? <a href="sign-up"> Create one here</a></p>
              <a href="forgot-password">Forgot password?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;

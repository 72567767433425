import {useParams} from "react-router-dom";
import InnerHeader from '../components/InnerHeader';
import LecturerDetails from '../faculty/LecturerDetails';
import Lecturer from "../academics/Lecturer";
import imgBg from '../images/bg/bg_academics.png'
import { lecturers } from "../faculty/FacultyInfo";
import { lecturerIds} from "../faculty/FacultyInfo"

function FacultyDetails() {
  const { id } = useParams();
  let lecturer = lecturers[id]
  return (
    <>
      <InnerHeader backgroundImage={imgBg} customName="SBU Faculty" />
      <section className="space-pt">
        <div className="container">
          <LecturerDetails name={lecturer.name} image={lecturer.image} possition={lecturer.possition} phone={lecturer.phone} 
          email={lecturer.email} address={lecturer.address} intro={lecturer.intro} qualifications={lecturer.qualifications} publications={lecturer.publications} />
        </div>
      </section>
      

      <section className="space-ptb bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 text-center">
              <div className="section-title">
                <h2>Meet Our SBU Professors</h2>
                <p>Meet the outstanding team of professionals</p>
              </div>
            </div>
          </div>
          <div className="row mb-xl-4">
            {
              lecturerIds.map(lid => (
                  <Lecturer img={lecturers[lid].image} name={lecturers[lid].name} urlDetails={"../faculty-details/" + lid} />
              ))
            }
          </div>
        </div>
      </section>
    </>
  );
}
  
  export default FacultyDetails;
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import SwiperAnimation from '@cycjimmy/swiper-animation';

function swiper() {
  var swiperslider = document.getElementsByClassName("swiper-container");
  if (swiperslider.length > 0) {
    var swiperAnimation = new SwiperAnimation();
    var swiper = new Swiper(".swiper-container", {
      modules: [Navigation, Pagination],
      init : true,
      direction: "horizontal",
      effect: "slide",
      loop: true,
      keyboard: {
        enabled: true,
        onlyInViewport: true
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        init: function() {
          swiperAnimation.init(this).animate();
        },
        slideChange: function() {
          swiperAnimation.init(this).animate();
        }
      }
    });
  }
}

export default swiper;

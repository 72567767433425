import UpcomingEvent from "./UpcomingEvent";
import Post from "./Post";
import img1 from '../images/events/01.jpg';
import img2 from '../images/events/02.jpg';
import img3 from '../images/events/03.jpg';
import {postData} from '../data/postsData';
import {useEffect, useState} from "react";

function Event() {
    const [nextSaturday, setNextSaturday] = useState({date: '', month: ''});

    useEffect(() => {
        const getNextSaturday = () => {
            const today = new Date();
            let day = today.getDay();
            const nextSaturday = new Date();

            // Calculate the next Saturday
            nextSaturday.setDate(today.getDate() + (6 - day));

            // Format the date
            const date = nextSaturday.getDate();
            const month = nextSaturday.toLocaleString('default', {month: 'short'});

            return {date, month};
        };

        setNextSaturday(getNextSaturday());
    }, []);

    const firstThreePosts = postData.slice(0, 3);
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-8 mb-4 mb-lg-0">
                    <div className="section-title mb-0">
                        <h2 className="title d-flex justify-content-center justify-content-lg-start">News</h2>
                        <div className="events">
                            {firstThreePosts.map(post => (
                                <Post
                                    key={post.id}
                                    id={post.id}
                                    header={post.header}
                                    image={post.image}
                                    content={post.content}
                                    date={post.date}
                                />
                            ))}
                        </div>
                    </div>
                    <div
                        className="text-md-right mb-4 mt-3 mb-md-0 d-flex justify-content-center justify-content-lg-start" >
                        <a className="btn btn-primary btn-round" style={{ width: '130px'}} href="/all-news">All News</a>
                    </div>
                </div>
                <div className="col-lg-4 mb-4 mb-lg-0">
                    <div className="section-title mb-0">
                        <h2 className="title d-flex justify-content-center justify-content-lg-end">Events</h2>
                        <UpcomingEvent
                            date={nextSaturday.date}
                            month={nextSaturday.month}
                            title="Mindfulness Class"
                            time="10:00 AM to noon PST"
                            header=""
                            dateClass="event-date"
                            monthClass="event-month"
                        />
                        <UpcomingEvent
                            date="12"
                            month="Feb"
                            title="Exam"
                            time="2:00 PM - 4:00 PM"
                            header=""
                            dateClass="event-date"
                            monthClass="event-month"
                        />
                        <UpcomingEvent
                            date="14"
                            month="Mar"
                            title="Enrollment"
                            time="All day"
                            header=""
                            dateClass="event-date"
                            monthClass="event-month"
                        />
                    </div>
                    <div
                        className="text-md-right mb-4 mt-3 mb-md-0 d-flex justify-content-center justify-content-lg-end"
                        style={{position: 'absolute', bottom: 0, width: '100%'}}>
                        <a className="btn btn-primary btn-round" style={{ width: '130px'}} href="/all-events">All Events</a>
                    </div>

                </div>
            </div>
        </div>

    )
        ;
}

export default Event;

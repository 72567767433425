import { useEffect } from 'react';
import { shuffle } from './animation';

import img1 from '../images/courses/01.jpg';
import img2 from '../images/courses/02.jpg';
import img3 from '../images/courses/03.jpg';
import img4 from '../images/courses/04.jpg';
import img5 from '../images/courses/05.jpg';
import img6 from '../images/courses/06.jpg';
import avatar1 from '../images/avatar/01.jpg';
import avatar2 from '../images/avatar/02.jpg';
import avatar3 from '../images/avatar/03.jpg';
import avatar4 from '../images/avatar/04.jpg';
import avatar5 from '../images/avatar/05.jpg';
import avatar6 from '../images/avatar/06.jpg';

function Courses() {
  useEffect(() => {
    shuffle();
  }, []);

  return (
    <section className="space-ptb">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="section-title">
              <h2 className="title">Our Popular Courses</h2>
            </div>
          </div>
          <div className="col-sm-4 text-md-right mb-4 mb-sm-0">
            <a className="btn btn-primary" href="/">All Courses</a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="filters-group mb-lg-4">
              <button className="btn-filter  active" data-group="all">All</button>
              <button className="btn-filter" data-group="branding">Courses</button>
              <button className="btn-filter" data-group="web">Events</button>
              <button className="btn-filter" data-group="photography">Students</button>
              <button className="btn-filter" data-group="advertising">Teachers</button>
            </div>
            <div className="my-shuffle-container columns-3 popup-gallery full-screen">
              <div className="grid-item" data-groups='["branding"]'>
                <div className="course-item">
                  <div className="coures-img">
                    <img className="img-fluid" src={img1} alt="" />
                    <div className="course-tag">
                      <a href="/">Business</a>
                    </div>
                  </div>
                  <div className="course-conten">
                    <a href="/" className="course-author d-flex align-items-center mb-3">
                      <img className="avatar img-fluid" src={avatar1} alt="" />
                      <span className="author-name">Alice Williams</span>
                    </a>
                    <h5 className="mb-3">
                      <a href="course-detail.html">Aeronautical & Manufacturing Engineering</a>
                    </h5>
                    <div className="course-rating mb-3 pb-3 border-bottom">
                      <div className="review-rating">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </div>
                    <div className="course-meta">
                      <ul className="list-unstyled mb-0">
                        <li><i className="fa fa-user pr-2"></i>12 students</li>
                        <li><i className="fa fa-book-open pr-2"></i>08 lessons</li>
                        <li className="price"><span>$100</span> $50</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid-item" data-groups='["web", "photography", "branding"]'>
                <div className="course-item">
                  <div className="coures-img">
                    <img className="img-fluid" src={img2} alt="" />
                    <div className="course-tag">
                      <a href="/">Politics</a>
                    </div>
                  </div>
                  <div className="course-conten">
                    <a href="/" className="course-author d-flex align-items-center mb-3">
                      <img className="avatar img-fluid" src={avatar4} alt="" />
                      <span className="author-name">Mellissa Doe</span>
                    </a>
                    <h5 className="mb-3">
                      <a href="course-detail.html">Business & Management Studies</a>
                    </h5>
                    <div className="course-rating mb-3 pb-3 border-bottom">
                      <div className="review-rating">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </div>
                    <div className="course-meta">
                      <ul className="list-unstyled mb-0">
                        <li><i className="fa fa-user pr-2"></i>08 students</li>
                        <li><i className="fa fa-book-open pr-2"></i>04 lessons</li>
                        <li className="price"><span>$80</span> $40</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid-item" data-groups='["photography"]'>
                <div className="course-item">
                  <div className="coures-img">
                    <img className="img-fluid" src={img3} alt="" />
                    <div className="course-tag">
                      <a href="/">Psychology</a>
                    </div>
                  </div>
                  <div className="course-conten">
                    <a href="/" className="course-author d-flex align-items-center mb-3">
                      <img className="avatar img-fluid" src={avatar2} alt="" />
                      <span className="author-name">Paul Flavius</span>
                    </a>
                    <h5 className="mb-3">
                      <a href="course-detail.html">History of Art, Architecture & Design</a>
                    </h5>
                    <div className="course-rating mb-3 pb-3 border-bottom">
                      <div className="review-rating">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </div>
                    <div className="course-meta">
                      <ul className="list-unstyled mb-0">
                        <li><i className="fa fa-user pr-2"></i>16 students</li>
                        <li><i className="fa fa-book-open pr-2"></i>10 lessons</li>
                        <li className="price"><span>$150</span> $80</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid-item" data-groups='["advertising"]'>
                <div className="course-item">
                  <div className="coures-img">
                    <img className="img-fluid" src={img5} alt="" />
                    <div className="course-tag">
                      <a href="/">Medicine</a>
                    </div>
                  </div>
                  <div className="course-conten">
                    <a href="/" className="course-author d-flex align-items-center mb-3">
                      <img className="avatar img-fluid" src={avatar5} alt="" />
                      <span className="author-name">Felica Queen</span>
                    </a>
                    <h5 className="mb-3">
                      <a href="course-detail.html">Communication & Media Studies</a>
                    </h5>
                    <div className="course-rating mb-3 pb-3 border-bottom">
                      <div className="review-rating">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </div>
                    <div className="course-meta">
                      <ul className="list-unstyled mb-0">
                        <li><i className="fa fa-user pr-2"></i>21 students</li>
                        <li><i className="fa fa-book-open pr-2"></i>09 lessons</li>
                        <li className="price"><span>$180</span> $90</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid-item" data-groups='["design", "advertising"]'>
                <div className="course-item">
                  <div className="coures-img">
                    <img className="img-fluid" src={img4} alt="" />
                    <div className="course-tag">
                      <a href="/">Architecture</a>
                    </div>
                  </div>
                  <div className="course-conten">
                    <a href="/" className="course-author d-flex align-items-center mb-3">
                      <img className="avatar img-fluid" src={avatar3} alt="" />
                      <span className="author-name">Michael Bean</span>
                    </a>
                    <h5 className="mb-3">
                      <a href="course-detail.html">Geography & Environmental Science</a>
                    </h5>
                    <div className="course-rating mb-3 pb-3 border-bottom">
                      <div className="review-rating">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </div>
                    <div className="course-meta">
                      <ul className="list-unstyled mb-0">
                        <li><i className="fa fa-user pr-2"></i>25 students</li>
                        <li><i className="fa fa-book-open pr-2"></i>15 lessons</li>
                        <li className="price"><span>$300</span> $200</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid-item" data-groups='["branding", "web"]'>
                <div className="course-item">
                  <div className="coures-img">
                    <img className="img-fluid" src={img6} alt="" />
                    <div className="course-tag">
                      <a href="/">Biological</a>
                    </div>
                  </div>
                  <div className="course-conten">
                    <a href="/" className="course-author d-flex align-items-center mb-3">
                      <img className="avatar img-fluid" src={avatar6} alt="" />
                      <span className="author-name">Sara Lisbon</span>
                    </a>
                    <h5 className="mb-3">
                      <a href="course-detail.html">Electrical & Electronic Engineering</a>
                    </h5>
                    <div className="course-rating mb-3 pb-3 border-bottom">
                      <div className="review-rating">
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star text-warning"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </div>
                    <div className="course-meta">
                      <ul className="list-unstyled mb-0">
                        <li><i className="fa fa-user pr-2"></i>35 students</li>
                        <li><i className="fa fa-book-open pr-2"></i>17 lessons</li>
                        <li className="price"><span>$500</span> $280</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Courses;

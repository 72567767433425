export default function PopularCourses() {
	return (
		<section className="space-ptb bg-dark">
      <div className="container">
        <div className="row mb-3 mb-md-0">
          <div className="col-md-8">
            <div className="section-title">
              <h2 className="title">Our Popular Courses</h2>
            </div>
          </div>
          <div className="col-md-4 text-md-right">
            <a className="btn btn-primary" href="/">All Courses</a>
          </div>
        </div>
        <div className="row popular-courses">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span>Economics & Finance</span>01 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div className="card-body">
                    <a href="/">Business & Management Studies</a>
                    <p className="text-dark">$ 15,800 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                      <span>Engineering & Technology</span>02 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                  <div className="card-body">
                    <a href="/">Aeronautical & Manufacturing Engineering</a>
                    <p className="text-dark">$ 12,700 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                      <span>Sciences & Medicine</span>03 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                  <div className="card-body">
                    <a href="/">Hospitality, Leisure, Recreation & Tourism</a>
                    <p className="text-dark">$ 20,500 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFour">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                      <span>Natural Sciences</span>04 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                  <div className="card-body">
                    <a href="/">Geography & Environmental Science</a>
                    <p className="text-dark">$ 22,000 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFive">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                      <span>Management</span>05 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                  <div className="card-body">
                    <a href="/">Business & Management Studies</a>
                    <p className="text-dark">$ 30,400 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
              <div className="card mb-0">
                <div className="card-header" id="headingSix">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                      <span>Masters in Accounting</span>06 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                  <div className="card-body">
                    <a href="/">Drama, Dance & Cinematics</a>
                    <p className="text-dark">$ 10,500 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="accordion" id="accordionExample2">
              <div className="card">
                <div className="card-header" id="headingSaven">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseSaven" aria-expanded="true" aria-controls="collapseSaven">
                      <span>Sciences & Medicine</span>07 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseSaven" className="collapse" aria-labelledby="headingSaven" data-parent="#accordionExample2">
                  <div className="card-body">
                    <a href="/">History of Art, Architecture & Design</a>
                    <p className="text-dark">$ 24,800 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
              <div className="card">
               <div className="card-header" id="headingEight">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                      <span>Computer Science</span>08 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample2">
                  <div className="card-body">
                    <a href="/">Theology & Religious Studies</a>
                    <p className="text-dark">$ 16,200 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingNine">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                      <span>Electrical Engineering</span>09 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionExample2">
                  <div className="card-body">
                    <a href="/">Electrical & Electronic Engineering</a>
                    <p className="text-dark">$ 12,300 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTen">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                      <span>Mechanical Engineering</span>10 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordionExample2">
                  <div className="card-body">
                    <a href="/">Electrical & Electronic Engineering</a>
                    <p className="text-dark">$ 17,800 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingElevan">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapseElevan" aria-expanded="true" aria-controls="collapseElevan">
                      <span>Biological Sciences</span>11 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapseElevan" className="collapse" aria-labelledby="headingElevan" data-parent="#accordionExample2">
                  <div className="card-body">
                    <a href="/">Theology & Religious Studies</a>
                    <p className="text-dark">$ 15,800 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
              <div className="card mb-0">
                <div className="card-header" id="headingtwelve">
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#collapsetwelve" aria-expanded="true" aria-controls="collapsetwelve">
                      <span>Medicine</span>12 Courses
                    </button>
                  </h2>
                </div>
                <div id="collapsetwelve" className="collapse" aria-labelledby="headingtwelve" data-parent="#accordionExample2">
                  <div className="card-body">
                    <a href="/">Hospitality, Leisure, Recreation & Tourism</a>
                    <p className="text-dark">$ 15,500 Fee / Yearly | 4 Years</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
	);
}

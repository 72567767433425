
function CourseCatalog() {
    return (
      <>
          <div className="my-sm-3 text-justify">
              <h4 className="mb-4">2025-2026 Courses</h4>
              <h6 className="text-left">Master of Arts in Buddhist Philosophy</h6>
              <p>The Master of Arts in Buddhist Philosophy degree offers an in-depth exploration of Buddhist thought, ethics, and
practices, combining rigorous academic study with personal spiritual growth. The program delves into core
Buddhist teachings, historical development, comparative philosophy, and contemporary applications of Buddhist
principles in areas such as mindfulness, social ethics, and interfaith dialogue. Students engage with ancient texts
and modern interpretations, fostering critical thinking and cross-cultural understanding. Designed for scholars,
practitioners, and professionals, the degree equips graduates with a profound knowledge of Buddhist
philosophy, enabling them to contribute meaningfully to academic, spiritual, and community contexts worldwide.</p>
			  <p><strong>Program Requirements</strong></p>
			  <p>To successfully complete the Master of Arts in Buddhist Philosophy, students must fulfill the following
requirements:</p>
			  <ol>
			    <li>Admission into the Master of Art in Buddhist Philosophy program</li>
			    <li>Consistent progress and advancement to preliminary candidacy</li>
			    <li>A minimum of 56 graduate-level credit hours as outlined below:
			    	<ul>
			    		<li>Fourteen (14) core courses (44 units)</li>
			    		<li>Four (4) research courses (12 units)</li>
			    	</ul>
			    </li>
			    <li>All students must reside for a minimum of four (4) semesters</li>
			    <li>Proficiency requirement in a modern research language</li>
			    <li>Successful oral defense of the thesis</li>
			    <li>Maintaining a minimum GPA of 3.0</li>
			  </ol>

			  <p><strong>Coursework Overview</strong></p>
			  <p>Required Courses (44 units)</p>
              <ul>
                  <li>BPHIL 515 - Introduction to Buddhist Philosophy (3 units)</li>
                  <li>BPHIL 530 - Comparative Religions and Philosophy (3 units)</li>
                  <li>BPHIL 570 - Philosophical Discussions of Buddhism (3 units)</li>
                  <li>PSYC 580 - Buddhist Psychology (3 units)</li>
                  <li>BMED 525 - Buddhist Meditation (3 units)</li>
                  <li>BMED 595 - Applied Buddhism in Society (5 units)</li>
                  <li>BWMN 575 - Women in Buddhism (3 units)</li>
                  <li>COMM 500 - Intercultural Communication (3 units)</li>
                  <li>COMM 510 - Nonverbal Communication Across Cultures (3 units)</li>
                  <li>BUDS 595 - Intro Buddhism and Social Issues (3 units)</li>
                  <li>BTEX 540 - Reading the Buddha�s Words (3 units)</li>
                  <li>BTEX 545 - Buddhist Scripture Translations in the West (3 units)</li>
                  <li>BTEX 560 - Sacred Texts in Buddhism (3 units)</li>
                  <li>BTEX 590 - Pali (3 units)</li>
              </ul>
              <p>Required Research Methodology Courses (12 units)</p>
              <ul>
                  <li>RESM 500 - Academic Writing (3 units)</li>
                  <li>RESM 590 - Research Methodology (3 units)</li>
                  <li>RESM 59 - Research Methodology 2 (3 units)</li>
                  <li>CAPS 599 - Capstone Thesis Project (3 units)</li>
              </ul>
              <h6 className="text-left">Master of Arts in Buddhist Counseling Psychology</h6>
              <p>The Master in Buddhist Counseling Psychology focuses on the intersection between Buddhist psychology and
Western multicultural counseling in helping individuals, couples, families, and communities. The program is
aligned to the requirements for Licensed Professional Clinical Counselor (LPCC) and Licensed Marriage and
Family Therapist (LMFT).</p>
              <p><strong>Program Requirements</strong></p>
              <p>To successfully complete the Master of Arts in Buddhist Counseling Psychology, students must fulfill the
following requirements:</p>
              <ol>
                  <li>Admission into the Master in Buddhist Counseling Psychology program</li>
                  <li>Consistent progress</li>
                  <li>A minimum of 63 graduate-level units as outlined below:
					<ul>
                      <li>One (1) Introduction course (3 units)</li>
                      <li>Six (6) Foundational courses (18 units)</li>
                      <li>Six (6) Core courses (18 units)</li>
                      <li>Six (6) Advance courses (18 units)</li>
                      <li>Two (2) Clinical education courses (6 units)</li>
                    </ul>
                  </li>
                  <li>All students must reside for a minimum of six (6) semesters</li>
                  <li>Maintaining a minimum GPA of 3.0</li>
                  <li>Completion of Thesis or Comprehensive examination</li>
              </ol>
              
              <p><strong>Coursework Overview</strong></p>
              <p>Required courses (60 units)</p>
              <ul>
                <li>BPHIL 515 - Introduction to Buddhist Philosophy (3 units)</li>
                <li>BPHIL 530 - Comparative Religions and Philosophy (3 units)</li>
                <li>BMED 525 - Buddhist Meditation (3 units)</li>
                <li>COMM 500 - Intercultural Communication (3 units)</li>
                <li>COMM 510 - Nonverbal Communication Across Cultures (3 units)</li>
                <li>CNSEL 620 - Group Psychotherapy (3 units)</li>
                <li>CNSEL 625 - Counseling Families (3 units)</li>
                <li>CNSEL 630 - Counseling Adolescents (3 units)</li>
                <li>CNSEL 640 - Counseling Across the Lifespan (3 units)</li>
                <li>CNSEL 650 - Counseling Couples (3 units)</li>
                <li>ETHS 510 - Law and Ethics (3 units)</li>
                <li>BTEX 545 - Buddhist Scripture Translations in the West (3 units)</li>
                <li>PRAC 540 - Practicum (3 units)</li>
                <li>PRAC 660 - Advanced Practicum (3 units)</li>
                <li>PSYC 580 - Buddhist Psychology (3 units)</li>
                <li>PSYC 520 - Psychopathology (3 units)</li>
                <li>PSYC 630 - Trauma and Resiliency (3 units)</li>
                <li>PSYC 610 - Psychological Testing (3 units)</li>
                <li>PSYC 640 - Psychopharmacology (3 units)</li>
                <li>STATA 530 - Experimental Design and Statistical Analysis (3 units)</li>
              </ul>
              <p>Required Research Methodology Courses (3 units)</p>
              <ul>
                <li>RESM 500 - Academic Writing (3 units)</li>
              </ul>

              <h6 className="text-left">Master of Divinity in Buddhist Chaplaincy</h6>
              <p>A Master of Divinity in Buddhist Chaplaincy at SBU is a graduate degree program designed to train individuals in
providing spiritual care and guidance rooted in Buddhist principles. This program typically combines academic
study of Buddhist texts, philosophy, and ethics with practical training in pastoral care, counseling, and interfaith
communication. Students learn to offer emotional, psychological, and spiritual support in various settings, such
as hospitals, military, prisons, and educational institutions, while applying mindfulness, compassion, and
meditation as part of their chaplaincy practice. The program prepares graduates to serve as professional
chaplains, offering comfort and wisdom within a diverse range of communities and spiritual needs.</p>
			  <p><strong>Program Requirements</strong></p>
			  <p>To successfully complete the Master of Divinity in Buddhist Chaplaincy, students must fulfill the following
requirements:</p>
			  <ol>
			  	<li>Admission into the Master of Divinity in Buddhist Chaplaincy program</li>
			  	<li>Consistent progress</li>
			  	<li>A minimum of 72 graduate-level credit hours as outlined below:
			  		<ul>
			  			<li>Thirty-one (31) core courses (69 units)</li>
			  			<li>One (1) research methodology course (3 units)</li>
			  		</ul>
			  	</li>
			    <li>All students must reside for a minimum of six (6) semesters</li>
			    <li>Successful oral defense of the thesis</li>
			    <li>Maintaining a minimum GPA of 3.0</li>
			  </ol>
			  <p><strong>Coursework Overview</strong></p>
			  <p>Required Courses (69 units)</p>
			  <ul>
			  	<li>BPHIL 515 - Introduction to Buddhist Philosophy (3 units)</li>
				<li>BPHIL 530 - Comparative Religions and Philosophy (3 units)</li>
				<li>BPHIL 570 - Philosophical Discussions of Buddhism (3 units)</li>
				<li>BUDS 595 - Intro Buddhism and Social Issues (3 units)</li>
				<li>BWMN 575 - Women in Buddhism (3 units)</li>
				<li>COMM 500 - Intercultural Communication (3 units)</li>
				<li>COMM 510 - Nonverbal Communication Across Cultures (3 units)</li>
				<li>CNSEL 507 - Chaplaincy Roles and Competencies (3 units)</li>
				<li>CNSEL 541- Spiritual Care and Counseling (3 units)</li>
				<li>BMED 525 - Buddhist Meditation (3 units)</li>
				<li>BTEX 540 - Reading the Buddha�s Words (3 units)</li>
				<li>BTEX 545 - Buddhist Scripture Translations in the West (3 units)</li>
				<li>BTEX 560 - Sacred Texts in Buddhism (3 units)</li>
				<li>BTEX 562 - Sacred Texts in Buddhism 2 (3 units)</li>
				<li>BUDS 620 - Spiritual Formation (3 units)</li>
				<li>BUDS 630 - Dharma Talk and Buddhist Rituals (3 units)</li>
				<li>BUDS 640 - Spiritual Leadership (3 units)</li>
				<li>BUDS 650 - Spiritual Pastoral Theology (3 units)</li>
				<li>PRAC 531 to PRAC 536 - Service-learning 1 to 6 (6 units; 1 unit per class)</li>
				<li>PSYC 580 - Buddhist Psychology (3 units)</li>
				<li>PSYC 521 to PSYC 526 - Group Process 1 to 6 (6 units; 1 unit per class)</li>
			  </ul>
			  <p>Required Research Methodology Course (3 units)</p>
			  <ul>
			  	<li>RESM 500 - Academic Writing (3 units)</li>
			  </ul>
          </div>
      </>
    );
}

export default CourseCatalog;
import React from 'react';

function Mission() {
    return (
        <>

            <h4 className="title text-center mb-3" id='mission'>STATEMENT OF MISSION</h4>
            <p className="text-justify">The Sakya Buddhist University aims to deliver a comprehensive education deeply rooted in Buddhist
                principles while nurturing cultural appreciation and bridging the gap between East and West. Our
                fundamental values and mission are:
            </p>
            <ul className="text-justify">
                <li style={{marginBottom: '1rem'}}>Holistic Education: We merge intellectual, spiritual, emotional, and creative components
                    to offer a well-rounded learning environment that celebrates individual uniqueness and
                    encourages personal academic paths.
                </li>
                <li style={{marginBottom: '1rem'}}>Foundation in Liberal Arts: Grounded in Buddhist wisdom, our robust liberal arts curriculum cultivates a global outlook, empowering graduates to thrive in contemporary society.
                </li>
                <li style={{marginBottom: '1rem'}}>Embrace of Diversity: Diversity is a gateway to wisdom and comprehension, fostering inclusivity and instilling compassion and service toward others.
                </li>
                <li style={{marginBottom: '1rem'}}>Cultural Interaction: We promote and facilitate intellectual and cultural exchanges between Eastern and Western traditions.
                </li>
                <li style={{marginBottom: '1rem'}}>Character Cultivation: Our focus is on nurturing individuals of strong moral character who lead with a sense of communal well-being, guiding self-discovery and purposeful living.
                </li>
                <li style={{marginBottom: '1rem'}}>Spiritual Awareness: Through contemplative education, we guide students in exploring their purpose and inner meaning, fostering spiritual growth and self-awareness.
                </li>
                <li style={{marginBottom: '1rem'}}>Collaborative Learning: Education is a shared conversation between students and faculty, fostering mutual learning and development.
                </li>
                <li style={{marginBottom: '1rem'}}>Engaged Global Citizenship: We encourage a compassionate, creative, and critical approach to an ever-changing world, emphasizing the discipline of contemplative education.
                </li>
                <li style={{marginBottom: '1rem'}}>Commitment to a Global Community: Our vision is of an interconnected global community that champions personal and collective actions for individual, societal, and ecological prosperity.
                </li>
                <li style={{marginBottom: '1rem'}}>Advocacy for a Just World: With a focus on insight, awareness, and compassion, we promote critical thinking and active involvement, equipping individuals with the skills to authentically contribute to meaningful change through the teachings of Buddha.
                </li>
            </ul>
            <p className="text-justify">These principles guide us in cultivating an environment where students can authentically engage in and contribute to a more just and compassionate world.
            </p>
        </>
    )
        ;
}

export default Mission;

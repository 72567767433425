function AcademicCalendar() {
  return (
    <>
      <div className="my-sm-3 text-justify">
        <h4 className="mb-4">Sakya Buddha University Academic Calendar 2025–2026</h4>
        <h6>FALL SEMESTER 2025: AUGUST 2025 – DECEMBER 2025</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, August 18</div>
          <div className="col-md-8">Graduate Program Orientation for new students</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, August 25</div>
          <div className="col-md-8">First Day of Fall Semester Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Sunday, September 7</div>
          <div className="col-md-8">Last Day of the Add/Drop Period; last day for 100% refund</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, September 8</div>
          <div className="col-md-8">Withdrawal Period begins</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, October 20</div>
          <div className="col-md-8">Last day to withdraw with a grade of W; refund percentage applies</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, November 3 – Friday, December 5</div>
          <div className="col-md-8">Student registration for Spring and Summer</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, November 24 – Friday, November 28</div>
          <div className="col-md-8">Thanksgiving Holiday; Fall Break; No Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Saturday, December 13</div>
          <div className="col-md-8">Last Day of Fall Semester Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, December 15 – Saturday, December 20</div>
          <div className="col-md-8">Final exams</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, January 2</div>
          <div className="col-md-8">Fall Semester Grades due</div>
        </div>
        <br/>

        <h6>WINTER SESSION 2025: DECEMBER 2025 – JANUARY 2026</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, December 22</div>
          <div className="col-md-8">First Day of Winter Session Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, December 29</div>
          <div className="col-md-8">Last Day of the Add/Drop Period and Winter Session Withdrawal Period</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Thursday, December 25, 2025 - Thursday, January 1, 2026</div>
          <div className="col-md-8">Winter Break; No Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Saturday, January 17</div>
          <div className="col-md-8">Last Day of Winter Session Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, January 23</div>
          <div className="col-md-8">Winter Session Grades due</div>
        </div>

        <br/>
        <h6>SPRING SEMESTER 2026: JANUARY 2026 – MAY 2026</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, January 12</div>
          <div className="col-md-8">Spring Semester begins</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, January 19</div>
          <div className="col-md-8">First Day of Spring Semester Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Sunday, February 1</div>
          <div className="col-md-8">Last Day of the Add/Drop Period; last day for 100% refund</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, February 2</div>
          <div className="col-md-8">Withdrawal Period begins</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Tuesday, February 17</div>
          <div className="col-md-8">Lunar New Year Holiday; No Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, March 16</div>
          <div className="col-md-8">Last Day to withdraw with a grade of W; refund percentage applies</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, March 30 – Saturday, April 4</div>
          <div className="col-md-8">Spring Break; No Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, April 6 – Friday, May 1</div>
          <div className="col-md-8">Student registration for Summer and Fall</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Saturday, May 9</div>
          <div className="col-md-8">Last Day of Spring Semester Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, May 11 – Saturday, May 16</div>
          <div className="col-md-8">Final exams</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, May 22</div>
          <div className="col-md-8">Spring Semester Grades due</div>
        </div>
        <br/>

        <h6>SUMMER SESSION 2026: MAY 2026 – AUGUST 2026</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, May 25</div>
          <div className="col-md-8">First Day of Summer Session Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, June 1</div>
          <div className="col-md-8">Last Day of the Add/Drop Period; last day for 100% refund</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, June 22</div>
          <div className="col-md-8">Last Day to withdraw with a grade of W; refund percentage applies</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Saturday, July 4</div>
          <div className="col-md-8">Independence Day; No Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Saturday, August 1</div>
          <div className="col-md-8">Last Day of Summer Session Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, August 7</div>
          <div className="col-md-8">Summer Session Grades due</div>
        </div>

        <br/>
        <h6>FALL SEMESTER 2026: AUGUST 2026 – DECEMBER 2026</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, August 17</div>
          <div className="col-md-8">Graduate Program Orientation for new students</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, August 24</div>
          <div className="col-md-8">First Day of Fall Semester Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Sunday, September 6</div>
          <div className="col-md-8">Last Day of the Add/Drop Period; last day for 100% refund</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, September 7</div>
          <div className="col-md-8">Withdrawal Period begins</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, October 19</div>
          <div className="col-md-8">Last Day to withdraw with a grade of W; refund percentage applies</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, November 2 – Friday, December 6</div>
          <div className="col-md-8">Student registration for Spring and Summer</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, November 23 – Friday, November 27</div>
          <div className="col-md-8">Thanksgiving Holiday; Fall Break; No Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Saturday, December 12</div>
          <div className="col-md-8">Last Day of Fall Semester Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, December 14 – Saturday, December 19</div>
          <div className="col-md-8">Final exams</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, January 4, 2027</div>
          <div className="col-md-8">Fall Semester Grades due</div>
        </div>

        <br/>
        <h6>WINTER SESSION 2026: DECEMBER 2026 – JANUARY 2027</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, December 21</div>
          <div className="col-md-8">First Day of Winter Session Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, December 28</div>
          <div className="col-md-8">Last Day of the Add/Drop Period and Winter Session Withdrawal Period</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, December 25, 2026 – Friday, January 1, 2027</div>
          <div className="col-md-8">Winter Break; No Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Saturday, January 16</div>
          <div className="col-md-8">Last Day of Winter Session Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, January 22</div>
          <div className="col-md-8">Winter Session Grades due</div>
        </div>

        <br/>
        <h6>SPRING SEMESTER 2027: JANUARY 2027 – MAY 2027</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, January 11</div>
          <div className="col-md-8">Spring Semester begins</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, January 18</div>
          <div className="col-md-8">First Day of Spring Semester Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Sunday, January 31</div>
          <div className="col-md-8">Last Day of the Add/Drop Period; last day for 100%refund</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, February 1</div>
          <div className="col-md-8">Withdrawal Period begins</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Saturday, February 6</div>
          <div className="col-md-8">Lunar New Year Holiday; No Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, March 15</div>
          <div className="col-md-8">Last Day to withdraw with a grade of W; refund percentage applies</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, March 29 – Saturday, April 3</div>
          <div className="col-md-8">Spring Break; No Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, April 5 – Friday, May 7</div>
          <div className="col-md-8">Student registration for Summer and Fall</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Saturday, May 8</div>
          <div className="col-md-8">Last Day of Spring Semester Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, May 10 – Saturday, May 15</div>
          <div className="col-md-8">Final exams</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, May 21</div>
          <div className="col-md-8">Spring Semester Grades due</div>
        </div>

        <br/>
        <h6>SUMMER SESSION 2027: MAY 2027 – AUGUST 2027</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, May 24</div>
          <div className="col-md-8">First Day of Summer Session Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, May 31</div>
          <div className="col-md-8">Last Day of the Add/Drop Period and Winter Session Withdrawal Period</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, June 21</div>
          <div className="col-md-8">Last Day to withdraw with a grade of W; refund percentage applies</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Sunday, July 4</div>
          <div className="col-md-8">Independence Day; No Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Saturday, July 31</div>
          <div className="col-md-8">Last Day of Summer Session Classes</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, August 6</div>
          <div className="col-md-8">Summer Session Grades due</div>
        </div>
      </div>
    </>

  );
}

export default AcademicCalendar;
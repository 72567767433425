import InnerHeader from '../components/InnerHeader';
import Clients from '../components/Clients';
import Course from '../courses/Course';
import PopularCourses from '../courses/PopularCourses';
import img1 from '../images/courses/01.jpg';
import img2 from '../images/courses/02.jpg';
import img3 from '../images/courses/03.jpg';
import img4 from '../images/courses/04.jpg';
import img5 from '../images/courses/05.jpg';
import img6 from '../images/courses/06.jpg';
import avatar1 from '../images/avatar/01.jpg';
import avatar2 from '../images/avatar/02.jpg';
import avatar3 from '../images/avatar/03.jpg';
import avatar4 from '../images/avatar/04.jpg';
import avatar5 from '../images/avatar/05.jpg';
import avatar6 from '../images/avatar/06.jpg';

const courses = [
  {
    image: img1,
    avatar: avatar1,
    major: "Business",
    teacher: "Alice Williams",
    name: "Aeronautical & Manufacturing Engineering",
    studentCount: 12,
    lessonCount: 8,
    price: 100,
    discountedPrice: 50,
  },
  {
    image: img2,
    avatar: avatar4,
    major: "Politics",
    teacher: "Mellissa Doe",
    name: "Business & Management Studies",
    studentCount: 8,
    lessonCount: 4,
    price: 80,
    discountedPrice: 40,
  },
  {
    image: img3,
    avatar: avatar2,
    major: "Psychology",
    teacher: "Paul Flavius",
    name: "History of Art, Architecture & Design",
    studentCount: 16,
    lessonCount: 10,
    price: 150,
    discountedPrice: 80,
  },
  {
    image: img4,
    avatar: avatar5,
    major: "Medicine",
    teacher: "Felica Queen",
    name: "Communication & Media Studies",
    studentCount: 21,
    lessonCount: 9,
    price: 180,
    discountedPrice: 90,
  },
  {
    image: img5,
    avatar: avatar3,
    major: "Architecture",
    teacher: "Michael Bean",
    name: "Geography & Environmental Science",
    studentCount: 25,
    lessonCount: 15,
    price: 300,
    discountedPrice: 200,
  },
  {
    image: img6,
    avatar: avatar6,
    major: "Biological",
    teacher: "Sara Lisbon",
    name: "Electrical & Electronic Engineering",
    studentCount: 35,
    lessonCount: 17,
    price: 500,
    discountedPrice: 280,
  }
];

function Courses() {
  return (
    <>
      <InnerHeader />
      <section className="space-ptb">
        <div className="container">
          <div className="row">
            {courses.map(props => (
              <div className="col-lg-4 col-md-6 mb-4">
                <Course {...props} />
              </div>
            ))}
          </div>
          <div className="row justify-content-center mt-4 mt-md-5">
            <div className="col-lg-8 text-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center mb-0">
                  <li className="page-item"><a className="page-link rounded-left" href="/">Previous</a></li>
                  <li className="page-item active"><a className="page-link" href="/">1</a></li>
                  <li className="page-item"><a className="page-link" href="/">2</a></li>
                  <li className="page-item"><a className="page-link" href="/">3</a></li>
                  <li className="page-item"><a className="page-link rounded-right" href="/">Next</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <PopularCourses />
      <Clients />
    </>
  );
}

export default Courses;

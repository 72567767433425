const majors = [
	"",
	"Accounting",
	"Advertising",
	"Affective-Social Pscyhology",
	"Alternative Dispute Resolution",
	"American Indian Studies",
	"Anthropology",
	"Applied Communication",
	"Applied Computing",
	"Archaeology",
	"Architecture and Planning",
	"Archives &amp; Record Management",
	"Art",
	"Art Education, K-12",
	"Art History",
	"Art Museum Administration",
	"Art, Literature &amp; Culture",
	"Arts and Culture",
	"Arts and Literature",
	"Asian Studies",
	"Astrophysics",
	"Atmospheric Physics",
	"Audio Production",
	"Audiology",
	"Baritone",
	"Basic Communication",
	"Bass",
	"Bassoon",
	"Biochemistry",
	"Bioengineering",
	"Biological Sciences",
	"Business Administration",
	"Business Education",
	"Business Information Technology",
	"Business Intelligence",
	"Business Law",
	"Business Leadership",
	"Business Planning",
	"Cell and Molecular Biology",
	"Cello",
	"Ceramics",
	"Chemical Engineering",
	"Chemistry",
	"Child and Family Studies",
	"Chinese",
	"Choral",
	"Civic Leadership &amp; Development",
	"Civil Engineering",
	"Clarinet",
	"Classical Literature",
	"Classical Studies",
	"Clinical Psychology",
	"Cognitive Neuroscience",
	"Cognitive Psychology",
	"Cognitive Science",
	"College Student Development",
	"Commercial Music",
	"Communication",
	"Communication Studies",
	"Community Counseling",
	"Community Interpretation",
	"Comp Science Systems Engineering",
	"Comparative Literature",
	"Comparative Politics",
	"Composition",
	"Computer Engineering",
	"Computer Information Systems",
	"Computer Law",
	"Computer Programming",
	"Computer Science",
	"Computer Science and Engineering",
	"Concert Accordion",
	"Conducting",
	"Conflict Resolution",
	"Constitutional Law",
	"Construction Management",
	"Counseling Psychology",
	"Creative Writing",
	"Criminal Law",
	"Criminology",
	"Cultural Anthropology",
	"Curriculum and Instruction",
	"Customized Management",
	"Cybersecurity",
	"Dance",
	"Data Communications",
	"Data Mining",
	"Data Processing",
	"Data Warehousing and Business Intelligence",
	"Database Management",
	"Developmental Psychology",
	"Digital Media Studies",
	"Drawing",
	"Early Childhood School Psychology",
	"Early Childhood Special Education",
	"Economics",
	"Education",
	"Education Management",
	"Educational Psychology",
	"Electrical and Computer Engineering",
	"Electrical Engineering",
	"Electronic Commerce",
	"Electronic Media Arts Design",
	"Elementary Education",
	"Emergent Digital Practices",
	"Energy and Sustainability",
	"Energy Management",
	"Engineering",
	"Engineering Management",
	"Engineering Mechanics",
	"English",
	"English Education",
	"English Literature",
	"Entrepreneurial Studies",
	"Entrepreneurship and Venture Management",
	"Environmental Assessment of Nuclear Power",
	"Environmental Managment",
	"Environmental Policy",
	"Environmental Policy and Management",
	"Environmental Science",
	"Ethnology",
	"European Studies",
	"Executive MBA",
	"Family Law",
	"Family Services",
	"Family Therapy",
	"Feminist Jurisprudence",
	"Film",
	"Film Animation",
	"Film Studies and Production",
	"Finance",
	"Flute",
	"Forensic DNA Analysis",
	"Forensic Psychology",
	"French",
	"Gender and Women's Studies",
	"Geographic Information Systems",
	"Geography",
	"Geology",
	"German",
	"Gerontology",
	"Gifted and Talented Education",
	"Global Enterprise Planning",
	"Global Health Affairs",
	"Global Human Resources",
	"Global Studies",
	"Governance and Organization",
	"Graphic Communication Design",
	"Greek",
	"Guitar",
	"Harp",
	"Health Behavior and Education",
	"Health Care Management",
	"Health Care Organization and Systems",
	"Healthcare Ethics",
	"Healthcare Leadership",
	"Healthcare Management",
	"Hebrew",
	"Higher Education",
	"History",
	"Home Economics",
	"Horn",
	"Hospitality Management",
	"Hotel and Restaurant Management",
	"Hotel Finance and Investments",
	"Hotel, Restaurant and Tourism Management",
	"Human Capacity in Organizations",
	"Human Capital Organizations",
	"Human Communications",
	"Human Development and Family Studies",
	"Human Resource Administration",
	"Humanitarian Assistance",
	"Humanities",
	"Individually Structured",
	"Information Science and Technology",
	"Information Security",
	"Information Systems Management",
	"Information Systems Security",
	"Information Technology",
	"Insurance",
	"International Administration",
	"International Business",
	"International Communication",
	"International Development",
	"International Disaster Psychology",
	"International Economics",
	"International Human Rights",
	"International Law",
	"International Management",
	"International Markets",
	"International Policy",
	"International Politics",
	"International Studies",
	"Italian",
	"Japanese",
	"Japanese Business and Culture",
	"Jazz and Commercial Music",
	"Jazz Composition",
	"Jazz Performance",
	"Jazz Studies",
	"Jewish Communal Service",
	"Journalism Studies",
	"Judaic Studies",
	"Judicial Administration",
	"K-12 Art or Music",
	"Kinesiology",
	"Latin",
	"Latin-American Studies",
	"Law",
	"Law &amp; Economics",
	"Law Firm Administration",
	"Law Librarianship",
	"Leadership",
	"Leadership and Organizations",
	"Leadership Studies",
	"Legal Administration",
	"Legal Studies",
	"Liberal Arts",
	"Liberal Studies",
	"Library and Information Science",
	"Literary Studies",
	"Managed Healthcare",
	"Management",
	"Management and Communications",
	"Management and Engineering",
	"Managerial Accounting",
	"Marketing",
	"Marketing and Public Relations",
	"Mass Communications",
	"Master of Accountancy",
	"Materials Science",
	"Mathematics",
	"Mechanical Engineering",
	"Mechanical Science and Environmental",
	"Mechatronic Systems Engineering",
	"Media Film Journalism Studies",
	"Media Studies",
	"Modern Languages",
	"Modern Languages and Literature",
	"Molecular and Cellular Biophysics",
	"Molecular Biology",
	"Museum Studies",
	"Music",
	"Music - Piano Pedagogy",
	"Music Composition",
	"Music Pedagogy",
	"Music Theory",
	"Music Theory and Composition",
	"Musicology",
	"Nanoscale Science and Engineering",
	"Natural Resource Management",
	"Network Architecture",
	"Not-For-Profit Management",
	"Nursing",
	"Oboe",
	"Office Administration",
	"Orchestral Studies",
	"Organ",
	"Organizational Communications",
	"Organizational Security",
	"Other",
	"Painting",
	"Percussion",
	"Performance",
	"Philanthropic Studies",
	"Philosophy",
	"Photography",
	"Physical Education",
	"Physics",
	"Piano",
	"Piano Performance/Pedagogy",
	"Political Science",
	"Political Theory",
	"Portugese",
	"Pre-Accounting",
	"Pre-Art Conservation",
	"Pre-Medicine",
	"Principal Licensure",
	"Printmaking",
	"Project Management",
	"Psychology",
	"Public Accounting",
	"Public Administration",
	"Public Affairs",
	"Public Health",
	"Public Health Management",
	"Public Policy",
	"Public Relations",
	"Public Relations and Marketing",
	"Publishing",
	"Real Estate",
	"Religious Studies",
	"Russian",
	"Saxophone",
	"School Administration",
	"School Counseling",
	"School Leadership",
	"School Psychology",
	"Sculpture",
	"Secondary Education",
	"Social Work",
	"Social Work with Latinos/as",
	"Sociology",
	"Software Engineering",
	"Spanish",
	"Special Education",
	"Speech Communication",
	"Sport &amp; Performance Psychology",
	"Sport Management",
	"Statistics",
	"Strategic Networking",
	"Strings",
	"Studio Art",
	"Taxation",
	"Technology Management",
	"Telecommunications",
	"Theatre",
	"Training and Development",
	"Trombone",
	"Trumpet",
	"Tuba",
	"Viola",
	"Violin",
	"Visual Art and Design",
	"Vocal",
	"Web Design and Development",
	"Winds, Brass and Percussion",
	"Women's Studies",
	"Zoology and Bacteriology",
];

export default majors;

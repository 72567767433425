function Course({ image, avatar, major, name, teacher, lessonCount = 0, studentCount = 0, price = 0, discountedPrice = 0 }) {
  return (
    <div className="course-item">
      <div className="coures-img">
        <img className="img-fluid" src={image} alt="" />
        <div className="course-tag">
          <a className="badge-success" href="/">{major}</a>
        </div>
      </div>
      <div className="course-conten">
        <a href="/" className="course-author d-flex align-items-center mb-3">
          <img className="avatar img-fluid" src={avatar} alt="" />
          <span className="author-name">{teacher}</span>
        </a>
        <h5 className="mb-3">
          <a href="course-detail.html">{name}</a>
        </h5>
        <div className="course-rating mb-3 pb-3 border-bottom">
          <div className="review-rating">
            <i className="fas fa-star text-warning"></i>
            <i className="fas fa-star text-warning"></i>
            <i className="fas fa-star text-warning"></i>
            <i className="fas fa-star text-warning"></i>
            <i className="fas fa-star"></i>
          </div>
        </div>
        <div className="course-meta">
          <ul className="list-unstyled mb-0">
            <li><i className="fa fa-user pr-2"></i>{`${studentCount} students`}</li>
            <li><i className="fa fa-book-open pr-2"></i>{`${lessonCount} lessons`}</li>
            <li className="price text-primary"><span>{`$${price}`}</span>{` $${discountedPrice}`}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Course;

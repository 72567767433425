import {useEffect, useState} from "react";

export function useHashChange(links,handleFunction= null) {
    const [activeLink, setActiveLink] = useState(links[0]);
    useEffect(() => {
        console.log("useHashChange thien")
        const handleHashChange = () => {
            const dynamicId = window.location.hash.slice(1);
            const matchingLink = links.find((link) => link.id === dynamicId);
            if (matchingLink) {
                if (handleFunction) {
                    handleFunction(matchingLink);
                } else {
                    setActiveLink(matchingLink);
                }
            }
        };
        // Add hashchange event listener
        window.addEventListener('hashchange', handleHashChange);

        // Call the handler once to handle initial hash
        handleHashChange();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return [activeLink, setActiveLink];
}
function Subscribe() {
  return (
    <section className="positoin-relative z-index-9">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="p-4 bg-primary border-radius">
              <div className="row">
                <div className="col-md-12">
                  <div className="row justify-content-between">
                    <div className="col-lg-7 col-md-8 align-self-center mb-4 mb-md-0">
                      <h2>Subscribe</h2>
                      <p className="mb-0 text-white">Now go push your own limits and succeed!</p>
                    </div>
                    <div className="col-lg-5 col-md-4 justify-content-end align-self-center">
                      <div className="subscribe-form">
                        <form action="#">
                          <input className="form-control" placeholder="Enter your email" type="email" />
                          <a href="/"><i className="fas fa-long-arrow-alt-right"></i></a>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Subscribe;

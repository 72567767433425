function Auth() {
  return (
    <div className="row">
      <div className="col-lg-6">
        <h5>Returning users:</h5>
        <a href="/application/sign-in">Log in</a> to continue an application.
      </div>
      <div className="col-lg-6">
        <h5>First-time users:</h5>
        <a href="/application/sign-up">Create an account</a> to start a new application.
      </div>
    </div>
  );
}

export default Auth;

import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Table from './Table';
import Auth from './Auth';
import {useAuth} from '../hooks/useAuth';
import imgBg from "../images/bg/application_home.png";
import InnerHeader from "../components/InnerHeader";

function ApplicationHome() {
  const navigate = useNavigate();
  const {loggedInUser, logout} = useAuth();
  const [errors, setErrors] = useState(null);

  function handleSignOut() {
    axios.delete(
      `${process.env.REACT_APP_BACKEND_API}/logout`,
      {headers: {Authorization: loggedInUser.auth}},
    )
      .then(response => {
        if (response.data && response.data.status === 200) {
          logout();
          navigate('/application');
        } else {
          setErrors(response.data.errors)
        }
      })
      .catch(error => {
        logout();
        navigate('/application');
      })
  }

  return (

    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <h4>Sakya Buddha University Admissions</h4>
            <p>{loggedInUser ? `Welcome, ${loggedInUser.first_name}.` : 'Once you log in, you may:'}</p>
            <ul>
              <li>
                Click&nbsp;<strong>Start New Application</strong>&nbsp;to begin an
                application.
              </li>
              <li>
                Continue an application that is&nbsp;<strong>In Progress</strong>
                &nbsp;by clicking its link.
              </li>
              <li>
                Click a&nbsp;<strong>Submitted</strong>&nbsp;application to view
                your application status page, complete your checklist, or view your
                decision letter. <span className=""/>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive mt-4">
              {loggedInUser ? <Table handleSignOut={handleSignOut}/> : <Auth/>}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default ApplicationHome;

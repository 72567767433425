import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';

function SignUp() {
  const navigate = useNavigate();
  const { loggedInUser, login } = useAuth();

  const [firstNameParam, setFirstNameParam] = useState(null);
  const [lastNameParam, setLastNameParam] = useState(null);
  const [birthdateParam, setBirthdateParam] = useState(null);
  const [emailParam, setEmailParam] = useState(null);
  const [passwordParam, setPasswordParam] = useState(null);
  const [errors, setErrors] = useState(null);

  function handleSignUp() {
    setErrors(null);

    if (loggedInUser) {
      navigate('/application');
      return true;
    }

    axios.post(
      `${process.env.REACT_APP_BACKEND_API}/signup`,
      {
        user: {
          first_name: firstNameParam,
          last_name: lastNameParam,
          email: emailParam,
          password: passwordParam,
          birthdate: birthdateParam,
        }
      },
    )
      .then(response => {
        if (response.data.status === 201) {
          login({ ...response.data.data, auth: response.headers.authorization });
          navigate('/application');
        } else {
          setErrors(response.data.errors);
        }
      })
      .catch(error => {
        setErrors(error.response.data.message);
      })
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleSignUp();
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h2>Register</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-7 mb-2">
            <form onSubmit={handleSubmit}>
              <div className="login bg-white shadow p-5">
                {errors && (
                  <div className="row">
                    <div className="col-lg-12">
                      <ul className="text-danger">
                        <li>{errors}</li>
                      </ul>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>First name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        onChange={e => setFirstNameParam(e.target.value)}
                      />
                      <span className="focus-border"></span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Last name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        onChange={e => setLastNameParam(e.target.value)}
                      />
                      <span className="focus-border"></span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Birthdate</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="MM/DD/YYYY"
                    onChange={e => setBirthdateParam(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email address"
                    onChange={e => setEmailParam(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={e => setPasswordParam(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>
                <button type="submit" className="btn btn-primary">Sign Up</button>
                <p className="mt-2 mb-0">Already registered?<a href="sign-in"> Click here to sign in.</a></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;

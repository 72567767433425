import InnerHeader from '../components/InnerHeader';
import Counter from '../components/Counter';
import Clients from '../components/Clients';
import Weather from '../components/Weather';
import Faq from '../components/Faq';

function Pricing() {
  return (
    <>
      <InnerHeader customName={"Pricing Plans"} />
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-title text-center">
                <h2>Choose the plan that works for you</h2>
                <p className="mb-0">Our pricing works for enterprises of all sizes. Starting at just $19 per month</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 pb-4 pb-md-0">
              <div className="pricing">
                <h4 className="pricing-title">Basic</h4>
                <p className="mb-0">Only the basics - Everything you need to get started. Best for startup</p>
                <div className="pricing-price change-pricing-price">
                  <div className="yearly-price"><sup>$</sup>
                  <strong>29</strong>/ year</div>
                </div>
                <ul className="list-unstyled pricing-list">
                  <li>5 Analytics Campaign</li>
                  <li><s>Branded Reports</s></li>
                  <li>500 Keywords</li>
                  <li><s>0 Social Account</s></li>
                  <li>Phone &amp; Email Support</li>
                </ul>
                <a href="/" className="btn btn-light">Select Plan</a>
              </div>
            </div>
            <div className="col-md-4 pb-4 pb-md-0">
              <div className="pricing active">
                <h4 className="pricing-title">Standard</h4>
                <p className="mb-0">When you are ready to grow, We will grow with you. Get a Standard plan</p>
                <div className="pricing-price">
                  <div className="yearly-price"><sup>$</sup>
                  <strong className="text-white">49</strong>/ year</div>
                </div>
                <ul className="list-unstyled pricing-list">
                  <li>30 Analytics Campaign</li>
                  <li>Branded Reports</li>
                  <li>700 Keywords</li>
                  <li><s>100 Social Account</s></li>
                  <li>Phone &amp; Email Support</li>
                </ul>
                <a href="/" className="btn btn-white">Select Plan</a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing">
                <h4 className="pricing-title">Professional</h4>
                <p className="mb-0">Ready to kick it up a notch? Go for big with Professional membership</p>
                <div className="pricing-price change-pricing-price">
                  <div className="yearly-price"><sup>$</sup>
                  <strong>79</strong>/ year</div>
                </div>
                <ul className="list-unstyled pricing-list">
                  <li>50 Analytics Campaign</li>
                  <li>Branded Reports</li>
                  <li>900 Keywords</li>
                  <li>200 Social Account</li>
                  <li>Phone &amp; Email Support</li>
                </ul>
                <a href="/" className="btn btn-light">Select Plan</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Counter />
      <Weather />
      <Faq />
      <Clients />
    </>
  );
}

export default Pricing;

import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useCookies } from './useCookies';

export const useUser = () => {
  const { loggedInUser, setLoggedInUser } = useContext(AuthContext);
  const { setItem, removeItem } = useCookies();

  const addUser = (user) => {
    setLoggedInUser(user);
    setItem('user', JSON.stringify(user));
  };

  const removeUser = () => {
    setLoggedInUser(null);
    removeItem('user');
  };

  return { loggedInUser, addUser, removeUser };
};

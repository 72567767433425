import Lecturer from '../academics/Lecturer';
import imgTenzin from '../images/team/tenzin_dorjee.jpg';
import imgGiaimo from '../images/team/ben_giaimo.jpg';
import imgKelly from '../images/team/Catherine.jpg';
import imgGabriel from '../images/team/victor_gabriel.jpg';
import imgJohn from '../images/team/john_freese.jpeg';



function Faculty() {
    return (
      <>
          <div className=" section-title my-sm-3">
            <h4 className="mb-4">SBU Faculty</h4>
            <p>Meet the outstanding team of professionals... </p>
          </div> 
          <div className="row mb-xl-4">
            <Lecturer img={imgTenzin} name="Tenzin Dorjee" urlDetails="../faculty-details/tenzindorjee"  />
            <Lecturer img={imgGiaimo} name="Benjamin Giaimo" urlDetails="../faculty-details/benjamingiaimo"  />
            <Lecturer img={imgKelly} name="Catherine Kelly" urlDetails="../faculty-details/catherinekelly"  />
            <Lecturer img={imgGabriel} name="Victor Gabriel" urlDetails="../faculty-details/victorgabriel"  />
            <Lecturer img={imgJohn} name="John Freese" urlDetails="../faculty-details/johnfreese"  />
          </div>
      </>
    );
  }
  
  export default Faculty;
import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getApp, updateApp, convertKeysToSnakeCase, convertKeysToCamelCase } from '../utils';
import InstitutionForm from './InstitutionForm';

function AcademicRecord() {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();
  const [data, setData] = useState(selectedApp || {});

  useEffect(() => {
    window.jQuery('body,html').animate({
      scrollTop: 460
    }, 1000);
  }, []);

  function goToNextPage() {
    return navigate('/application/supplemental');
  }

  function onSuccess(updatedData) {
    setSelectedApp(updatedData);
    window.jQuery("#institutionModal").modal("hide");
    return setData(updatedData);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrors(null);

    let newRecords = [];

    if (!form.id) {
      newRecords.push(convertKeysToSnakeCase(form));
    } else {
      const idx = data.academicRecords.findIndex(r => r.id === form.id);
      newRecords.push(convertKeysToSnakeCase(form));
    }

    updateApp(
      loggedInUser,
      selectedApp,
      { id: data.id, academicRecordsAttributes: newRecords },
      onSuccess,
      setErrors,
    );
  }

  function handleCloseForm() {
    setForm({});
    return window.jQuery("#institutionModal").modal("hide");
  }

  const records = (data.academicRecords || []).map(convertKeysToCamelCase);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12">
              <h1>Academic Record</h1>
              <div>
                <p className='text-justify'>
                  We request either a scanned copy or a downloaded version of your unofficial
                  transcripts in PDF format from each college or university where you have attempted
                  or received college credit. The transcripts should include the following
                  information:
                </p>
                <ul>
                  <li>Student name</li>
                  <li>Institution name</li>
                  <li>Dates of attendance</li>
                  <li>Course names, numbers, credits, grades received for courses taken</li>
                  <li>
                    Degree title and conferral date, if a degree was earned
                    <ul style={{margin: 0, paddingLeft: 45}}>
                      <li>
                        If not printed on transcript, a separate degree certificate/diploma is
                        required
                      </li>
                    </ul>
                  </li>
                  <li>Additional international transcript&nbsp;requirements</li>
                </ul>
                <p className='text-justify'>
                  Please note that transcripts uploaded or received directly from the applicant are
                  considered unofficial. Upon admission to Sakya Buddha University, official
                  transcripts sent directly from each institution attended will be required.
                </p>
              </div>

              <table className="table text-left mb-4">
                <thead>
                  <tr className="bg-gray">
                    <th>Institution</th>
                    <th>Degree</th>
                    <th>Dates Attended</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {errors && (
                    <tr>
                      <td className="text-left text-danger">{errors.message}</td>
                    </tr>
                  )}
                  <tr>
                    <td className="text-left">
                      <a
                        href="#"
                        onClick={() => setForm({})}
                        data-toggle="modal"
                        data-target="#institutionModal"
                      >
                        Add Institution
                      </a>
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  {(records || []).map((r, idx) => (
                    <tr key={`rec-${idx}`}>
                      <td className="text-left">
                        {r.institution}
                      </td>
                      <td>{r.degree}</td>
                      <td>{`${r.fromMonth || '_'}/${r.fromYear || '_'} - ${r.toMonth || '_'}/${r.toYear || '_'}`}</td>
                      <td>
                        <a
                          href="#"
                          onClick={() => setForm(r)}
                          data-toggle="modal"
                          data-target="#institutionModal"
                        >
                          Edit
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="form-group">
                <div className="row justify-content-md-center">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={goToNextPage}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InstitutionForm
        data={form}
        setData={setForm}
        handleSubmit={handleSubmit}
        handleClose={handleCloseForm}
      />
    </>
  );
}

export default AcademicRecord;

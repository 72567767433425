const $ = window.jQuery;
const $document = $(document);

export default function searchBox() {
  $document.ready(function () {
    if ($('.search').length > 0) {
      $('.search-btn').on("click", function () {
        $('.search').toggleClass("search-open");
        return false;
      });
      $("html, body").on('click', function (e) {
        if (!$(e.target).hasClass("not-click")) {
          $('.search').removeClass("search-open");
        }
      });
    }
  });
}

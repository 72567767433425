import {EVENTSDATA} from '../data/eventsData';
import Divider from "../components/divider";
import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import UpcomingEvent from "../components/UpcomingEvent";
import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/gallery/event-img.png";


const monthYearMap = [
    'January 2024',
    'February 2024',
    'March 2024',
    'April 2024',
    'May 2024',
    'June 2024',
    'July 2024',
    'August 2024',
    'September 2024',
    'October 2024',
    'November 2024',
    'December 2024'
];

const MonthHeader = ({month}) => (
    <div className="row" style={{alignItems: 'center'}}>
        <div className="col-2">
            <span>{monthYearMap[month]}</span>
        </div>
        <div className="col-10">
            <Divider/>
        </div>
    </div>
);

const getAllSaturdays = (year, month) => {
    const date = new Date(year, month, 1);
    const saturdays = [];

    while (date.getMonth() === month) {
        if (date.getDay() === 6) { // 6 represents Saturday
            saturdays.push(new Date(date));
        }
        date.setDate(date.getDate() + 1);
    }

    return saturdays.map(sat => ({
        date: sat.getDate(),
        month: sat.toLocaleString('default', { month: 'short' })
    }));
};

const getAllSaturdaysForYear = (year) => {
    let allSaturdays = {};
    for (let month = 0; month < 12; month++) {
        allSaturdays[month] = getAllSaturdays(year, month);
    }
    return allSaturdays;
};

function AllEvent() {
    const [eventsData, setEventsData] = useState({});

    useEffect(() => {
        console.log("use effect");

        const today = new Date();
        const year = today.getFullYear();
        const allSaturdays = getAllSaturdaysForYear(year);

        // Create a deep copy of EVENTSDATA
        let updatedEventsData = JSON.parse(JSON.stringify(EVENTSDATA));

        Object.entries(allSaturdays).forEach(([month, saturdaysInMonth]) => {
            saturdaysInMonth.forEach((saturday, index) => {
                const newEvent = {
                    id: parseFloat(`${parseInt(month) + 1}.${index + 1}`),
                    date: saturday.date.toString(),
                    month: saturday.month,
                    title: "Mindfulness Class",
                    time: "10:00 AM to noon PST",
                    header: "Sakya Buddha University hosts a mindfulness meditation class. The session starts with reciting preliminary practices and mindfulness meditation, followed by a Dharma talk and Q & A.\n" +
                        "\n" +
                        " <br /> The Buddha emphasized the significance of group practice in fundamental dharma practice, with the effects being magnified by the number of participants. We invite you to join us!"
                };

                if (!updatedEventsData[month]) {
                    updatedEventsData[month] = [];
                }
                updatedEventsData[month].push(newEvent);
            });

            // Sort events in this month by date and time
            updatedEventsData[month].sort((a, b) => new Date(a.date) - new Date(b.date));
        });

        setEventsData(updatedEventsData);
    }, []);







    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 1; // Adjust as needed, 1 month per page for example

    const pagesVisited = currentPage * itemsPerPage;
    const displayData = Object.entries(eventsData).slice(pagesVisited, pagesVisited + itemsPerPage);

    const pageCount = Math.ceil(Object.keys(eventsData).length / itemsPerPage);

    const changePage = ({selected}) => {
        setCurrentPage(selected);
    };

    return (
        <>
            <InnerHeader backgroundImage={imgBg}/>
            <section className="space-ptb">
                <div className="container">
                    <div className="col-lg-12 ml-0">
                        {displayData.map(([month, data]) => (
                            <React.Fragment key={month}>

                                <MonthHeader month={month}/>
                                {data.map(event => (
                                    <React.Fragment key={event.id}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <UpcomingEvent {...event} dateClass="event-date-all"
                                                               monthClass="event-month-all"/>
                                            </div>
                                        </div>


                                        <div className="row" style={{alignItems: 'center'}}>
                                            <div className="col-2">
                                                <span></span>
                                            </div>
                                            <div className="col-10">
                                                <Divider/>
                                            </div>
                                        </div>

                                    </React.Fragment>
                                ))}

                            </React.Fragment>
                        ))}
                    </div>
                    <div className="col-lg-12">
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                            className="pagination justify-content-between mt-5"
                            pageRangeDisplayed="12"
                        />
                    </div>
                </div>
            </section>
        </>
    );
}


export default AllEvent;

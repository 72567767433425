function AcademicCalendar() {
  return (
    <>
      <div className="my-sm-3 text-justify">
        <h4 className="mb-4">Sakya Buddha University Academic Calendar 2023–2024</h4>
        <h6>FALL 2023: SEP 18, 2023 – DEC 29, 2023</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Thursday, August 25</div>
          <div className="col-md-8">Graduate Program orientation for new students</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, September 18</div>
          <div className="col-md-8">Classes begin</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, September 25</div>
          <div className="col-md-8">Last day of Add/Drop period; last day 100% refund</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Tuesday, September 26</div>
          <div className="col-md-8">Withdrawal period begins; refund percentage applies. Last day to withdraw with a
            grade of W
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, November 13</div>
          <div className="col-md-8">Student registration for Spring and Summer semesters</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Wednesday, November 22 to Friday, November 24</div>
          <div className="col-md-8">Thanksgiving holiday; no classes meet</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Tuesday, December 26 to Friday, December 29</div>
          <div className="col-md-8">Final exams</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, January 5, 2024</div>
          <div className="col-md-8">Grades due</div>
        </div>
        <br/>

        <h6>SPRING 2024: MAR 4, 2024 – JUN 14, 2024</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, March 4</div>
          <div className="col-md-8">Classes begin</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, March 11</div>
          <div className="col-md-8">Last day of Add/Drop period; last day 100% refund</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Tuesday, March 12</div>
          <div className="col-md-8">Withdrawal period begins; refund percentage applies. Last day to withdraw with a
            grade of W
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, March 18</div>
          <div className="col-md-8">Student registration for Fall semester</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, June 10 to Friday, June 14</div>
          <div className="col-md-8">Final exams</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, June 21</div>
          <div className="col-md-8">Grades due</div>
        </div>
        <br/>
        <h6>SUMMER 2024: JUN 24, 2024 – AUG 30, 2024</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, June 24</div>
          <div className="col-md-8">Classes begin</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, July 1</div>
          <div className="col-md-8">Last day of Add/Drop period; last day 100% refund</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Tuesday, July 2</div>
          <div className="col-md-8">Withdrawal period begins; refund percentage applies. Last day to withdraw with a
            grade of W
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, August 26</div>
          <div className="col-md-8">Final exams</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, August 30</div>
          <div className="col-md-8">Final exams</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, September 6</div>
          <div className="col-md-8">Grades due</div>
        </div>

        <br/>

        <h6>FALL 2024: SEP 2, 2024 – DEC 22, 2024</h6>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, August 26</div>
          <div className="col-md-8">Graduate Program orientation for new students</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, September 2</div>
          <div className="col-md-8">Classes begin</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, September 9</div>
          <div className="col-md-8">Last day of Add/Drop period; last day 100% refund</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Tuesday, September 10</div>
          <div className="col-md-8">Withdrawal period begins; refund percentage applies. Last day to withdraw with a
            grade of W
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, November 11</div>
          <div className="col-md-8">Student registration for spring and summer semesters</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Wednesday, November 27</div>
          <div className="col-md-8">Thanksgiving holiday; no classes meet</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, November 29</div>
          <div className="col-md-8">Thanksgiving holiday; no classes meet</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Monday, December 16</div>
          <div className="col-md-8">Final exams</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, December 20</div>
          <div className="col-md-8">Final exams</div>
        </div>
        <div className="row">
          <div className="col-md-4 font-weight-bold">Friday, December 27</div>
          <div className="col-md-8">Grades due</div>
        </div>
      </div>
    </>

  );
}

export default AcademicCalendar;
import InnerHeader from '../components/InnerHeader';


function AboutUs() {
  return (
    <>
      <InnerHeader />
    </>
  );
}

export default AboutUs;

import InnerHeader from '../components/InnerHeader';

function Blog() {
  return (
    <>
      <InnerHeader />
    </>
  );
}

export default Blog;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';
import { last } from 'lodash';

function ResetPassword() {
  const navigate = useNavigate();

  const { loggedInUser, login } = useAuth();

  const [passwordParam, setPasswordParam] = useState(null);
  const [passwordConfirmationParam, setPasswordConfirmationParam] = useState(null);
  const [errors, setErrors] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(null);

  function handleResetPassword() {
    setErrors(null);
    setMessage(null);
    setLoading(false);

    if (loggedInUser) {
      navigate('/application');
      return true;
    }

    if (!passwordParam || !passwordConfirmationParam) {
      setErrors('Please complete the required fields.');
      return false;
    }

    if (passwordParam !== passwordConfirmationParam) {
      setErrors('Passwords do not match.');
      return false;
    }

    setLoading(true);

    axios.put(
      `${process.env.REACT_APP_BACKEND_API}/password`,
      {
        user:
          {
            password: passwordParam,
            password_confirmation: passwordConfirmationParam,
            reset_password_token: last(window.location.search.split('=')),
          },
        },
    )
      .then(response => {
        if (response.data && response.data?.status === 200) {
          setMessage("Password updated.")
          navigate('/application/sign-in');
        } else {
          setErrors(response.data.errors);
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          return setErrors(error.response.data.message);
        }
        setErrors(error.message);
      })
      .finally(() => setLoading(false));
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleResetPassword();
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h2>Set Password</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-7 mb-2">
            <div className="login bg-white shadow p-5">
              {errors && (
                <div className="row">
                  <div className="col-lg-12">
                    <ul className="text-danger">
                      <li>{errors}</li>
                    </ul>
                  </div>
                </div>
              )}
              {message && (
                <div className="row">
                  <div className="col-lg-12">
                    <ul className="text-success">
                      <li>{message}</li>
                    </ul>
                  </div>
                </div>
              )}
              <p>
                To protect the security of your account, please specify a new password.
                The password must meet complexity requirements.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    onChange={e => setPasswordParam(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>
                <div className="form-group">
                  <label>Password Confirmation</label>
                  <input
                    type="password"
                    name="confirm_password"
                    className="form-control"
                    onChange={e => setPasswordConfirmationParam(e.target.value)}
                  />
                  <span className="focus-border"></span>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading && true}
                >
                  Set Password
                </button>
                {loading && (
                  <span
                    className="spinner-border"
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      marginLeft: '1rem',
                    }}
                  />
                )}
                <p className="mt-2 mb-0"><a href="sign-in">Back to sign in</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

function AcademicsIntro() {
    return (
      <>
          <div className="section-title my-sm-3 text-justify">
            <h4 className="mb-4">SBU Academics</h4>
              <p>Our commitment to diversity is reflected in the wide range of majors and concentrations offered,
                  allowing individuals to align their academic journey with their passions and aspirations, all while
                  embracing the guiding principles of Buddhist philosophy.</p>
              <p>Venture beyond the confines of traditional classrooms as we encourage our students to embark
                  on transformative international field trips. We invite students to explore the opportunities that
                  connect them with some of the world&#39;s most vibrant cities, where the essence of Buddhist
                  philosophy seamlessly intertwines with educational experience. Our hands-on learning initiatives
                  provide practical experiences, enhancing all academic pursuits and equipping our students with
                  the multifaceted challenges of post-graduate life. Throughout these transformative journeys, our
                  commitment is to support our students at every stage, allowing them to embrace both academic
                  excellence and the holistic principles of Buddhist philosophy.</p>
          </div>
      </>
    );
  }
  
  export default AcademicsIntro;
function Map() {
  return (
    <>
      <section className="map">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-sm-12">
              <div className="map h-500">
                <iframe
                  src="https://maps.google.com/maps?q=Sakya+Buddha+University+3404+Westminster+Ave,+Santa+Ana,+CA+92703,+USA&t=&z=15&ie=UTF8&iwloc=&output=embed"
                  style={{width: '100%', height: '100%'}}
                >
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Map;

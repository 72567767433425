import ReligiousStudies from "./ReligiousStudies";
import React, {useState} from "react";
import BuddhistChaplaincy from "./BuddhistChaplaincy";
import {Link} from "react-router-dom";

function Graduate() {
    return (
        <>
            <div className="my-sm-3 text-justify">
                <h4 className="mb-4">SBU Graduate Academics</h4>
                <p>
                    Sakya Buddha University offers a diverse range of graduate academic programs in Buddhist
                    education, providing a unique and enriching experience for students seeking a deep
                    understanding of Buddhist philosophy and teachings. Our programs are designed to foster
                    intellectual exploration, critical thinking, and a profound engagement with Buddhist principles.
                    With a commitment to academic excellence and a supportive learning environment, Sakya
                    Buddha University is dedicated to nurturing the next generation of scholars and practitioners in
                    the field of Buddhist education. Join us on a transformative journey that combines traditional
                    wisdom with contemporary perspectives.
                </p>
                <div>
                    <div>
                        <Link to={`/academics-program/1`} className="btn btn-primary mb-3 text-left" style={{ width: '32%'}} >
                            Religious Studies
                        </Link>
                    </div>
                    {/*<div>*/}
                    {/*    <Link to={`/academics-program/2`} className="btn btn-primary text-left " style={{ width: '32%'}}>*/}
                    {/*        Buddhist Chaplaincy*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                </div>


            </div>
        </>
    );
}

export default Graduate;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
    .icon-img {
        float: left;
    }
}

@media (max-width: 767px) {
    .center-icon-img {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/about-us/css/aboutUs.css"],"names":[],"mappings":"AAAA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;AACJ","sourcesContent":["@media (min-width: 768px) {\n    .icon-img {\n        float: left;\n    }\n}\n\n@media (max-width: 767px) {\n    .center-icon-img {\n        display: flex;\n        justify-content: center;\n        margin-bottom: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

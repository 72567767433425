
function Certificate() {
    return (
      <>
          <div className="my-sm-3">
              <h4 className="mb-4">Certificate Academics</h4>
              <div className='text-justify'>
                  <p>The Mindfulness Certificate Training Program (MCTP) at Sakya Buddha University is a comprehensive
                      and life-enhancing program crafted for individuals aiming to establish or deepen their mindfulness
                      practice. Ideal for therapists, educators, yoga teachers, business professionals, and anyone
                      interested in teaching mindfulness.</p>

                  <p>Under the guidance of expert mindfulness instructors, participants will:</p>

                  <ul>
                      <li>Acquire skills to teach mindfulness classes, both virtually and in-person.</li>
                      <li>Explore the history and science of mindfulness.</li>
                      <li>Learn mindfulness tools for stress reduction, enhanced calmness, and improved focus.</li>
                      <li>Gain insights into integrating mindfulness into their respective professions.</li>
                      <li>Familiarize themselves with talking points concerning the scientific benefits of various
                          mindfulness practices.
                      </li>
                      <li>Receive guidance on incorporating technology to support teaching.</li>
                      <li>Discover methods to alleviate physical and mental suffering.</li>
                      <li>Develop qualities such as compassion, patience, presence, and awareness.</li>
                  </ul>
              </div>
          </div>
      </>
    );
  }
  export default Certificate;
import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getApp, updateApp } from '../utils';

function Supplemental() {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const [errors, setErrors] = useState(null);
  const [selectedApp, setSelectedApp] = useOutletContext();
  const [data, setData] = useState(selectedApp || {});

  useEffect(() => {
    window.jQuery('body,html').animate({
      scrollTop: 460
    }, 1000);
  }, []);

  function onSuccess(data) {
    setSelectedApp(data);
    return navigate('/application/signature');
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrors(null);
    updateApp(
      loggedInUser,
      selectedApp,
      data,
      onSuccess,
      setErrors,
    );
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-12">
            <h1>Required Supplemental Information</h1>
            <form onSubmit={handleSubmit}>
              <div className="bg-dark clearfix">
                <h6 className="p-2">Disciplinary History</h6>
              </div>
              <div className="form-group">
                <label htmlFor="disciplinary_flag">
                  Have you previously experienced or are you presently subject to probation,
                  suspension, or dismissal from any educational institution you attended due to
                  conduct or disciplinary reasons?
                  (<span className="text-danger">*</span>)
                </label>
                <div className="" role="group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="disciplinary_flag"
                    id="disciplinary_yes"
                    autoComplete="off"
                    defaultChecked={data.disciplinaryFlag}
                    onChange={e => setData({ ...data, disciplinaryFlag: true })}
                  />
                  <label htmlFor="disciplinary_yes" className="pl-2">Yes</label>
                  <input
                    type="radio"
                    className="btn-check ml-4"
                    name="disciplinary_flag"
                    id="disciplinary_no"
                    autoComplete="off"
                    defaultChecked={!data.disciplinaryFlag}
                    onChange={e => setData({ ...data, disciplinaryFlag: false })}
                  />
                  <label htmlFor="disciplinary_no" className="pl-2">No</label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="felony_flag">
                  Have you ever been convicted of or pleaded guilty to a criminal offense, excluding
                  minor traffic violations, or do you currently have pending criminal charges against
                  you?
                  (<span className="text-danger">*</span>)
                </label>
                <div className="" role="group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="felony_flag"
                    id="felony_yes"
                    autoComplete="off"
                    defaultChecked={data.felonyFlag}
                    onChange={e => setData({ ...data, felonyFlag: true })}
                  />
                  <label htmlFor="felony_yes" className="pl-2">Yes</label>
                  <input
                    type="radio"
                    className="btn-check ml-4"
                    name="felony_flag"
                    id="felony_no"
                    autoComplete="off"
                    defaultChecked={!data.felonyFlag}
                    onChange={e => setData({ ...data, felonyFlag: false })}
                  />
                  <label htmlFor="felony_no" className="pl-2">No</label>
                </div>
              </div>
              <div className="form-group">
                <div className="row justify-content-md-center">
                  <button className="btn btn-primary" type="submit">Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Supplemental;

function Weather() {
  return (
    <section className="space-ptb">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h2>University Campus Weather</h2>
              <p className="mb-0">Remind yourself you have nowhere to go except up as you have already been at the bottom.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="table-responsive">
              <table className="table table-striped text-center mb-0">
                <thead>
                  <tr className="bg-dark">
                    <th className="text-white" scope="col">Date</th>
                    <th className="text-white" scope="col">Maximum Temp.</th>
                    <th className="text-white" scope="col">Minimum Temp.</th>
                    <th className="text-white" scope="col">Rainfall (mm)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>June 28</td>
                    <td>36°C</td>
                    <td>16°C</td>
                    <td>00</td>
                  </tr>
                  <tr>
                    <td>June 27</td>
                    <td>37°C</td>
                    <td>22°C</td>
                    <td>02</td>
                  </tr>
                  <tr>
                    <td>June 26</td>
                    <td>34°C</td>
                    <td>22°C</td>
                    <td>00</td>
                  </tr>
                  <tr>
                    <td>June 25</td>
                    <td>32°C</td>
                    <td>16°C</td>
                    <td>04</td>
                  </tr>
                  <tr>
                    <td>June 24</td>
                    <td>35°C</td>
                    <td>25°C</td>
                    <td>00</td>
                  </tr>
                  <tr>
                    <td>June 23</td>
                    <td>30°C</td>
                    <td>14°C</td>
                    <td>06</td>
                  </tr>
                  <tr>
                    <td>June 22</td>
                    <td>39°C</td>
                    <td>26°C</td>
                    <td>00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Weather;

import InnerHeader from '../components/InnerHeader';
import MeetOurTeam from '../components/MeetOurTeam';
import Subscribe from '../components/Subscribe';
import img2 from '../images/team/02.jpg';
import img4 from '../images/team/04.jpg';
import img6 from '../images/team/06.jpg';
import avatar2 from '../images/avatar/02.jpg';
import avatar3 from '../images/avatar/03.jpg';
import avatar5 from '../images/avatar/05.jpg';

function TeachersSingle() {
  return (
    <>
      <InnerHeader />
      <section className="space-pt">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="team-single is-sticky">
                    <div className="team-img">
                    <img className="img-fluid border-radius" src={img2} alt="" />
                    </div>
                    <div className="social mt-4 text-center">
                      <ul className="pl-0">
                        <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="team-info">
                    <a href="/" className="team-name h4">Ora Bryan</a>
                    <h6 className="border-bottom text-primary my-3 pb-3">Illustration</h6>
                    <div className="d-flex">
                      <h6>Phone:</h6>
                      <label className="ml-3">789 456 1230</label>
                    </div>
                    <div className=" d-flex">
                      <h6>Email:</h6>
                      <label className="ml-3">support@academic.com</label>
                    </div>
                    <div className=" d-flex">
                      <h6>Address:</h6>
                      <label className="ml-3">594 E. Whitemarsh Street Mchenry, IL 60050</label>
                    </div>
                    <p className="mt-4">The best way is to develop and follow a plan. Start with your goals in mind and then work backward to develop the plan. What steps are required to get you to the goals? Make the plan as detailed as possible. Try to visualize and then plan for, every possible setback. Commit the plan to paper and then keep it with you at all times.</p>
                  </div>
                  <div className="blockquote mt-5">
                    <div className="blockquote-info">
                      <div className="blockquote-quote">
                        <i className="fas fa-quote-left"></i>
                      </div>
                      <div className="blockquote-content">
                        *Along with your plans, you should consider developing an action orientation that will keep you motivated to move forward at all times. This requires a little self-discipline, but is a crucial component to achievement of any kind*
                      </div>
                    </div>
                  </div>
                  <div className="section-title mt-5">
                    <h4 className="title">Qualifications</h4>
                       <div className="list">
                        <ul className="pl-0">
                          <li><i className="fas fa-check text-primary pr-2"></i>Once you have a clear understanding of what you want,</li>
                          <li><i className="fas fa-check text-primary pr-2"></i>It could be time, effort, sacrifice, money or perhaps, </li>
                          <li><i className="fas fa-check text-primary pr-2"></i>Have some fun and hypnotize yourself to be your very own </li>
                          <li><i className="fas fa-check text-primary pr-2"></i>Introspection is the trick. Understand what you want, </li>
                        </ul>
                      </div>
                  </div>
                  <div className="section-title">
                    <h4 className="title">Work philosophy</h4>
                    <p className="mt-4">The best way is to develop and follow a plan. Start with your goals in mind and then work backward to develop the plan. What steps are required to get you to the goals? Make the plan as detailed as possible. Try to visualize and then plan for, every possible setback. Commit the plan to paper and then keep it with you at all times.</p>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="owl-carousel" data-nav-arrow="false" data-nav-dots="true" data-items="2" data-md-items="2" data-sm-items="1" data-xs-items="1" data-xx-items="1" data-space="0" data-autoheight="true">

                        <div className="item">
                          <div className="testimonial-item px-4">
                            <div className="testimonial-quote mb-4">
                              <i className="fas fa-quote-left fa-2x text-white"></i>
                            </div>
                            <div className="testimonial-content">
                              <p>Just what I was looking for. I will let my mum know about this, she could really make use of university!</p>
                            </div>
                            <div className="testimonial-author">
                              <div className="testimonial-avatar avatar avatar-md mb-0">
                                <img className="img-fluid " src={avatar2} alt="" />
                              </div>
                              <div className="testimonial-name">
                                <h6 className="name">Alice Williams</h6>
                                <span>- Manager</span>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="item">
                          <div className="testimonial-item px-4">
                            <div className="testimonial-quote mb-4">
                              <i className="fas fa-quote-left fa-2x text-white"></i>
                            </div>
                            <div className="testimonial-content">
                              <p>university is the next killer app. university did exactly what you said it does. I will let my mum know about this,</p>
                            </div>
                            <div className="testimonial-author">
                              <div className="testimonial-avatar avatar avatar-md mb-0">
                                <img className="img-fluid " src={avatar5} alt="" />
                              </div>
                              <div className="testimonial-name">
                                <h6 className="name">Homer Reyes</h6>
                                <span>Community</span>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="item">
                          <div className="testimonial-item px-4">
                            <div className="testimonial-quote mb-4">
                              <i className="fas fa-quote-left fa-2x text-white"></i>
                            </div>
                            <div className="testimonial-content">
                              <p>I have gotten at least 50 times the value from university. I will let my mum know about this.</p>
                            </div>
                            <div className="testimonial-author">
                              <div className="testimonial-avatar avatar avatar-md mb-0">
                                <img className="img-fluid " src={avatar3} alt="" />
                              </div>
                              <div className="testimonial-name">
                                <h6 className="name">Ora Bryan</h6>
                                <span>Illustration</span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-12">
            <div className="my-4 my-sm-5 py-3 px-3 bg-dark border-radius">
              <div className="row d-flex align-items-center justify-content-between">
                <div className="col-sm">
                  <a href="/" className="d-flex align-items-center">
                    <div className="sin-pag-img avatar">
                      <img className="img-fluid rounded" src={img6} alt="" />
                    </div>
                    <div className="sin-pag-con ml-3">
                      <span className="h5 text-white">Glenda Tran</span>
                      <small className="d-block text-white">Prev</small>
                    </div>
                  </a>
                </div>
                <div className="col-12 col-sm-1 text-center my-3 my-sm-0">
                  <a href="/"><i className="fas fa-lg fa-grip-horizontal text-white" aria-hidden="true"></i></a>
                </div>
                <div className="col-sm">
                  <a href="/" className="d-flex align-items-center text-right">
                    <div className="sin-pag-con mr-3 ml-auto">
                      <span className="h5 text-white">Candice Mcdonald</span>
                      <small className="d-block text-white">Next</small>
                    </div>
                    <div className="sin-pag-img avatar">
                      <img className="img-fluid rounded" src={img4} alt="" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
      <MeetOurTeam />
      <Subscribe />
    </>
  );
}

export default TeachersSingle;

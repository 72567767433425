export const EVENTSDATA = {
    0: [
        {
            id: 1.1,
            date: "1",
            month: "Jan",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    1: [
        {
            id: 2.1,
            date: "4",
            month: "Feb",
            title: "thien 2",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    2: [
        {
            id: 3.1,
            date: "1",
            month: "Mar",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    3: [
        {
            id: 4.1,
            date: "1",
            month: "Apr",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    4: [
        {
            id: 5.1,
            date: "1",
            month: "May",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    5: [
        {
            id: 6.1,
            date: "1",
            month: "Jun",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    6: [
        {
            id: 7.1,
            date: "2",
            month: "Jul",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    7: [
        {
            id: 8.1,
            date: "1",
            month: "Aug",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    8: [
        {
            id: 9.1,
            date: "1",
            month: "Sep",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    9: [
        {
            id: 10.1,
            date: "1",
            month: "Oct",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    10: [
        {
            id: 11.1,
            date: "1",
            month: "No",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
        // Add more events for January here
    ],
    11: [
        {
            id: 12.1,
            date: "1",
            month: "Dec",
            title: "Art Exhibition",
            time: "2:00 PM - 4:00 PM",
            header: "Innovative Art Showcase"
        },
    ],
};
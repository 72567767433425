import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/bg/info_tech.png";
import Divider from "../components/divider";

function InfoTech() {

    return (
        <>
            <InnerHeader customName='Information Techology' backgroundImage={imgBg}/>
            <Divider/>

            <div className="container">
                <div className="header">
                    <h4>Information Technology at Sakya Buddha University</h4>
                </div>
                <div className="info-section">
                    <p>
                        The Information Technology (IT) at Sakya Buddha University plays a crucial role in supporting
                        the
                        various resources utilized for teaching, research, and learning activities. If you need
                        assistance, feel
                        free to reach out to the IT team.
                    </p>
                    <h5>IT Help Center</h5>
                    <p>3404 & 3412 Westminster Ave.<br/>
                        Santa Ana, CA 92703<br/>
                        <a href="mailto:tqnguyen84@gmail.com">tqnguyen84@gmail.com</a></p>

                    <h5>IT Support</h5>
                    <p>Call <a href="tel:626-242-8456">626-242-8456</a></p>
                </div>
            </div>
        </>
    )
        ;
}

export default InfoTech;

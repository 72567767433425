import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/home/chua.jpg";
import Divider from "../components/divider";
import {useState} from "react";
import axios from "axios";

function Accreditation() {

    return (
        <>
            <InnerHeader backgroundImage={imgBg}/>
            <Divider/>

            <div className="container text-justify">
                <div className='row'>
                    <div className="col-lg-12 ml-0 shadow border-radius pt-5 pb-4">
                        <h4 className=' d-flex justify-content-center'>Accreditation</h4>
                        <p>To be updated ...</p>
                    </div>

                </div>
            </div>


        </>
    )
        ;
}

export default Accreditation;

import bg5 from '../images/bg/05.jpg';
import bg from '../images/about/pattern.png';

function WhyChooseUs() {
  return (
    <section className="bg-dark">
      <div className="container-fluid">
        <div className="row no-guuters">
          <div
            className="col-lg-6 bg-holder d-none d-lg-block"
            style={{backgroundImage: `url(${bg5})`}}>
          </div>
          <div
            className="col-lg-6 px-4 px-md-5 px-lg-6 py-6 px-lg-6"
            style={{backgroundImage: `url(${bg})`}}
          >
            <div className="section-title">
              <h2 className="title">Why Choose Us</h2>
              <p className="mb-0">Let success motivate you. Find a picture of what epitomizes success to you and then pull it out when you are in need of motivation.</p>
            </div>
            <div className="media">
              <i className="flaticon-knowledge mr-3 fa-3x text-white border border-white border-radius p-3 mb-0 font-weight-normal"></i>
              <div className="media-body">
                <h5 className="mt-0 mb-3">Multidisciplinary study programs</h5>
                <p className="text-white mb-0">For the summary order he followed a little scheme of employing his time each day. From five to seven each morning he spent in bodily personal attention,</p>
              </div>
            </div>
            <span className="border-bottom d-block my-5 border-light"></span>
            <div className="media">
              <i className="flaticon-mortarboard-2 mr-3 fa-3x text-white border border-white border-radius p-3 mb-0 font-weight-normal"></i>
              <div className="media-body">
                <h5 className="mt-0 mb-3">International study programs</h5>
                <p className="text-white mb-0">saying a short prayer, thinking over the day’s business and resolutions, studying and eating breakfast. From eight till twelve he worked at his trade.</p>
              </div>
            </div>
            <span className="border-bottom d-block my-5 border-light"></span>
            <div className="media">
              <i className="flaticon-team-1 mr-3 fa-3x text-white border border-white border-radius p-3 mb-0 font-weight-normal"></i>
              <div className="media-body">
                <h5 className="mt-0 mb-3">Highly qualified teaching staff</h5>
                <p className="text-white mb-0">From twelve to one he read or overlooked his accounts and dined. From two to five he worked at his trade. The rest of the evening music, or diversion of some sort.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;

function FinancialAid() {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <img className="img-fluid border-radius" src="images/blog/03.jpg" alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="mb-3 mt-4">Financial Aid</h4>
              <p>Sakya Buddha University is committed to facilitating access to higher education by providing comprehensive financial support to eligible students. Our Financial Office recognizes the critical role of financing education and is dedicated to assisting students in navigating tuition costs, exploring various financial options, and identifying additional funding avenues.</p>
              <p>Currently, Sakya Buddha University offers scholarships on a case-by-case basis. We encourage all students to reach out to the Financial Aid Office to inquire about available scholarship opportunities. It's important to note that federal aid is not available at this time.</p>
              <p>Our Financial Office operates on a hybrid schedule, ensuring accessibility through both in-person and remote assistance. Whether you prefer to visit us on campus or seek assistance remotely, our team is here to support you. For prompt assistance, please contact us via email at <strong>sakyabuddha@vienphathoc.org</strong>. We strive to respond to all inquiries within 24 hours.</p>
              <p>We invite all prospective and current students to take the first step towards their educational journey by reaching out to the Financial Aid Office.</p>

              <h6 className="font-weight-bold">Mailing Address</h6>
              <p>
                Sakya Buddha University
                <br />
                Graduate Studies Financial Office
                <br />
                3404 Westminster Avenue, Santa Ana, CA 92703
                <br />
                Phone: (714) 328 6087
                <br />
                Email: sakyabuddha@vienphathoc.org
                <br />
                Hours: Monday-Friday, 8 AM-5 PM (PST)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinancialAid;

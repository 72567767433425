import Post from "../components/Post";
import {postData} from '../data/postsData';
import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/gallery/post-img.png";
import Divider from "../components/divider";

function AllPost() {
    return (
        <>
            <InnerHeader backgroundImage={imgBg}/>
            <Divider />
                <div className="container">
                    <div className='row'>
                        <div className="col-12">
                            {postData.map(post => (
                                <Post key={post.id} {...post} />
                            ))}
                        </div>
                    </div>
                </div>
        </>
    );
}

export default AllPost;

import InnerHeader from "../components/InnerHeader";
import imgBg from "../images/bg/private_policy_bg.jpg";
import Divider from "../components/divider";
import {useState} from "react";
import axios from "axios";

function PrivatePolicy() {

    return (
        <>
            <InnerHeader backgroundImage={imgBg}/>
            <Divider/>

            <div className="container text-justify">
                <div className='row'>
                    <div className="col-lg-12 ml-0 shadow border-radius pt-5 pb-4">
                        <h4 className=' d-flex justify-content-center'>The Data Privacy Policy of </h4>
                        <h4 className=' d-flex justify-content-center'>Sakya Buddha University </h4>

                        <p>Students at Sakya Buddha University may face dismissal and withdrawal Students at Sakya
                            Buddha University may face dismissal and withdrawal from their enrollment
                            for three main reasons:</p>
                        <ol>
                            <li>Attendance</li>
                            <li>Misconduct</li>
                            <li>Failure to Maintain Satisfactory Academic Progress</li>
                        </ol>

                        <p>Sakya Buddha University has specific policies addressing each of these categories, detailed
                            in the sections below for further clarification.</p>

                        <div className="accordion" id="private-policy">
                            <div className="card">
                                <div className="accordion-icon card-header w-50" id="headingThree">
                                    <h4 className="mb-0">
                                        <button className="btn collapsed" type="button" data-toggle="collapse"
                                                data-target="#private-1" aria-expanded="false"
                                                aria-controls="private-1">1. Dismissal Due to Attendance
                                        </button>
                                    </h4>
                                </div>
                                <div id="private-1" className="collapse" aria-labelledby="headingThree">
                                    <div className="card-body">
                                        <p>Please consult the Sakya Buddha University Attendance Policy for detailed
                                            information.</p>
                                        <p>Students who fail to log into the Learning Management System (LMS) for 14
                                            consecutive days
                                            after the first day of class for all online courses, or who miss 25% of
                                            their
                                            course
                                            meetings non-consecutively, without providing written notification to Sakya
                                            Buddha
                                            University of their intention to continue, will face administrative
                                            withdrawal
                                            or dismissal
                                            from the university, effective retroactively from the last recorded
                                            attendance
                                            date.</p>
                                        <p>Students who are administratively dismissed and withdrawn from Sakya Buddha
                                            University after
                                            the add/drop period will receive grades such as "NC," "W," or "F" for the
                                            related courses,
                                            based on the withdrawal deadline. These grades will count toward attempted
                                            hours
                                            at
                                            [UNIVERSITY NAME], and any applicable tuition refund will be calculated
                                            based on
                                            Sakya
                                            Buddha University's refund policy.</p>
                                        <p>Sakya Buddha University may designate periods of non-enrollment during which
                                            no
                                            courses are
                                            conducted. During such periods, such as holidays or the annual winter break,
                                            the
                                            14-day
                                            attendance limit may be extended to include the scheduled break.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="accordion-icon card-header w-50" id="headingThree">
                                    <h4 className="mb-0">
                                        <button className="btn collapsed" type="button" data-toggle="collapse"
                                                data-target="#private-2" aria-expanded="false"
                                                aria-controls="private-2">2. Student Dismissal
                                            Due to Misconduct
                                        </button>
                                    </h4>
                                </div>
                                <div id="private-2" className="collapse" aria-labelledby="headingThree">
                                    <div className="card-body">
                                        <p><strong>NOTE:</strong> Various Sakya Buddha University policies address
                                            specific aspects of student misconduct and unacceptable behavior that may
                                            lead to the student's dismissal. These encompass the following:</p>

                                        <ul>
                                            <li>Harassment and Non-Discrimination</li>
                                            <li>Sexual Harassment</li>
                                            <li>Cheating & Plagiarism</li>
                                            <li>Student Code of Conduct</li>
                                            <li>Drug-Free Policy</li>
                                            <li>Anti-Bullying</li>
                                            <li>Alcoholic Beverages</li>
                                            <li>Non-Solicitation</li>
                                        </ul>

                                        <p>Each Sakya Buddha University student is accountable for:</p>
                                        <ol>
                                            <li>Complying with the laws of their nation and the nation where they are
                                                studying.
                                            </li>
                                            <li>Adhering to Sakya Buddha University's rules and regulations.</li>
                                            <li>Cooperating with Sakya Buddha University center authorities in all
                                                facility-related matters, whether personal or on behalf of others,
                                                including providing information and evidence.
                                            </li>
                                            <li>Attending all teaching & learning sessions, except with prior excused
                                                permission from the subject teacher.
                                            </li>
                                            <li>Sitting for examinations, unless prevented or with prior excused
                                                permission from the subject teacher.
                                            </li>
                                            <li>Being responsible for safeguarding and ensuring the safety of Sakya
                                                Buddha University's property, including any equipment used by them.
                                            </li>
                                        </ol>

                                        <p>The following are prohibitions:</p>
                                        <ul>
                                            <li>No student is permitted to engage in any activity or conduct that may
                                                damage or harm the interests, harmony, or reputation of Sakya Buddha
                                                University centers or its faculty, staff, students, or officers.
                                            </li>
                                            <li>No student is allowed to use any lecture, tutorial, or teaching
                                                materials provided to them by the centers for unauthorized publication,
                                                distribution, or dissemination, whether for payment or otherwise.
                                            </li>
                                            <li>No student may plagiarize the intellectual property of others, including
                                                data, ideas, publications, and inventions.
                                            </li>
                                            <li>No student may cheat or attempt to cheat, or behave in a manner that can
                                                be interpreted as cheating or attempting to cheat, in an examination or
                                                any other aspect of a course.
                                            </li>
                                            <li>No student or group of students may organize, conduct, or participate in
                                                any non-Sakya Buddha University sponsored or approved activity in the
                                                name of the University without permission or written instructions from
                                                Sakya Buddha University.
                                            </li>
                                            <li>No student or group of students may promote, manage, or assist in the
                                                collection of money or contributions in the name of Sakya Buddha
                                                University without permission or written instruction from the center.
                                            </li>
                                            <li>No student or group of students may make any statement to the media in
                                                the name of the University or center without the written permission of
                                                an authorized representative of the University.
                                            </li>
                                        </ul>

                                        <p>A student who violates one or more of these responsibilities, prohibitions,
                                            or Sakya Buddha University policies is deemed to have committed a violation
                                            or breach of conduct and is subject to disciplinary action, up to and
                                            including dismissal and withdrawal from Sakya Buddha University.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="accordion-icon card-header w-50" id="headingThree">
                                    <h4 className="mb-0">
                                        <button className="btn collapsed" type="button" data-toggle="collapse"
                                                data-target="#private-3" aria-expanded="false"
                                                aria-controls="private-3">3. Failure to Maintain Satisfactory Academic Progress and Dismissal
                                        </button>
                                    </h4>
                                </div>
                                <div id="private-3" className="collapse" aria-labelledby="headingThree">
                                    <div className="card-body">
                                        <p>Please consult the Sakya Buddha University policy outlined below for further
                                            details on Satisfactory Academic Progress.</p>

                                        <p>Sakya Buddha University mandates that students uphold minimum standards of
                                            academic achievement, known as satisfactory academic progress, throughout
                                            their program of study. The university does not permit students to remain
                                            enrolled if they fail to meet these satisfactory minimum standards of
                                            academic progress.</p>

                                        <p>Satisfactory Academic Progress is determined by two key areas:</p>

                                        <h5>A. Minimum requirement for maintenance of grade point average</h5>

                                        <p><strong>Undergraduate students:</strong></p>

                                        <p>The student's grade point average (GPA) is assessed at the conclusion of each
                                            course/module. Should an undergraduate student's GPA dip below 2.0 at the
                                            conclusion of any course/module, the student will be placed on academic
                                            probation for the subsequent course/module:</p>

                                        <ul>
                                            <li>If the student achieves a high enough grade in the course/module
                                                following placement on academic probation, resulting in a cumulative GPA
                                                of 2.0 or higher, they will be removed from academic probation.
                                            </li>
                                            <li>Should the student fail to achieve a high enough grade in the
                                                course/module following placement on academic probation, maintaining a
                                                cumulative GPA below 2.0, they will be placed on final academic
                                                probation.
                                            </li>
                                            <li>If the student on final academic probation elevates their cumulative GPA
                                                to 2.0 or higher in the following course/module, they will be relieved
                                                from final academic probation (and academic probation).
                                            </li>
                                            <li>Failure to raise the cumulative GPA to 2.0 or higher in the subsequent
                                                course/module while on final academic probation will lead to dismissal
                                                from Sakya Buddha University.
                                            </li>
                                        </ul>

                                        <p><strong>Graduate students:</strong></p>

                                        <p>The student's grade point average (GPA) is evaluated at the conclusion of
                                            each course/module. Should a graduate student's GPA fall below 3.0 at the
                                            conclusion of any course/module, the student will be placed on academic
                                            probation for the subsequent course/module:</p>

                                        <ul>
                                            <li>If the student achieves a high enough grade in the course/module
                                                following placement on academic probation, resulting in a cumulative GPA
                                                of 3.0 or higher, they will be removed from academic probation.
                                            </li>
                                            <li>Should the student fail to achieve a high enough grade in the
                                                course/module following placement on academic probation, maintaining a
                                                cumulative GPA below 3.0, they will be placed on final academic
                                                probation.
                                            </li>
                                            <li>If the student on final academic probation elevates their cumulative GPA
                                                to 3.0 or higher in the following course/module, they will be relieved
                                                from final academic probation (and academic probation).
                                            </li>
                                            <li>Failure to raise the cumulative GPA to 3.0 or higher in the subsequent
                                                course/module while on final academic probation will lead to dismissal
                                                from Sakya Buddha University.
                                            </li>
                                        </ul>

                                        <h5>B. Maximum time allowed for program completion</h5>

                                        <p>Undergraduate students must complete all coursework within 8 years from the
                                            commencement of their studies.</p>
                                        <p>Master's graduate students must complete all coursework within 4 years from
                                            the commencement of their studies.</p>
                                        <p>Doctoral graduate students must complete all coursework within 8 years from
                                            the commencement of their studies.</p>

                                        <p>Students who exceed these maximum program durations will be dismissed from
                                            their respective programs.</p>

                                        <p>Exceptions may be considered by Sakya Buddha University for special or
                                            extenuating circumstances. The maximum extension granted for an
                                            undergraduate student is two (2) additional years.</p>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
        ;
}

export default PrivatePolicy;
